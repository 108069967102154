.ticker {
  overflow: hidden;
  padding-top: var(--gutter-large);
  padding-bottom: 10vmax;

  .ticker__line {
    display: flex;

    &:not(:first-child) {
      margin-top: calc(var(--gutter-medium) * 1);
    }

    .ticker__repeater {
      position: relative;

      display: flex;
      flex-shrink: 0;
      align-items: center;

      animation-name: linear-left; // see mixins
      animation-duration: var(--duration);
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    &:not(:last-child) {
      .ticker__repeater { left: -10rem; }
    }
  }
}
