@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.toggler {
  --toggler-gutter: var(--gutter-medium);

  position: relative;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  margin-bottom: 1.6rem;
  margin-bottom: var(--gutter-block);

  color: var(--color);

  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);

  .toggler__bg {
    @include mixins.button-decs;

    --color: var(--colors-text-one); // override
    --inactive-bg-color: transparent; // override

    position: absolute;
    top: -1px;
    left: -1px;

    height: calc(100% + 2px);

    border: 1px solid var(--colors-theme-border);
    box-shadow: 0 8px 16px var(--colors-shade);

    transition: transform var(--transition-default) var(--transition-easing);
  }

  .toggler__item {
    @include mixins.button-decs;

    --color: var(--colors-text-one); // override
    --inactive-bg-color: transparent; // override

    cursor: pointer;

    position: relative;

    flex-wrap: wrap;

    line-height: var(--lineheight);
    text-align: center;

    mix-blend-mode: multiply;

    @include mixins.button;

    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      --inactive-bg-color: transparent; // override

      // opacity: var(--hover-opacity);
    }

    .toggler__item-span {
      margin-right: 0.3em;
    }

    input[type="radio"] {
      pointer-events: none;
      position: absolute;
      opacity: 0;
    }
  }

  &--2 {
    .toggler__bg {
      width: calc(50% - var(--toggler-gutter) / 2 + 1px);
    }

    .toggler__item {
      width: calc(50% - var(--toggler-gutter) / 2);
    }

    &:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) {
      .toggler__bg {
        transform: translateX(calc(100% + var(--toggler-gutter)));
      }
    }
  }

  &--3 {
    .toggler__bg {
      width: calc(33.3333% - var(--toggler-gutter) / 3 + 0.6666px);
    }

    .toggler__item {
      width: calc(33.3333% - var(--toggler-gutter) / 3);
      padding: 1.2em 0;
    }

    &:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) {
      .toggler__bg {
        transform: translateX(calc(100% + var(--toggler-gutter) * 0.5));
      }
    }

    &:has(.toggler__item[data-nr="2"] input[type="radio"]:checked) {
      .toggler__bg {
        transform: translateX(calc(200% + var(--toggler-gutter) * 1));
      }
    }
  }

  @media (max-width: vars.$mq-large) {
    .toggler__item {
      padding: 1.2em 0.4em;
    }

    &--3 {
      .toggler__item {
        flex-direction: column;

        svg {
          margin-right: 0;
          margin-bottom: 0.25em;
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    // --toggler-gutter: var(--gutter-small);
    --toggler-gutter: 1px;

    .toggler__item {
      padding-right: 0;
      padding-left: 0;
    }

    &--2 {
      justify-content: space-around;

      .toggler__bg {
        width: var(--toggler-item-width);
        transition-property: transform, width;
      }

      .toggler__item {
        flex-grow: 1;
        width: auto;
      }

      &:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) {
        .toggler__bg {
          transform: translateX(calc(var(--toggler-item-width) + 2px));
          width: calc(100% - var(--toggler-item-width));
        }
      }
    }

    &--mobile-vertical {
      flex-direction: column;

      .toggler__bg {
        width: calc(100% + 2px) !important;
      }

      .toggler__item {
        width: 100%;
        padding: 0.8em 0;
      }

      &.toggler--2 {
        .toggler__bg { height: calc(50% - var(--toggler-gutter) / 2 + 1px); }
        .toggler__item { height: calc(50% - var(--toggler-gutter) / 2); }

        &:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) {
          .toggler__bg {
            transform: translateY(calc(100% + var(--toggler-gutter)));
          }
        }
      }

      &.toggler--3 {
        .toggler__bg { height: calc(33.3333% - var(--toggler-gutter) / 3 + 0.6666px); }
        .toggler__item { height: calc(33.3333% - var(--toggler-gutter) / 3); }

        &:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) {
          .toggler__bg {
            transform: translateY(calc(100% + var(--toggler-gutter) * 0.5));
          }
        }

        &:has(.toggler__item[data-nr="2"] input[type="radio"]:checked) {
          .toggler__bg {
            transform: translateY(calc(200% + var(--toggler-gutter) * 1));
          }
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile-small) {
    &:not(.toggler--mobile-vertical) {
      @include fonts.font-small;
    }
  }
}
