@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.label {
  @include mixins.button-decs;

  // pointer-events: none;
  position: relative;

  @include mixins.button;
  @include mixins.corner;

  &--toggle {
    &::after {
      content: none;
    }
  }

  &--locaties {
    width: 100%;
  }

  .results &,
  .calculate & {
    --inactive-bg-color: var(--colors-bg-one);
    --color: var(--colors-text-one);

    margin-bottom: var(--gutter-block);
    border: var(--colors-theme-border) solid 1px;

    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      --inactive-bg-color: var(--colors-theme-two);
    }
  }

  &--select {
    padding: 0;

    &::after {
      // down arrow
      position: absolute;
      right: 1.2em;
      transform: rotate(135deg);

      width: 1rem;
      height: 1rem;
      margin-top: -0.2em;

      border-width: 2px;
    }

    .interest-header & {
      float: right;
    }

    &:has(select:hover),
    &:has(select:focus),
    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      &::after {
        transform: rotate(135deg) translate(0.3rem, -0.3rem) !important

        // border-color: var(--colors-text-two);
      }
    }
  }

  &--period {
    select {
      width: 5.2em;
    }
  }

  .label__select {
    pointer-events: auto;
    cursor: pointer;

    flex-grow: 1;

    min-height: 1em;
    padding: 1em calc(1.2em + 2rem) 1em 1.2em;

    line-height: 1.4; // cut off on 1
    color: var(--color);

    background-color: transparent;
    border: 0;
  }

  @media (max-width: vars.$mq-small) {
    &--select {
      padding: 0 !important;
    }

    &--period {
      select {
        width: 4.4em;
      }
    }

    .buttons--touch-scroll &,
    .calculate &,
    .interests &,
    .interest-header & {
      padding: 0;
      border-radius: 10rem;

      &--select::after {
        right: 1em;
      }

      .label__select {
        padding: 0.6em calc(0.8em + 2rem) 0.6em 0.8em;
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    &--period {
      select {
        width: 4.7em;
      }
    }

    .interest-header & {
      background-color: var(--colors-bg-one);
    }

    .interest-header--banks & {
      float: initial;
      font-size: var(--font-size-small);

      .label__select {
        font-size: var(--font-size-small);
      }
    }
  }
}
