.word {
  overflow: hidden;
  display: inline-flex;
  margin: 0 -0.03em -0.05em;

  .word__text {
    will-change: transform; // looks nicer in Chrome
    padding: 0 0.03em 0.05em;

    // &:not(.fader, .fader2) {
    //   display: none;
    // }
  }
}
