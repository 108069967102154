@use "../1-base/functions" as functions;

// STRUCTURE
// also set in js
// $mq-max-content: 1920px;
$mq-max-content: 1440px;
$mq-xl: 1600px;
$mq-large: 1400px;
$mq-small: 1200px;
$mq-tablet: 1023px;
$mq-phablet: 800px;
$mq-mobile: 599px;
$mq-mobile-small: 374px; // 1px smaller than small phones
$mq-mobile-horizontal: 440px; // smaller than ipad horizontal
// others
// (prefers-color-scheme: dark)
// (orientation: portrait)

@mixin white-start {
  --border-radius: 4.8rem;

  position: relative;
  background-color: var(--colors-bg-one);

  &::after,
  &::before {
    content: "";

    position: absolute;
    bottom: 100%;
    left: 0;

    width: var(--border-radius);
    height: var(--border-radius);
  }

  // 70.7 = 1 / sqrt(2)
  &::before {
    background: radial-gradient(circle at 100% 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
  }

  &::after {
    right: 0;
    left: auto;
    background: radial-gradient(circle at 0 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
  }
}

:root {
  // COLORS
  --colors-bg-one: #fff; // white
  --colors-bg-two: #191334; // black
  --colors-bg-three: #f5f5f5; // light grey
  --colors-bg-four: #dad9cf; //  grey
  --colors-bg-layer: #f8f8f480; // light grey with alpha
  --colors-theme-one: #90bbff; // light blue
  --colors-theme-two: #fff7c3; // light yellow
  --colors-theme-three: #e6e8ff; // light purple
  --colors-theme-four: #e2dfcd; // light beige
  --colors-theme-five: #cfd3ff; // lila
  --colors-theme-highlight: #bed8ff; // light blue
  --colors-theme-border: #d7d7d7; // light grey
  --colors-theme-error: #c71c1c; // red
  --colors-theme-error-glow: #c71c1c6b; // red with alpha
  --colors-text-one: #191334; // black
  --colors-text-two: #fff; // white
  --colors-text-transparent: #191334ab; // black with alpha
  --colors-shade: #0000001a; // black with alpha
  --colors-glow: #ffffff1a; // white with alpha
  --colors-glow2: #ffffff30; // white with alpha

  // ANIMATION
  // also set in js
  --transition-fast: 0.2s;
  --transition-default: 0.4s;
  --transition-slow: 0.8s;
  --transition-easing: cubic-bezier(0.56, 0, 0.24, 1);
  --transition-easing-quad: cubic-bezier(0.7, 0, 0.3, 1);
  --transition-easing-out: cubic-bezier(0, 0, 0.06, 1);
  --transition-easing-back: cubic-bezier(0.5, 0.01, 0.19, 1.65);
  --transition-easing-out-back: cubic-bezier(0.3, 1.36, 0.59, 0.99);
  --transition-easing-in: cubic-bezier(0.58, 0, 1, 0.52);

  // SIZING
  // all overwritten in js
  --window-width: 100vw;
  --window-height: 100vh;
  --document-height: 100vh;
  --inner-height: 100vh; // sometimes wrong on android
  --navbar-height: 0;
  --current-navbar-height: 0;
  --max-content: #{$mq-max-content};

  // GRID
  // --gutter: calc(1.2rem + 2vw);
  // --gutter: #{functions.fluid(40px, 16px, $mq-mobile-small, $mq-max-content)};
  --gutter: 4rem;
  --border-radius-large: 4rem;

  // --gutter-large: #{functions.fluid(32px, 24px, $mq-mobile-small, $mq-max-content)};
  --gutter-large: 3.2rem;
  --gutter-medium: 2.4rem;
  --gutter-between: 1.6rem;
  --gutter-small: 1.2rem;
  --gutter-block: var(--gutter-large);
  --padding: var(--gutter-large);

  // SITE SPECIFIC
  --hover-opacity: 0.5;
  --text-opacity: 0.7;
  --rente-btn-width: 4.9em; // overwritten in js, always set with units
  --interest-header-height: 13.2rem; // overwritten in js, always set with units
  --nav-height: 7.8rem; // overwritten in js, always set with units
  --cta1-width: 51rem;
  --scroll-height: auto;
  --interest-height: 8rem; // overwritten in js, always set with units
  --interest-gap: 22rem; // overwritten in js, always set with units
  --article-img-height: 50vw; // overwritten in js, always set with units

  @media (max-width: $mq-small) {
    --gutter: var(--gutter-medium);
    --rente-btn-width: 4.1em;
  }

  @media (max-width: $mq-phablet) {
    .body[data-pagetype="banks"] { --rente-btn-width: 3.1em; }
  }

  @media (max-width: $mq-phablet) {
    --gutter: var(--gutter-small);
  }

  @media (max-width: $mq-mobile) {
    --rente-btn-width: 3.8em;
    --colors-shade: #00000030;
  }
}

// Z-INDEXES
.main { z-index: 100; }

.nav {
  z-index: 200;

  .body:has(.media--fullscreen) & { z-index: 99; } // under main
}

.loader { z-index: 300; }
