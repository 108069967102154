@use "../1-base/mixins" as mixins;

.block2 {
  --bg-color: var(--colors-bg-three);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  aspect-ratio: 4/3;
  width: 31rem;
  margin-right: var(--gutter);
  padding: var(--gutter-medium);

  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);

  .block2__heading {
    --color: var(--colors-text-one);

    display: inline;
    margin-bottom: 0.3em;
    padding-bottom: 0.15em;

    @include mixins.sick-underline;
    @include mixins.underline-left;
    @include mixins.underline-text-shadow;
  }

  // .block2
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    .block2__heading {
      @include mixins.underline-full-left;

      transition-duration: var(--transition-fast);
    }

    .button {
      background-color: var(--colors-theme-two); // needed for safari border radius bug
      transition-duration: var(--transition-fast);

      &::after {
        transform: rotate(45deg) translate(0.3rem, -0.3rem);
        border-color: var(--colors-text-one);
        transition-duration: var(--transition-fast);
      }

      &::before { transform: translateY(-40%); }
    }
  }
}
