@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.results {
  .results__block {
    container-type: inline-size;
    overflow: hidden;
    display: block;

    margin-bottom: var(--padding);

    background-color: var(--colors-bg-one);
    border-radius: var(--border-radius-large);

    &--overflow {
      overflow: visible;
    }

    .results__block-content {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: var(--gutter-block) var(--gutter-block) 0;

      &--grey {
        overflow: hidden;
        flex-direction: row;
        background-color: var(--colors-theme-border);

        .results__block-text {
          display: block;
          flex-grow: 1;
          align-self: center;
        }

        .results__block-image {
          flex-shrink: 0;
          align-self: flex-end;

          width: 40%;
          margin-bottom: -15%;
          margin-left: 0%;
        }
      }

      &--image {
        justify-content: flex-start;
        padding: calc(var(--gutter-block) * 2) var(--gutter-block) var(--gutter-block);

        .results__block-text {
          position: relative;
          flex-grow: initial;
          width: 60%;
        }

        .results__block-bg-image {
          position: absolute;
          top: 0;
          right: 0;

          overflow: hidden;

          width: 50%;
          height: 100%;

          &::after {
            // gradient from left
            content: "";

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            width: 50%;

            // background: linear-gradient(to right, var(--colors-theme-border), transparent);

            background:
              linear-gradient(
                to right,
                hsl(0deg 0% 84.5%) 0%,
                hsl(0deg 0% 84.5% / 98.7%) 8.1%,
                hsl(0deg 0% 84.5% / 95.1%) 15.5%,
                hsl(0deg 0% 84.5% / 89.6%) 22.5%,
                hsl(0deg 0% 84.5% / 82.5%) 29%,
                hsl(0deg 0% 84.5% / 74.1%) 35.3%,
                hsl(0deg 0% 84.5% / 64.8%) 41.2%,
                hsl(0deg 0% 84.5% / 55%) 47.1%,
                hsl(0deg 0% 84.5% / 45%) 52.9%,
                hsl(0deg 0% 84.5% / 35.2%) 58.8%,
                hsl(0deg 0% 84.5% / 25.9%) 64.7%,
                hsl(0deg 0% 84.5% / 17.5%) 71%,
                hsl(0deg 0% 84.5% / 10.4%) 77.5%,
                hsl(0deg 0% 84.5% / 4.9%) 84.5%,
                hsl(0deg 0% 84.5% / 1.3%) 91.9%,
                hsl(0deg 0% 84.5% / 0%) 100%
              );
          }
        }

        @container (max-width: 65rem) {
          flex-direction: column;
          padding-top: var(--gutter-block);
          padding-bottom: 0;

          .results__block-text { width: 100%; }

          .results__block-bg-image {
            position: relative;

            aspect-ratio: 1 / 1;
            width: 8.2rem;
            margin-bottom: 1rem;

            border-radius: 10rem;

            &::after { content: none; }
          }
        }
      }

      &:has(.interests) {
        padding: var(--gutter-small);
        padding-left: var(--gutter-medium);
      }

      @container (max-width: 70rem) {
        --gutter-block: var(--gutter-medium);
      }
    }
  }

  .results__item {
    // overflow: hidden;

    margin-bottom: 1.6rem;
    padding: var(--gutter-medium);
    padding-bottom: 1px;

    color: var(--color);

    border: 1px solid var(--colors-theme-border);
    border-radius: var(--gutter-medium);

    &:last-child,
    &--grey,
    &--dark {
      margin-bottom: var(--gutter-block);
    }

    &--grey,
    &--dark {
      background-color: var(--bg-color);
      border: 0;
    }

    &--grey {
      --bg-color: var(--colors-bg-three);
    }

    &--dark {
      --bg-color: var(--colors-bg-two);
      --color: var(--colors-text-two);

      display: flex;
      justify-content: space-between;
    }

    .results__item-cell {
      width: calc(50% - var(--gutter-medium) * 0.5);
      color: var(--color);

      &--alt {
        --color: var(--colors-theme-two);
      }
    }

    .results__item-flex {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: calc(var(--gutter-medium) - 0.2em);
    }
  }

  @media (max-width: vars.$mq-small) {
    .results__block {
      .results__block-content {
        --gutter-results: var(--gutter-medium);

        &--numbers {
          --gutter-results: 1.6rem;
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .results__block {
      .results__block-content {
        // --gutter-results: var(--gutter-small);

        &--grey {
          --gutter-results: var(--gutter-medium);

          // .results__block-text { }

          .results__block-image { display: none; }
        }
      }
    }

    .results__item {
      &--dark {
        display: block;
      }

      .results__item-cell {
        width: 100%;
      }
    }
  }

  // @media (max-width: vars.$mq-mobile-small) {
  //   .results__toggle {
  //     @include fonts.font-small;
  //   }
  // }
}
