@use "../1-base/vars" as vars;

.toggle {
  --toggle-size: 2rem;
  --toggle-width: 4rem;
  --toggle-border-width: 2px;
  --toggle-color: var(--colors-text-two);
  --bg-color: var(--colors-bg-two);

  // 3 options:
  // toggle--switch: puts text on left
  // toggle--inverted: makes it white
  // toggle--bordered: adds a border
  //
  // you can also set 3 css variables:
  // --toggle-size: 1em;
  // --toggle-width: 2em;
  // --toggle-border-width: .25em;
  // they do what they're called
  // border-width also works on non bordered
  //
  // change the .cookies--black selector to your black holder (twice)
  //
  // margin-bottom: 0.3em;

  // mix-blend-mode: multiply;

  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  transition: opacity;
  transition-duration: var(--transition-default);

  .interest-header & {
    padding: 0.6em 0.8em;
    line-height: 1.4;
  }

  svg {
    flex-shrink: 0;
    margin-right: 0.75em;
  }

  // .toggle__btn,
  // .toggle__text { pointer-events: none; }

  .toggle__btn {
    position: relative;

    display: inline-block;
    flex-shrink: 0;

    margin-right: 0.5em;
    padding: 0.6em calc(var(--toggle-width) * 0.5 - var(--toggle-border-width) * 2) 0.4em;

    font-size: var(--toggle-size);
    vertical-align: middle;

    background-color: var(--bg-color);
    border-radius: 1em;

    transition: background-color var(--transition-default);

    &::before {
      content: "";

      position: absolute;
      top: var(--toggle-border-width);
      left: var(--toggle-border-width);

      // transform: translateX(calc((var(--toggle-width) - var(--toggle-border-width)) * 1.5));
      transform: translateX(calc(var(--toggle-width) - 1em - var(--toggle-border-width) * 4));

      width: calc(1em - var(--toggle-border-width) * 2);
      height: calc(1em - var(--toggle-border-width) * 2);

      background-color: var(--toggle-color);
      border-radius: calc(1em - var(--toggle-border-width) * 2);

      transition: transform var(--transition-default) var(--transition-easing), background-color var(--transition-default);
    }
  }

  &--text-on {
    .toggle__btn { margin-right: 0; }

    .toggle__text {
      position: absolute;
      color: var(--toggle-color);
      transition: opacity var(--transition-default), color var(--transition-default);

      &--on {
        left: 0.5em;
      }

      &--off {
        right: 0.5em;
        opacity: 0;
      }
    }
  }

  // .toggle
  &--switch {
    justify-content: flex-end;

    .toggle__btn {
      order: 1;
      margin-right: 0;
      margin-left: 0.5em;
    }
  }

  // in black or inverted (for on color)
  // .toggle
  &--inverted {
    mix-blend-mode: screen;
  }

  // .toggle
  &--off {
    opacity: 0.3;

    .toggle__btn::before { transform: none; }

    &.toggle--text-on {
      opacity: 1;

      .toggle__text--on { opacity: 0; }
      .toggle__text--off { opacity: 1; }
    }
  }

  // .toggle
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover,
  &.hover {
    opacity: 0.6;
  }

  .calculate & {
    --toggle-size: 2.6rem;
    --toggle-width: 5.6rem;
    --bg-color: var(--colors-theme-one);

    width: 100%;
    margin-bottom: var(--gutter-block);

    &--off {
      --bg-color: var(--colors-bg-four);

      opacity: 1; // override
    }

    .toggle__btn {
      margin-left: auto;
    }

    &:has(button:focus),
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover,
    &.hover {
      --bg-color: var(--colors-theme-two);

      opacity: 1; // override
    }
  }

  // for on coloured bg only
  // .toggle
  &--bordered {
    .toggle__btn {
      padding: calc(0.6em - var(--toggle-border-width)) calc(var(--toggle-width) * 0.5 - var(--toggle-border-width)) calc(0.4em - var(--toggle-border-width));
      background-color: var(--bg-color);
      border: var(--toggle-color) solid var(--toggle-border-width);
      border-radius: 10rem;

      &::before {
        transform: translateX(calc(var(--toggle-width) - 1em));
        width: calc(1em - var(--toggle-border-width) * 4);
        height: calc(1em - var(--toggle-border-width) * 4);
        background-color: var(--toggle-color);
      }
    }

    &.toggle--off {
      .toggle__btn::before { transform: none; }
    }

    // .toggle
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover,
    &.hover {
      opacity: 1;

      .toggle__btn {
        background-color: var(--toggle-color);

        &::before {
          background-color: var(--bg-color);
        }
      }
    }

    &.toggle--text-on {
      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover,
      &.hover {
        .toggle__text {
          color: var(--bg-color);
        }
      }
    }
  }

  // wilde weide
  .body--transparent-header .header:not(.header--cookies, .header--force-btn-bg-color) & {
    // .toggle__btn {
    //   background-color: transparent;
    //   backdrop-filter: blur(8px);
    // }

    --bg-color: var(--colors-bg-one);
  }

  @media (max-width: vars.$mq-mobile) {
    .interest-header--dark & {
      --toggle-size: 1.6rem;
      --toggle-width: 3.6rem;
    }
  }
}
