@use "../1-base/vars" as vars;

.person {
  container-type: inline-size;
  display: flex;
  align-items: center;

  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium) var(--gutter-small) var(--gutter-medium) var(--gutter-medium);

  border: 0.1rem solid var(--colors-theme-border);
  border-radius: var(--border-radius-large);

  .person__image {
    position: relative;

    overflow: hidden;
    flex-shrink: 0;

    width: 12rem;
    height: 12rem;
    margin-right: var(--gutter-large);

    background-color: var(--colors-bg-three);
    border-radius: 10rem;

    @container (max-width: 38rem) {
      width: 8rem;
      height: 8rem;
      margin-right: var(--gutter-medium);
    }

    @container (max-width: 32rem) {
      width: 6rem;
      height: 6rem;
      margin-right: var(--gutter-small);
    }
  }

  .person__texts {
    flex-grow: 1;
    max-width: calc(100% - 6rem - var(--gutter-small));

    .person__email {
      overflow: hidden;
      display: block;

      max-width: 100%;
      height: 1.2em;

      text-overflow: ellipsis;
      white-space: nowrap;

      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover,
      &.hover {
        text-decoration: underline;
        text-underline-offset: 0.2rem;
      }
    }
  }
}
