@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.highlight {
  @mixin active-highlight {
    transform: scale(1);
    opacity: 1;

    .highlight__content {
      .highlight__gradient {
        opacity: 1;
      }

      .highlight__image {
        .highlight__image-star {
          transform: scale(1) rotate(0deg);
          transition: transform var(--transition-slow) var(--transition-easing-out);
        }
      }

      .highlight__hider {
        max-height: calc(var(--scroll-height) + 1px);
        opacity: 1;
        transition: max-height 0.4s, opacity 0.2s 0.2s;
      }

      // .highlight__content
      &--review {
        .highlight__header {
          .highlight__header-initials {
            color: var(--colors-text-two);
            background-color: var(--colors-text-one);

            .highlight__header-gradient { opacity: 0; }
          }
        }
      }
    }
  }

  transform: scale(0.85);

  display: block;

  opacity: 0;

  transition: opacity, transform;
  transition-duration: var(--transition-default);

  .swiper-slide-prev &,
  .swiper-slide-next & {
    opacity: 1;
  }

  .highlight__content {
    --bg-color: var(--colors-bg-three);

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1/1;
    padding: 10%;

    background-color: var(--bg-color);
    border-radius: var(--border-radius-large);

    .highlight__gradient {
      position: absolute;
      z-index: -1;
      left: 0;

      overflow: hidden;

      width: 100%;
      height: 100%;

      opacity: 0;
      border-radius: var(--border-radius-large);

      transition: opacity var(--transition-default);
    }

    .highlight__image {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 42%;
      margin-bottom: var(--gutter-large);

      .highlight__image-star {
        position: absolute;
        transform: scale(0) rotate(180deg);

        overflow: hidden;

        aspect-ratio: 1/1;
        width: 150%;

        clip-path: polygon(98.49% 62.18%, 84.95% 69.45%, 85.91% 84.8%, 70.54% 84.32%, 63.7% 98.09%, 50.63% 90%, 37.82% 98.49%, 30.55% 84.95%, 15.2% 85.91%, 15.68% 70.54%, 1.91% 63.7%, 10% 50.63%, 1.51% 37.82%, 15.05% 30.55%, 14.09% 15.2%, 29.46% 15.68%, 36.3% 1.91%, 49.37% 10%, 62.18% 1.51%, 69.45% 15.05%, 84.8% 14.09%, 84.32% 29.46%, 98.09% 36.3%, 90% 49.37%);

        transition: transform var(--transition-slow) var(--transition-easing);
      }

      img {
        position: relative;
        aspect-ratio: 5/6;
        width: 100%;
        object-fit: contain;
      }

      &--circle {
        position: relative;

        overflow: hidden;

        aspect-ratio: 1/1;
        width: 45%;
        margin-bottom: 0;

        border: 1px solid var(--colors-theme-border);
        border-radius: 50%;
      }

      &--link {
        img {
          will-change: transform;
          transition: transform var(--transition-default);
        }

        &.hover,
        body:not(.body--touch) &:focus,
        body:not(.body--touch) &:hover {
          transition-duration: var(--transition-fast);

          img {
            transform: scale(1.1);
            transition-duration: var(--transition-fast);
          }
        }
      }
    }

    .highlight__heading {
      position: relative;

      // will-change: transform;
      margin-bottom: 0.5em;
      text-align: center;

      @include mixins.sick-underline;
      @include mixins.underline-center;
      @include mixins.underline-text-shadow;

      &--link {
        display: inline;

        &.hover,
        body:not(.body--touch) &:focus,
        body:not(.body--touch) &:hover {
          @include mixins.underline-full-center;

          transition-duration: var(--transition-fast);
        }
      }
    }

    .highlight__hider {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      max-height: 0;

      opacity: 0;

      transition: max-height 0.4s, opacity 0.2s 0s;

      .highlight__text {
        width: 100%;
        text-align: center;
        text-wrap: balance;
        opacity: var(--text-opacity);
      }
    }

    // .highlight__content
    &--no-padding {
      justify-content: space-evenly;
      padding: 5% 0;

      .highlight__image,
      .highlight__heading {
        margin-bottom: 0;
      }

      .highlight__hider {
        .highlight__text {
          margin-bottom: 1em;
        }
      }
    }

    // .highlight__content
    &--link {
      .highlight__image {
        will-change: transform;
        transition: transform var(--transition-default);
      }

      &.hover,
      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover {
        transition-duration: var(--transition-fast);

        .highlight__image {
          transform: scale(1.05);
          transition-duration: var(--transition-fast);
        }

        .highlight__heading {
          @include mixins.underline-full-center;

          transition-duration: var(--transition-fast);
        }
      }
    }

    // .highlight__content
    &--review {
      position: relative;
      z-index: 0;
      align-items: flex-start;

      .highlight__header {
        display: flex;
        align-items: baseline;
        margin-bottom: var(--padding);

        .highlight__header-initials {
          @include fonts.font-normal;
          @include fonts.font-sans-condensed;

          pointer-events: none;

          margin-right: 1em;

          font-weight: 700 !important;
          color: var(--colors-text-one);

          background-color: transparent;

          &::before,
          &::after { content: none; }

          .highlight__header-gradient {
            position: absolute;
            z-index: -1;

            width: 100%;
            height: 100%;

            opacity: 1;

            transition: opacity var(--transition-default);
          }
        }
      }

      .highlight__heading {
        text-align: left;
        text-shadow: none;
        background-image: none;
      }

      .highlight__text {
        margin-bottom: auto;
        text-align: left;
        opacity: 1;
      }

      .highlight__link {
        margin-top: 0.8em;
        text-decoration: underline;
        text-underline-offset: 0.2rem;

        body:not(.body--touch) &:focus,
        body:not(.body--touch) &:hover,
        &.hover {
          text-decoration: none;
        }

        svg {
          width: 1rem;
          height: 1rem;
          margin-left: 0.5em;

          path {
            fill: var(--colors-text-one);
          }
        }
      }
    }
  }

  .swiper-slide-active & {
    @include active-highlight;
  }

  @media (max-width: vars.$mq-small) {
    .swiper-slide-prev & {
      opacity: 0;
    }

    .swiper-slide-next & {
      @include active-highlight;
    }
  }

  @media (max-width: vars.$mq-phablet) {
    .highlight__content--link {
      aspect-ratio: 1/1.2; // if smaller it resizes the swiper
    }

    // all revert to original prev and next
    .swiper-slide-prev &,
    .swiper-slide-next & {
      transform: scale(0.85);
      opacity: 0.5;

      .highlight__content {
        .highlight__gradient {
          opacity: 0;
        }

        .highlight__header {
          .highlight__header-initials {
            color: var(--colors-text-one);
            background-color: transparent;

            .highlight__header-gradient {
              opacity: 1;
            }
          }
        }

        .highlight__image {
          .highlight__image-star {
            transform: scale(0) rotate(180deg);
          }
        }

        .highlight__hider {
          max-height: 0;
          opacity: 0;
          transition: max-height 0.4s, opacity 0.2s 0s;
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .swiper-slide-prev &,
    .swiper-slide-next & {
      opacity: 1;
    }
  }
}
