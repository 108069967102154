@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.rate {
  @include mixins.button-decs;

  --inactive-bg-color: transparent;
  --color: var(--colors-text-one);

  z-index: initial;
  min-width: var(--rente-btn-width);
  padding-right: 0; // override button mixin
  padding-left: 0; // override button mixin

  @include mixins.button;

  .interests--banks & {
    background-color: transparent !important;

    &::after { content: none; }
  }

  &--active {
    background-color: var(--colors-theme-highlight);
  }

  &--no-bg {
    --inactive-bg-color: transparent !important;

    overflow: visible;
    &::before { display: none; }

    svg { margin-right: 0; }
  }

  @media (max-width: vars.$mq-small) {
    .interests--banks & {
      justify-content: flex-start;
      padding-right: 0; // override button mixin
      padding-left: 0; // override button mixin
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .interests--banks & {
      justify-content: flex-start;
      padding: 0;
      border-radius: 0;
    }
  }

  @media (max-width: vars.$mq-mobile) {
    @include fonts.font-small;

    padding: 0;
  }
}
