@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.interest-header {
  width: 100%;
  transition: border-radius var(--transition-fast), box-shadow var(--transition-fast);

  &--sticky {
    box-shadow: 0 16px 16px var(--colors-shade);
  }

  .interest-header__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    width: calc(100% + var(--gutter-small) * 2);
    margin-right: calc(-1 * var(--gutter-small));
    margin-bottom: var(--gutter-small);
    margin-left: calc(-1 * var(--gutter-small));
    padding: 0 calc(var(--gutter-small) / 2);

    .interest-header__block {
      margin: 0 calc(var(--gutter-small) / 2) var(--gutter-medium);

      // border: 1px red solid;
      &--label { width: 15em; }

      &--energy {
        padding-left: 1em; // needs this width
      }

      .interest-header__block-desc {
        display: flex;
        align-items: center;
        margin-bottom: 0.8em;

        .interest-header__block-info {
          margin-right: 0.5em;
        }
      }

      .interest-header__block-buttons {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }

  &--dark {
    --color: var(--colors-text-two);

    // margin-bottom: 10vmax;
    margin-bottom: var(--padding);
    padding: var(--gutter-medium) var(--gutter-medium) 0;

    color: var(--color);

    background-color: var(--colors-bg-two);
    border-radius: var(--border-radius-large);

    .interest-header__content {
      margin-bottom: 0;
    }

    .interest-header__block {
      margin-bottom: var(--gutter-medium);
    }
  }

  // &--banks {
  //   .interest-header__content {
  //     // align-items: baseline;

  //     .interest-header__block {
  //       .interest-header__block-desc {
  //         margin-bottom: 0;
  //       }
  //     }
  //   }
  // }

  @media (max-width: vars.$mq-tablet) {
    .interest-header__content {
      .interest-header__block {
        &--energy { padding-left: 0; }
        &--label { width: auto; }
      }
    }

    &--dark {
      padding: var(--gutter-medium) calc(var(--gutter-medium) * 2) 0;

      .interest-header__content {
        justify-content: space-between;

        .interest-header__block {
          &--nhg {
            order: 1;
            width: 100%;
          }
        }
      }
    }

    &--banks {
      justify-content: space-between;

      // padding-left: var(--gutter-medium);
      padding: var(--gutter-medium) var(--gutter-medium) 0;

      .interest-header__content {
        align-items: center;
        justify-content: space-between;

        .interest-header__block {
          // margin-bottom: var(--gutter-small);

          &--label {
            margin-bottom: 0;
          }

          //   &--nhg {
          //     order: initial;
          //     width: auto;
          //   }
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .interest-header__content {
      .interest-header__block {
        margin-bottom: calc(var(--gutter-medium) * 0.5);
      }
    }

    &--dark {
      @include fonts.font-small;

      --color: var(--colors-text-one);

      // padding: var(--gutter-medium) var(--gutter-medium) calc(var(--gutter-medium) * 0.5);
      padding: var(--gutter-small) var(--gutter-medium) 0;

      // background-color: var(--colors-bg-one);
      background-color: var(--colors-bg-three);
      border-radius: var(--gutter-medium);

      .interest-header__content {
        justify-content: space-between;

        .interest-header__block {
          &--nhg {
            order: 0;
            width: auto;
          }

          &--energy { order: -1; }

          &:first-child {
            // width: 100%;
            order: -1;
          }
        }
      }
    }

    &--sticky {
      border-radius: 0 0 var(--gutter-medium) var(--gutter-medium);
      box-shadow: 0 0 16px var(--colors-shade);
    }

    &--banks {
      .interest-header__content {
        .interest-header__block {
          &--label {
            order: -2;
            width: 100%;
          }

          &--nhg { width: 100%; }

          &:first-child {
            width: auto;
          }
        }
      }
    }
  }
}
