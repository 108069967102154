@use "../1-base/vars" as vars;

.block6 {
  position: relative;
  z-index: 1;

  overflow: hidden;
  display: flex;

  width: 100%;
  margin-bottom: var(--gutter-medium);

  border-radius: var(--gutter-medium);

  .block6__texts {
    padding: calc(var(--gutter-medium) * 2) 0 calc(var(--gutter-medium) * 2) calc(var(--gutter-medium) * 1);
  }

  .block6__image {
    position: relative;
  }

  @media (max-width: vars.$mq-mobile) {
    .block6__image {
      display: none;
    }

    .block6__texts {
      padding: var(--gutter-medium);
    }

    .block6__buttons {
      width: 100%;
      padding-left: 0;
    }
  }
}
