.radio {
  --lineheight: 1.2;

  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 4.2em;
  margin-bottom: var(--gutter-small);
  padding: 0 1.2em;

  line-height: var(--lineheight);

  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);

  transition: background-color var(--transition-default);

  .buttons & { margin: 0.5rem; }

  .radio__icon {
    width: 2.6rem;
    margin-right: 0.75em;

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  .radio__text {
    flex-grow: 1;
    padding: 0.35em 0; // same as input
  }

  .radio__circle,
  .radio__box {
    position: absolute;
    right: var(--gutter-medium);

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--colors-theme-border);

    transition: background-color var(--transition-default);
  }

  .radio__circle {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 10rem;

    svg {
      margin-right: 0;

      path {
        fill: var(--colors-text-two);
        transition: fill var(--transition-default);
      }
    }
  }

  .radio__box {
    width: 2.4rem;
    height: 2.4rem;
    padding: 2px;
    border-radius: 0.6rem;

    &::after {
      content: "";

      width: 100%;
      height: 100%;

      border-radius: 0.4rem;

      transition: background-color var(--transition-default);
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin-left: 3rem;
    opacity: 0;
  }

  &:has(input[type="checkbox"]:focus),
  &:has(input[type="radio"]:focus),
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    .radio__circle {
      svg path {
        fill: var(--colors-text-one);
        transition-duration: var(--transition-fast);
      }
    }

    .radio__box {
      &::after { background-color: var(--colors-theme-three); }
    }
  }

  &:has(input[type="checkbox"]:checked),
  &:has(input[type="radio"]:checked) {
    background-color: var(--colors-bg-three);

    .radio__circle {
      background-color: var(--colors-bg-two);

      svg path {
        fill: var(--colors-text-two);
      }
    }

    .radio__box {
      &::after { background-color: var(--colors-theme-one) !important; }
    }
  }
}
