@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.button {
  @include mixins.button-decs;

  cursor: pointer;

  position: relative;

  font-weight: 400 !important;
  text-align: center;
  white-space: nowrap;

  &--div { pointer-events: none; }
  &--small { padding: 0.6em 1em; }

  &--margin {
    margin-bottom: var(--gutter-small);
  }

  &--round,
  &--circle,
  &--corner {
    @include mixins.corner;
  }

  &--back,
  &--round,
  &--circle {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 5.6rem;
    height: 5.6rem;
    padding: 0;

    border-radius: 10rem;

    svg { margin-right: 0 !important; }
  }

  &--round {
    --inactive-bg-color: var(--colors-theme-five);
    --color: var(--colors-text-one);

    &::after {
      content: none;
    }
  }

  &--corner {
    aspect-ratio: 1/1;

    &::after {
      width: 1rem;
      height: 1rem;
      margin-left: -0.4rem;
      border-width: 2px;
    }
  }

  .splash-block & { margin-top: var(--gutter-medium); }

  .buttons-min-width & {
    min-width: var(--rente-btn-width);
    padding-right: 0;
    padding-left: 0;
  }

  .buttons--grow & {
    flex-grow: 1;
    padding-right: 0;
    padding-left: 0;
  }

  .results & {
    margin-bottom: var(--gutter-block);
  }

  // exactly here
  @include mixins.button;

  .interests &,
  .block5 & {
    margin-bottom: 0;
  }

  .results .buttons & {
    margin-bottom: 0.5rem;
  }

  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  // .button
  &:has(input[type="radio"]:focus) {
    background-color: var(--colors-theme-two); // needed for safari border radius bug
    border-color: var(--colors-theme-two);

    &::before { transform: translateY(-40%); }
  }

  &--big {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // margin-bottom: var(--gutter-block) !important;
    margin-bottom: 1rem !important;

    line-height: var(--lineheight);
    white-space: normal;

    svg {
      height: 3.4rem;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0.75em;
    }
  }

  &--back {
    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      svg { transform: translateX(-0.5rem); }
    }
  }

  &--arrow {
    align-self: flex-start;

    svg {
      transform: rotate(180deg);
      margin-right: 0;
      margin-left: 0.75em;
    }

    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      svg { transform: rotate(180deg) translateX(-0.5rem); }
    }
  }

  &:has(input[type="radio"]:checked),
  &--active {
    background-color: var(--active-bg-color); // needed for safari border radius bug

    &::before { transform: translateY(calc(1px - 80%)) !important; }

    &.button--border {
      border-color: var(--active-bg-color) !important;
    }
  }

  .button__span {
    margin-right: 0.3em;
  }

  // .button
  @media (max-width: vars.$mq-tablet) {
    .buttons--land & {
      svg { display: none; }
    }
  }

  // .button
  @media (max-width: vars.$mq-phablet) {
    .buttons--land & {
      svg { display: block; }
    }
  }

  // .button
  @media (max-width: vars.$mq-mobile) {
    &--mobile-border {
      border: 1px solid var(--colors-theme-border);
    }

    &--margin {
      width: 100%;
      padding: 1.2em !important;
    }
  }
}
