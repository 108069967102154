@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.splash-rect {
  --bg-color: var(--colors-bg-one);

  display: flex;
  flex-direction: column;

  max-width: calc(calc(var(--max-content) - var(--gutter) * 3) * 0.3333);
  padding: var(--gutter-large);

  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);

  .splash-rect__heading {
    display: inline;

    @include mixins.sick-underline;
    @include mixins.underline-left;
    @include mixins.underline-text-shadow;
  }

  .splash-rect__content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.5em;

    .splash-rect__text {
      text-wrap: balance;
      opacity: var(--text-opacity);
    }

    .splash-rect__side {
      display: flex;
      margin: -1rem 0 -1rem 1em;
    }
  }

  // .splash-rect
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    .splash-rect__heading {
      @include mixins.underline-full-left;

      transition-duration: var(--transition-fast);
    }

    .button {
      color: var(--colors-text-one);
      background-color: var(--colors-theme-two); // needed for safari border radius bug
      transition-duration: var(--transition-fast);

      &::after {
        transform: rotate(45deg) translate(0.3rem, -0.3rem);
        border-color: var(--colors-text-one);
        transition-duration: var(--transition-fast);
      }

      &::before {
        transform: translateY(-40%);
        transition-duration: var(--transition-fast);
      }
    }
  }

  @media (max-width: vars.$mq-tablet) {
    width: calc(50% - var(--gutter) * 0.5);
    margin: var(--padding) 0 0 0;
    padding: var(--gutter-medium);

    .splash-rect__content {
      flex-direction: column;
      align-items: flex-start;

      .splash-rect__side {
        margin: 0.5em 0 0;
      }
    }
  }

  @media (max-width: vars.$mq-phablet) {
    --bg-color: var(--colors-bg-three);
  }

  @media (max-width: vars.$mq-mobile) {
    width: 100%;
  }
}
