.gradient {
  position: absolute;
  z-index: -1;
  top: -50%;
  left: 0;

  width: 200%;
  height: 200%;

  background: radial-gradient(var(--colors-theme-two) 0%, var(--colors-theme-three) 70.7%); // 1 / sqrt(2)

  transition: opacity var(--transition-slow);

  &--secondary {
    background: radial-gradient(var(--colors-theme-one) 0%, var(--colors-theme-three) 70.7%); // 1 / sqrt(2)
  }

  .background & {
    z-index: 1;
    opacity: 0;

    .body[data-pagetype="page"] &,
    .body[data-pagetype="news"] &,
    .body[data-pagetype="knowledge"] &,
    .body[data-pagetype="contact"] & {
      opacity: 1;
    }
  }
}
