@use "../1-base/vars" as vars;

@mixin hide-scrollbar {
  scrollbar-width: none; // firefox, without fixBody
  &::-webkit-scrollbar { display: none; } // webkit,  without fixBody
}

// linear left animation declaration
@keyframes linear-left {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}

@mixin button-decs {
  --color: var(--colors-text-two);
  --active-bg-color: var(--colors-theme-highlight);
  --inactive-bg-color: var(--colors-bg-two);
  --lineheight: 1.2;

  position: relative;
  z-index: 0;

  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 1.2em;

  color: var(--color);

  background-color: var(--inactive-bg-color); // needed for safari border radius bug
  border-radius: var(--gutter-medium);

  transition: background-color, color, border-color, opacity;
  transition-duration: var(--transition-default);

  &::before {
    // will-change: transform;
    content: "";

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 500%;

    background: linear-gradient(180deg, var(--inactive-bg-color) 0%, var(--inactive-bg-color) 20%, var(--colors-theme-two) 40%, var(--colors-theme-two) 60%, var(--active-bg-color) 80%, var(--active-bg-color) 100%);

    transition: transform var(--transition-default) var(--transition-easing);
  }
}

@mixin button {
  .column__content--dark &,
  .interest-header &,
  &--back,
  &--border,
  &--inverted {
    --color: var(--colors-text-one);
    --inactive-bg-color: var(--colors-bg-one);

    .nav & { --inactive-bg-color: var(--colors-bg-three); }
  }

  &--border {
    border: 1px solid var(--colors-theme-border);
  }

  .interest-header:not(.interest-header--dark) & {
    // --color: var(--colors-text-one) !important;
    --active-bg-color: var(--colors-bg-two);

    &--active {
      --color: var(--colors-text-two) !important;
    }
  }

  .interest-header--dark & {
    --inactive-bg-color: var(--colors-glow);
    --color: var(--colors-text-two);
  }

  .interest-header--dark &--active {
    --color: var(--colors-text-one);
    --inactive-bg-color: var(--colors-theme-highlight);
  }

  .interest-header--dark &--div {
    --inactive-bg-color: var(--colors-bg-two);
  }

  .buttons & { margin: 0.5rem; }

  .buttons--div & {
    justify-content: flex-start;
    padding-right: 0;
    padding-left: 0;
  }

  svg {
    flex-shrink: 0;
    margin: -0.5rem 0.75em -0.5rem 0;
    transition: transform var(--transition-default);

    path {
      fill: var(--color);
      transition: fill var(--transition-default);
    }
  }

  &--icon-right {
    svg {
      margin-right: 0;
      margin-left: 0.75em;
    }
  }

  &:has(input[type="radio"]:focus),
  &:has(select:hover),
  &:has(select:focus),
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    &:not(.rate) {
      --color: var(--colors-text-one);

      background-color: var(--colors-theme-two); // needed for safari border radius bug
      border-color: var(--colors-theme-two);
      transition-duration: var(--transition-fast);

      &::after {
        transform: rotate(45deg) translate(0.5rem, -0.5rem);
        border-color: var(--colors-text-one);
        transition-duration: var(--transition-fast);
      }

      &::before {
        transform: translateY(-40%);
        transition-duration: var(--transition-fast);
      }

      svg {
        transition-duration: var(--transition-fast);

        path {
          fill: var(--colors-text-one);
          transition-duration: var(--transition-fast);
        }
      }
    }
  }

  &.hover { transition-duration: 0.1s; } // must be after the above

  @media (max-width: vars.$mq-small) {
    .buttons--touch-scroll &,
    .calculate &,
    .interests &,
    .interest-header & {
      padding: 0.8em 0.65em;
    }

    .interests__gap & {
      padding: 1.2em; // override
    }

    .interest-header--banks & {
      justify-content: flex-start;
      padding-right: 0;
      padding-left: 0;

      // border-radius: 0;
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .calculate & { padding: 1.2em; }

    .interest-header--banks & {
      padding: 0;
      border-radius: 0;
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .calculate & { padding: 0.8em 0.65em; }

    .interest-header & {
      --color: var(--colors-text-one);
      --inactive-bg-color: var(--colors-bg-three);
    }

    .interest-header--dark & {
      --inactive-bg-color: var(--colors-bg-one);
    }

    .interest-header--dark &--div {
      --inactive-bg-color: var(--colors-bg-three);
    }
  }
}

@mixin white-start {
  --border-radius: 4.8rem;

  position: relative;
  background-color: var(--bg-color);

  &::after,
  &::before {
    content: "";

    position: absolute;
    z-index: -1;
    bottom: 100%;
    left: 0;

    width: var(--border-radius);
    height: var(--border-radius);
  }

  // 70.7 = 1 / sqrt(2)
  &::before {
    background: radial-gradient(circle at 100% 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
  }

  &::after {
    right: 0;
    left: auto;
    background: radial-gradient(circle at 0 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
  }
}

@mixin corner {
  &::after {
    pointer-events: none;
    content: "";

    transform: rotate(45deg);

    display: flex;

    width: 1.4rem;
    height: 1.4rem;
    margin-left: -0.6rem;

    border-top: 3px solid var(--color);
    border-right: 3px solid var(--color);

    transition: border-color, transform;
    transition-duration: var(--transition-default);
  }

  // &.button--inverted {
  //   &::after { border-color: var(--colors-text-one); }
  // }

  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    &::after {
      transform: rotate(45deg) translate(0.5rem, -0.5rem);

      // border-color: var(--colors-text-one);
      transition-duration: var(--transition-fast);
    }
  }
}

@mixin underline-text-shadow {
  // x y blur color
  text-shadow:
    calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color),
    calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color),
    calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color),
    calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color),
    calc(var(--shadow-width) * -1.5)  0 var(--bg-color),
    calc(var(--shadow-width) * 1.5) 0 var(--bg-color),
    0 calc(var(--shadow-width) * 1) var(--bg-color),
    0 calc(var(--shadow-width) * -1)  var(--bg-color);
}

@mixin sick-underline {
  // @include underline-text-shadow;
  text-decoration: none;

  background-image: linear-gradient(var(--color), var(--color));
  background-repeat: no-repeat, no-repeat;

  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  transition-property: background-size, opacity;
}

@mixin underline-left {
  background-position: 0 var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
}

@mixin underline-center {
  background-position: 50% var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
}

@mixin underline-right {
  background-position: 100% var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
}

@mixin underline-full-left {
  background-position: 0 var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
}

@mixin underline-full-center {
  background-position: 50% var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
}

@mixin underline-full-right {
  background-position: 100% var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
}
