@use "../3-core/fonts" as fonts;
@use "../1-base/vars" as vars;

.interests {
  overflow: hidden;
  width: 100%;

  .interests__header {
    overflow: hidden;
    display: flex;
    margin-bottom: -1em;
    text-indent: -9999px;
  }

  .interests__item { // tr
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    width: 100%;
    padding: var(--gutter-small) var(--gutter-medium);

    & > * {
      display: flex;
      flex-shrink: 0;
      align-items: center;
    }

    .interests__item-brand,
    .interests__item-rate,
    .interests__item-rates { flex-grow: 1; }

    .interests__item-brand {
      display: flex;
      align-items: center;

      .interests__item-logo {
        flex-shrink: 0;
        width: 13rem;
        margin-right: var(--gutter-medium);

        img {
          max-height: 2.9em;
        }
      }

      .interests__item-name {
        overflow: hidden;
        padding: 0.2em 0;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .interests__item-rate {
      @include fonts.font-normal;
      @include fonts.font-sans-condensed;

      white-space: nowrap;
    }

    .interests__item-calc {
      text-align: right;

      &--faded-out {
        pointer-events: none;
        height: calc(var(--font-size-normal) * 1.5);
        opacity: 0;
      }
    }
  }

  &--splash {
    margin-bottom: var(--gutter-small);

    .interests__item { // tr
      margin-bottom: var(--gutter-small);
      padding-right: var(--gutter-small);
      background-color: var(--colors-bg-one);
      border-radius: var(--gutter-large);

      & > * {
        &:first-child {
          // padding-left: var(--gutter-large);
          border-radius: var(--gutter-large) 0 0 var(--gutter-large);
        }

        &:last-child {
          // padding-right: var(--gutter-small);
          border-radius: 0 var(--gutter-large) var(--gutter-large) 0;
        }
      }

      .interests__item-brand {
        width: calc(13rem + 11em);
      }
    }
  }

  &--calculate {
    .interests__item { // tr
      padding: 0;
    }
  }

  &--banks,
  &--regular {
    border-collapse: separate;
    border: var(--colors-theme-border) 1px solid;
    border-radius: var(--gutter-large);

    // border-radius: var(--border-radius-large);

    .interests__item { // tr
      border-bottom: var(--colors-theme-border) 1px solid;

      &:last-child { border-bottom: 0; }

      .interests__item-brand {
        width: calc(var(--interest-header-left-width) + var(--interest-header-right-diff));
      }
    }

    .interests__gap {
      display: flex;

      width: calc(100% + 2px);
      margin: 0 -1px;
      padding-top: var(--gutter-medium);

      background-color: var(--colors-bg-three);
      border-bottom: var(--colors-theme-border) 1px solid;

      td {
        width: 100%;
      }
    }
  }

  &--regular {
    overflow: visible;
    background-color: var(--colors-bg-one);
  }

  &--banks {
    margin-bottom: var(--padding);
  }

  @media (max-width: vars.$mq-small) {
    &--banks,
    &--regular {
      .interests__item { // tr
        .interests__item-brand {
          .interests__item-logo {
            img {
              max-height: 2.5em;
            }
          }
        }
      }
    }
  }

  @media (max-width: vars.$mq-tablet) {
    &--banks,
    &--regular {
      .interests__item { // tr
        flex-wrap: wrap;
        justify-content: space-between;

        .interests__item-brand {
          width: calc(100% - 12em);

          // width: auto;
        }

        .interests__item-rates {
          order: 1;
          width: 100%;
        }
      }
    }

    &--calculate {
      .interests__item {
        .interests__item-brand {
          .interests__item-logo {
            display: flex;
          }
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .interests__item { // tr
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      margin-bottom: var(--gutter-small);
      padding: var(--gutter-medium);

      border: var(--colors-theme-border) 1px solid;

      & > * {
        margin-bottom: var(--gutter-small);
        padding: 0;
        background-color: transparent;

        &:first-child,
        &:last-child {
          padding: 0; // override
        }
      }

      .interests__item-brand {
        width: auto !important;
        margin-right: 0;
        margin-bottom: 0.6rem;
      }

      .interests__item-logo {
        order: 0;
      }

      .interests__item-name {
        order: 2;
        width: 100%;
        max-width: calc(100vw - var(--gutter) * 2 - var(--gutter-medium) * 2);
      }

      .interests__item-rate,
      .interests__item-rates {
        flex-grow: 0;
      }

      .interests__item-rate {
        flex-grow: 0;
        order: 1;
        margin-bottom: 1rem;
      }

      .interests__item-rates {
        width: auto;
        padding-left: 0;
      }

      .interests__item-calc {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        order: 3;

        width: 100%;
        margin-bottom: 0;
      }
    }

    &--banks,
    &--regular {
      border: 0;
      border-radius: 0;

      .interests__item {
        border-radius: var(--gutter-large);

        // .interests__item-name {
        //   order: 0;
        //   width: auto;
        // }

        &:last-child {
          border-bottom: var(--colors-theme-border) 1px solid;
        }
      }

      .interests__gap {
        background-color: transparent;
        border-bottom: 0;
      }
    }

    &--banks {
      align-items: baseline;

      .interests__item { // tr
        .interests__item-brand {
          width: 100% !important;
        }
      }
    }

    &--calculate {
      margin-bottom: 0;
      padding: 0;
      border: 0;

      .interests__item {
        margin-bottom: 0;
        padding: 0;
        border: 0;

        .interests__item-name {
          order: 1;
          width: 100%;
          margin-top: 0.5rem;
        }

        .interests__item-rate {
          order: 2;
          margin-bottom: 0;
          margin-left: 0;
        }

        .interests__item-calc {
          width: auto;
        }
      }
    }
  }
}
