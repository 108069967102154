@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.news {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  margin-bottom: var(--gutter-medium);

  border-radius: var(--gutter-large);

  .news__image {
    position: relative;
    overflow: hidden;
    aspect-ratio: 3 / 2;

    img {
      will-change: transform;
      transition: transform var(--transition-default) var(--transition-easing);
    }
  }

  .news__texts {
    --bg-color: var(--colors-bg-three);

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding: var(--gutter-medium);

    background-color: var(--bg-color);

    .news__date {
      margin-bottom: 0.5em;
    }

    .news__heading {
      display: inline;

      @include mixins.sick-underline;
      @include mixins.underline-left;
      @include mixins.underline-text-shadow;
    }

    .news__text {
      margin-top: 0.5em;
      color: var(--colors-text-transparent);
    }
  }

  &--hor {
    --bg-color: var(--colors-bg-three);

    flex-direction: row;
    background-color: var(--bg-color);
    border-radius: var(--border-radius-large);

    .news__image {
      aspect-ratio: 1 / 1.1;
      width: 50%;
      margin: 1rem;

      // border: 1rem solid var(--colors-bg-three);
      border-radius: var(--gutter-large);
    }

    .news__texts {
      align-self: flex-end;
      width: 50%;
      padding: var(--gutter-medium) var(--gutter-large);

      .news__heading {
        background: none;
      }
    }
  }

  // .news
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    .news__image {
      img {
        transform: scale(1.05);
        transition-duration: var(--transition-fast);
      }
    }

    .news__texts {
      .news__heading {
        @include mixins.underline-full-left;

        transition-duration: var(--transition-fast);
      }
    }
  }

  @media (max-width: vars.$mq-tablet) {
    &--hor {
      flex-direction: column;

      .news__image {
        aspect-ratio: 16 / 9;
        width: 100%;
        margin: 0;
        border-radius: 0;
      }

      .news__texts {
        width: 100%;
        padding: var(--gutter-medium);
      }
    }
  }
}
