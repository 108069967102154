@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.block1 {
  --logo-width: 9rem;

  display: flex;
  align-items: center;

  width: 40rem;
  margin-right: var(--gutter);
  padding: var(--gutter-medium);

  background-color: var(--colors-bg-three);
  border-radius: var(--border-radius-large);

  .block1__logo {
    margin-right: var(--gutter-medium);

    img {
      max-width: var(--logo-width);
      mix-blend-mode: multiply;
    }
  }

  .block1__content {
    display: flex;
    flex-direction: column;
    align-self: start;
    width: calc(100% - var(--logo-width) - var(--gutter-medium));

    .block1__heading {
      overflow: hidden;

      margin-bottom: 0.3em;
      padding-bottom: 0.15em;

      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .block1__rate {
      font-weight: 700;

      @include fonts.font-normal;
    }
  }

  @media (max-width: vars.$mq-mobile) {
    // width: 32rem;
  }
}
