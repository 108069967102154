@use "../1-base/vars" as vars;

.block3 {
  display: flex;

  width: 100%;
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium);

  background-color: var(--colors-theme-four);
  border-radius: var(--border-radius-large);

  .block3__image {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-self: flex-end;

    width: 28rem;
    margin-bottom: calc(var(--gutter-medium) * -1);
    padding-left: var(--gutter-medium);

    img {
      width: 100%;
      height: auto;
    }
  }

  .block3__texts {
    padding-left: var(--gutter-medium);
  }

  .block3__buttons {
    flex-shrink: 0;
    padding-left: var(--gutter-medium);
  }

  @media (max-width: vars.$mq-tablet) {
    flex-wrap: wrap;
    justify-content: space-between;

    .block3__texts {
      order: -1;
      width: 100%;
      padding-right: var(--gutter-medium);
    }
  }

  @media (max-width: vars.$mq-phablet) {
    .block3__image {
      width: 23rem;
      padding-left: 1rem;
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .block3__image {
      display: none;
    }

    .block3__texts {
      padding-left: 0;
    }

    .block3__buttons {
      width: 100%;
      padding-left: 0;
    }
  }
}
