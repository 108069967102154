@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.image {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--padding);

  img {
    width: 100%;
  }

  &--rounded {
    img {
      border-radius: var(--gutter-large);
    }
  }

  &--broker { margin-bottom: 0; }

  &--icon { width: 10rem; }

  .image__caption {
    margin-top: 1rem;

    @include fonts.font-small;
  }

  // .image
  @media (max-width: vars.$mq-mobile) {
    &--broker {
      margin-right: -20%;
      margin-left: 20%;
    }
  }
}
