@use "../1-base/vars" as vars;

.footer {
  --color: var(--colors-theme-highlight);
  --bg-color: var(--colors-bg-two);

  padding: 5vmax 0;
  color: var(--color);
  background-color: var(--bg-color);

  a,
  button {
    color: var(--color);
  }

  .footer__logo,
  .footer__list {
    margin-bottom: var(--gutter-large) !important;
  }

  .footer__logo {
    display: flex;
  }

  .footer__list {
    --lineheight: 1.8;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: var(--lineheight);

    .footer__title,
    .footer__item {
      display: flex;
    }

    .footer__title {
      font-weight: 700;
    }

    .footer__item {
      &--active,
      &.hover,
      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover {
        text-decoration: underline;
        text-underline-offset: 0.2rem;
      }
    }
  }

  .footer__line {
    margin-top: 10vmax;
  }

  @media (max-width: vars.$mq-mobile) {
    .footer__line {
      text-align: center;
      text-wrap: balance;
    }
  }
}
