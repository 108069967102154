// Fold Out V6.1 SASS
// set transition duration to transitionDuration in FoldOut.js

.fold-out {
  overflow: hidden;

  width: 100%;
  max-height: 0;
  padding-top: 1px; // for non collapsing margin
  padding-bottom: 1px; // for non collapsing margin

  visibility: hidden;
  opacity: 0;

  transition: max-height 0.6s 0s ease, opacity 0.3s 0s ease, visibility 0s 0.6s; // closing

  &[style*="max-height"]:not([style*="max-height: 0"]) {
    visibility: visible;
    opacity: 1;
    transition: max-height 0.6s 0s ease, opacity 0.3s 0.3s ease, visibility 0s 0s; // opening
  }

  .results &,
  .calculate & {
    transition-timing-function: var(--transition-easing-quad), ease, ease; // closing

    &[style*="max-height"]:not([style*="max-height: 0"]) {
      opacity: 1;
      transition-timing-function: var(--transition-easing-quad), ease, ease; // opening
    }
  }

  // .fold-out
  &--opened {
    overflow: visible;

    max-height: none;

    visibility: visible;
    opacity: 1;

    transition: none;
  }
}
