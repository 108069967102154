@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.block4 {
  --bg-color: var(--colors-bg-three);

  display: flex;
  align-items: center;

  max-width: calc(calc(var(--max-content) - var(--gutter) * 3) * 0.2777) !important;
  margin-bottom: var(--padding);
  padding: var(--gutter-medium);

  background-color: var(--bg-color);
  border-radius: var(--gutter-medium);

  transition: background-color var(--transition-default);

  .block4__image {
    flex-shrink: 0;
    max-width: 6rem;
    padding-right: var(--gutter-medium);
    transition: transform var(--transition-default);
  }

  .block4__text {
    display: inline;

    @include mixins.sick-underline;
    @include mixins.underline-left;
    @include mixins.underline-text-shadow;
  }

  // .block4
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    transition-duration: var(--transition-fast);

    .block4__image {
      transform: scale(1.1);
      transition-duration: var(--transition-fast);
    }

    .block4__text {
      @include mixins.underline-full-left;

      transition-duration: var(--transition-fast);
    }
  }

  @media (max-width: vars.$mq-phablet) {
    max-width: 100% !important;
    margin-bottom: var(--gutter-small);
  }
}
