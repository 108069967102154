:root {
  --colors-bg-one: #fff;
  --colors-bg-two: #191334;
  --colors-bg-three: #f5f5f5;
  --colors-bg-four: #dad9cf;
  --colors-bg-layer: #f8f8f480;
  --colors-theme-one: #90bbff;
  --colors-theme-two: #fff7c3;
  --colors-theme-three: #e6e8ff;
  --colors-theme-four: #e2dfcd;
  --colors-theme-five: #cfd3ff;
  --colors-theme-highlight: #bed8ff;
  --colors-theme-border: #d7d7d7;
  --colors-theme-error: #c71c1c;
  --colors-theme-error-glow: #c71c1c6b;
  --colors-text-one: #191334;
  --colors-text-two: #fff;
  --colors-text-transparent: #191334ab;
  --colors-shade: #0000001a;
  --colors-glow: #ffffff1a;
  --colors-glow2: #ffffff30;
  --transition-fast: .2s;
  --transition-default: .4s;
  --transition-slow: .8s;
  --transition-easing: cubic-bezier(.56, 0, .24, 1);
  --transition-easing-quad: cubic-bezier(.7, 0, .3, 1);
  --transition-easing-out: cubic-bezier(0, 0, .06, 1);
  --transition-easing-back: cubic-bezier(.5, .01, .19, 1.65);
  --transition-easing-out-back: cubic-bezier(.3, 1.36, .59, .99);
  --transition-easing-in: cubic-bezier(.58, 0, 1, .52);
  --window-width: 100vw;
  --window-height: 100vh;
  --document-height: 100vh;
  --inner-height: 100vh;
  --navbar-height: 0;
  --current-navbar-height: 0;
  --max-content: 1440px;
  --gutter: 4rem;
  --border-radius-large: 4rem;
  --gutter-large: 3.2rem;
  --gutter-medium: 2.4rem;
  --gutter-between: 1.6rem;
  --gutter-small: 1.2rem;
  --gutter-block: var(--gutter-large);
  --padding: var(--gutter-large);
  --hover-opacity: .5;
  --text-opacity: .7;
  --rente-btn-width: 4.9em;
  --interest-header-height: 13.2rem;
  --nav-height: 7.8rem;
  --cta1-width: 51rem;
  --scroll-height: auto;
  --interest-height: 8rem;
  --interest-gap: 22rem;
  --article-img-height: 50vw;
}

@media (width <= 1200px) {
  :root {
    --gutter: var(--gutter-medium);
    --rente-btn-width: 4.1em;
  }
}

@media (width <= 800px) {
  :root .body[data-pagetype="banks"] {
    --rente-btn-width: 3.1em;
  }

  :root {
    --gutter: var(--gutter-small);
  }
}

@media (width <= 599px) {
  :root {
    --rente-btn-width: 3.8em;
    --colors-shade: #00000030;
  }
}

.main {
  z-index: 100;
}

.nav {
  z-index: 200;
}

.body:has(.media--fullscreen) .nav {
  z-index: 99;
}

.loader {
  z-index: 300;
}

@keyframes linear-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  line-height: 1;
  -webkit-font-smoothing: antialiased !important;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

img {
  -ms-interpolation-mode: bicubic;
}

iframe {
  background: none;
  overflow: hidden;
}

.fader {
  --vert: var(--padding);
  --hor: var(--gutter);
  --move-duration: .7s;
  --opacity-duration: .7s;
  will-change: transform, opacity, clip-path;
  transition-delay: 0s;
  transition-property: transform, opacity, clip-path !important;
  transition-timing-function: cubic-bezier(0, 0, .04, 1) !important;
}

.no-js .fader {
  animation: fader-fader-in var(--move-duration);
  animation-delay: .4s;
  animation-fill-mode: both;
}

@keyframes fader-fader-in {
  0% {
    transform: translateY(calc(var(--vert) * 1));
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.js .body--firefox .fader, .js .body--firefox .fader.fader--top-start {
  transform: none;
}

.js .body--firefox .fader--clip {
  transform: translateY(calc(var(--vert) * 1));
}

.js .body--firefox .fader--clip.fader--top-start {
  transform: translateY(calc(var(--vert) * -1));
}

.js .body--firefox .fader--up {
  transform: translateY(100%);
}

.js .body--firefox .fader--up.fader--top-start {
  transform: translateY(-100%);
}

.js .fader {
  transform: translateY(calc(var(--vert) * 1));
  opacity: 0 !important;
}

.js .fader--top-start {
  transform: translateY(calc(var(--vert) * -1));
}

.js .fader--shrink {
  transform: scale(1.1);
}

.js .fader--left {
  transform: translateX(calc(var(--hor) * -1));
}

.js .fader--right {
  transform: translateX(calc(var(--hor) * 1));
}

.js .fader--bl {
  transform: translate(calc(var(--hor) * -1), var(--vert));
}

.js .fader--bl.fader--top-start {
  transform: translate(calc(var(--hor) * -1), calc(var(--vert) * -1));
}

.js .fader--br {
  transform: translate(calc(var(--hor) * 1), var(--vert));
}

.js .fader--br.fader--top-start {
  transform: translate(calc(var(--hor) * 1), calc(var(--vert) * -1));
}

.js .fader--still {
  will-change: opacity;
  transform: initial;
  transition-property: opacity !important;
}

.js .fader--fullscreen, .js .fader--no-fade {
  will-change: auto;
  transform: none;
  opacity: 1 !important;
  transition-property: none !important;
}

.js .fader--clip {
  transform: translateY(calc(var(--vert) * 1));
  clip-path: inset(100% 0 0);
  opacity: 1 !important;
}

.js .fader--clip.fader--top-start {
  transform: translateY(calc(var(--vert) * -1));
}

.js .fader--up {
  transform: translateY(100%);
  opacity: 1 !important;
}

.js .fader--up.fader--top-start {
  transform: translateY(-100%);
}

.js .fader--fast-load {
  --move-duration: 0s;
  --opacity-duration: .1s;
  transform: none;
}

.js .fader.nav__item {
  pointer-events: none;
}

.js .fader.video--cover, .js .fader.cover {
  transform: scale(1.05);
}

.js .fader3:not(.fader--loader, .fader--loading), .js .fader3.fader--loaded {
  opacity: 1 !important;
  transition-duration: var(--move-duration), var(--opacity-duration), var(--move-duration) !important;
  transform: none !important;
}

.js .fader3:not(.fader--loader, .fader--loading).fader--still, .js .fader3.fader--loaded.fader--still {
  transition-duration: var(--opacity-duration) !important;
}

.js .fader3:not(.fader--loader, .fader--loading).fader--no-fade, .js .fader3.fader--loaded.fader--no-fade {
  transition-duration: 0s !important;
}

.js .fader3:not(.fader--loader, .fader--loading).fader--clip, .js .fader3.fader--loaded.fader--clip {
  clip-path: inset(0);
}

@media (width <= 800px) {
  .js .fader--bl {
    transform: translate(0, var(--vert));
  }

  .js .fader--bl.fader--top-start {
    transform: translate(0, calc(var(--vert) * -1));
  }

  .js .fader--br {
    transform: translate(0, var(--vert));
  }

  .js .fader--br.fader--top-start {
    transform: translate(0, calc(var(--vert) * -1));
  }
}

.fold-out {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  max-height: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  transition: max-height .6s, opacity .3s, visibility 0s .6s;
  overflow: hidden;
}

.fold-out[style*="max-height"]:not([style*="max-height: 0"]) {
  visibility: visible;
  opacity: 1;
  transition: max-height .6s, opacity .3s .3s, visibility;
}

.results .fold-out, .calculate .fold-out {
  transition-timing-function: var(--transition-easing-quad), ease, ease;
}

.results .fold-out[style*="max-height"]:not([style*="max-height: 0"]), .calculate .fold-out[style*="max-height"]:not([style*="max-height: 0"]) {
  opacity: 1;
  transition-timing-function: var(--transition-easing-quad), ease, ease;
}

.fold-out--opened {
  visibility: visible;
  opacity: 1;
  max-height: none;
  transition: none;
  overflow: visible;
}

.switcher {
  --switcher-duration: .4s;
  --switcher-scroll-height: 0;
  --switcher-easing: cubic-bezier(.56, 0, .24, 1);
  transition: opacity var(--switcher-duration);
}

.switcher--faded-out {
  opacity: 0;
}

.switcher--resizing {
  height: var(--switcher-scroll-height);
  transition: opacity var(--switcher-duration), height var(--switcher-duration) var(--switcher-easing);
  overflow: hidden;
}

.switcher .switcher__item {
  width: 100%;
  padding-top: 1px;
  padding-bottom: 1px;
}

.switcher .switcher__item--hidden {
  display: none;
}

.hidden {
  display: none !important;
}

.faded {
  opacity: 0;
}

.invisible {
  visibility: hidden;
}

.disabled {
  pointer-events: none;
  opacity: var(--hover-opacity);
}

.img-responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.iframe {
  background: none;
}

.sprite {
  display: inline-block;
}

.clear:before, .clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--center {
  text-align: center;
}

.text--medium {
  font-size: var(--font-size-medium);
}

.capitalize {
  text-transform: capitalize;
}

.legibility {
  text-rendering: optimizelegibility;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  width: 100%;
  position: absolute;
}

.sticky {
  position: sticky;
  top: 0;
}

@media (width >= 1601px) {
  .visible-xl {
    display: none !important;
  }
}

@media (width <= 1600px) {
  .hidden-xl {
    display: none !important;
  }
}

@media (width >= 1401px) {
  .visible-large {
    display: none !important;
  }
}

@media (width <= 1400px) {
  .hidden-large {
    display: none !important;
  }
}

@media (width >= 1201px) {
  .visible-small {
    display: none !important;
  }
}

@media (width <= 1200px) {
  .hidden-small {
    display: none !important;
  }
}

@media (width >= 1024px) {
  .visible-tablet {
    display: none !important;
  }
}

@media (width <= 1023px) {
  .hidden-tablet {
    display: none !important;
  }
}

@media (width >= 801px) {
  .visible-phablet {
    display: none !important;
  }
}

@media (width <= 800px) {
  .hidden-phablet {
    display: none !important;
  }
}

@media (width >= 600px) {
  .visible-mobile {
    display: none !important;
  }
}

@media (width <= 599px) {
  .hidden-mobile {
    display: none !important;
  }
}

@media (width >= 375px) {
  .visible-mobile-small {
    display: none !important;
  }
}

@media (width <= 374px) {
  .hidden-mobile-small {
    display: none !important;
  }
}

@media (orientation: portrait) {
  .visible-landscape {
    display: none !important;
  }
}

@media (orientation: landscape) {
  .visible-portrait {
    display: none !important;
  }
}

:root {
  --font-family-serif: "Times", serif;
  --font-family-sans: "Arial", sans-serif;
  --font-family-display: "Stencil", sans-serif;
  --font-size-small: 13px;
  --font-size-book: 16px;
  --font-size-intro: clamp(16px, .187617vw + 15.2983px, 18px);
  --font-size-normal: 20px;
  --font-size-between: 24px;
  --font-size-medium: clamp(23.5294px, .794614vw + 20.5576px, 32px);
  --font-size-large: clamp(31.3726px, 1.5598vw + 25.5389px, 48px);
  --font-size-xl: clamp(39.2157px, 2.32498vw + 30.5203px, 64px);
}

b, strong {
  font-weight: 800;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  color: var(--colors-text-one);
  text-decoration: none;
}

button {
  cursor: pointer;
  color: var(--colors-text-one);
  background-color: #0000;
  border: 0;
  padding: 0;
  line-height: 1;
}

ul {
  list-style: disc;
}

.text--search ul, .footer ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.text--search li {
  margin-bottom: calc(var(--lineheight) * 1em);
}

ol {
  list-style: decimal;
}

h1, h2, h3, h4 {
  --underline-width: .07em;
  --underline-top: calc(50% + .47em);
  font-variation-settings: "wdth" 125, "YTLC" 500;
  font-optical-sizing: auto;
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 940;
  line-height: var(--lineheight);
  margin-top: -.1em;
  margin-bottom: -.15em;
}

.text h1, .text h2, .text h3, .text h4 {
  margin-bottom: .2em;
}

h3, h4 {
  text-wrap: balance;
}

h1 {
  --lineheight: 1;
  --underline-width: .06em;
  font-size: var(--font-size-xl);
}

h2 {
  --lineheight: 1;
  font-size: var(--font-size-large);
}

h3 {
  --lineheight: 1.1;
  font-size: var(--font-size-medium);
}

h4 {
  --lineheight: 1.15;
  font-size: var(--font-size-between);
}

h5 {
  --lineheight: 1.15;
  font-size: var(--font-size-normal);
  --underline-width: 1px;
  --underline-top: calc(50% + .55em);
  --shadow-width: .08em;
  font-variation-settings: "wdth" 85;
  font-weight: 700;
  line-height: var(--lineheight);
  text-wrap: balance;
}

h6 {
  --lineheight: 1.5;
  font-size: var(--font-size-intro);
  font-variation-settings: "wdth" 125;
}

p, ul, ol {
  margin: 0 0 calc(var(--lineheight) * 1em);
  line-height: var(--lineheight);
}

p button, p a, ul button, ul a, ol button, ol a {
  text-underline-offset: .2rem;
  text-decoration: underline;
}

body:not(.body--touch) p button:focus, body:not(.body--touch) p button:hover, p button.hover, body:not(.body--touch) p a:focus, body:not(.body--touch) p a:hover, p a.hover, body:not(.body--touch) ul button:focus, body:not(.body--touch) ul button:hover, ul button.hover, body:not(.body--touch) ul a:focus, body:not(.body--touch) ul a:hover, ul a.hover, body:not(.body--touch) ol button:focus, body:not(.body--touch) ol button:hover, ol button.hover, body:not(.body--touch) ol a:focus, body:not(.body--touch) ol a:hover, ol a.hover, .footer p button, .footer p a, .footer ul button, .footer ul a, .footer ol button, .footer ol a {
  text-decoration: none;
}

body:not(.body--touch) .footer p button:focus, body:not(.body--touch) .footer p button:hover, .footer p button.hover, body:not(.body--touch) .footer p a:focus, body:not(.body--touch) .footer p a:hover, .footer p a.hover, body:not(.body--touch) .footer ul button:focus, body:not(.body--touch) .footer ul button:hover, .footer ul button.hover, body:not(.body--touch) .footer ul a:focus, body:not(.body--touch) .footer ul a:hover, .footer ul a.hover, body:not(.body--touch) .footer ol button:focus, body:not(.body--touch) .footer ol button:hover, .footer ol button.hover, body:not(.body--touch) .footer ol a:focus, body:not(.body--touch) .footer ol a:hover, .footer ol a.hover {
  text-underline-offset: .2rem;
  text-decoration: underline;
}

.field-caption p, .highlight__text p, .info p, .interest-header p, .term p {
  margin-bottom: 0;
}

.field-caption p + p, .highlight__text p + p, .info p + p, .interest-header p + p, .term p + p {
  padding-top: calc(var(--lineheight) * 1em);
}

button :focus {
  outline: none;
}

@keyframes fade-in-out {
  0% {
    opacity: 1;
  }

  10% {
    opacity: .5;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

address {
  font-style: normal;
}

textarea, select, input[type="text"], input[type="time"], input[type="date"], input[type="month"], input[type="email"], input[type="password"], input[type="tel"], input[data-type="number"], input[type="number"], input[type="url"], input[type="submit"] {
  --underline-width: 1px;
  --underline-top: calc(50% + .57em);
  --shadow-width: .075em;
  font-variation-settings: "wdth" 110;
  font-optical-sizing: auto;
  min-height: 1.7em;
  color: var(--colors-text-one);
  transition: border-color var(--transition-default);
  border: 0;
  padding: .2em;
  font-family: Encode Sans, serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  font-weight: normal !important;
}

body:not(.body--touch) textarea:focus, body:not(.body--touch) textarea:hover, textarea.hover, body:not(.body--touch) select:focus, body:not(.body--touch) select:hover, select.hover, body:not(.body--touch) input[type="text"]:focus, body:not(.body--touch) input[type="text"]:hover, input[type="text"].hover, body:not(.body--touch) input[type="time"]:focus, body:not(.body--touch) input[type="time"]:hover, input[type="time"].hover, body:not(.body--touch) input[type="date"]:focus, body:not(.body--touch) input[type="date"]:hover, input[type="date"].hover, body:not(.body--touch) input[type="month"]:focus, body:not(.body--touch) input[type="month"]:hover, input[type="month"].hover, body:not(.body--touch) input[type="email"]:focus, body:not(.body--touch) input[type="email"]:hover, input[type="email"].hover, body:not(.body--touch) input[type="password"]:focus, body:not(.body--touch) input[type="password"]:hover, input[type="password"].hover, body:not(.body--touch) input[type="tel"]:focus, body:not(.body--touch) input[type="tel"]:hover, input[type="tel"].hover, body:not(.body--touch) input[data-type="number"]:focus, body:not(.body--touch) input[data-type="number"]:hover, input[data-type="number"].hover, body:not(.body--touch) input[type="number"]:focus, body:not(.body--touch) input[type="number"]:hover, input[type="number"].hover, body:not(.body--touch) input[type="url"]:focus, body:not(.body--touch) input[type="url"]:hover, input[type="url"].hover, body:not(.body--touch) input[type="submit"]:focus, body:not(.body--touch) input[type="submit"]:hover, input[type="submit"].hover {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
}

textarea.hover, select.hover, input[type="text"].hover, input[type="time"].hover, input[type="date"].hover, input[type="month"].hover, input[type="email"].hover, input[type="password"].hover, input[type="tel"].hover, input[data-type="number"].hover, input[type="number"].hover, input[type="url"].hover, input[type="submit"].hover {
  transition-duration: .1s;
}

html {
  box-sizing: border-box;
  height: var(--inner-height);
  font-size: 62.5% !important;
}

.body {
  --underline-width: 1px;
  --underline-top: calc(50% + .57em);
  --shadow-width: .075em;
  font-variation-settings: "wdth" 110;
  font-optical-sizing: auto;
  --lineheight: 1.5;
  font-family: Encode Sans, serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-book);
  --color: var(--colors-text-one);
  font-kerning: normal;
  height: auto;
  min-height: 100%;
  color: var(--color);
  word-break: break-word;
  word-wrap: break-word;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
  display: block;
  overflow: hidden scroll;
}

.body--touch {
  cursor: pointer;
}

*, :before, :after {
  box-sizing: inherit;
}

.chromeframe {
  z-index: 1000;
  box-sizing: border-box;
  background-color: var(--colors-theme-one);
  width: 100%;
  padding: 20px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
}

blockquote, figure {
  margin: 0;
  padding: 0;
}

cite {
  font-style: normal;
}

input[type="text"], input[type="time"], input[type="date"], input[type="month"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], input[type="url"], input[type="submit"] {
  color: var(--colors-text-one);
  appearance: none;
  word-break: normal;
  border-radius: 0;
}

input[type="text"]::placeholder, input[type="time"]::placeholder, input[type="date"]::placeholder, input[type="month"]::placeholder, input[type="email"]::placeholder, input[type="password"]::placeholder, input[type="tel"]::placeholder, input[type="number"]::placeholder, input[type="url"]::placeholder, input[type="submit"]::placeholder {
  color: var(--colors-text-one);
  opacity: .45;
}

input[type="text"]:placeholder-shown, input[type="time"]:placeholder-shown, input[type="date"]:placeholder-shown, input[type="month"]:placeholder-shown, input[type="email"]:placeholder-shown, input[type="password"]:placeholder-shown, input[type="tel"]:placeholder-shown, input[type="number"]:placeholder-shown, input[type="url"]:placeholder-shown, input[type="submit"]:placeholder-shown {
  text-overflow: ellipsis;
}

@media (width <= 800px) {
  input[type="text"], input[type="time"], input[type="date"], input[type="month"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], input[type="url"], input[type="submit"] {
    font-size: 16px;
  }
}

textarea, select {
  color: var(--colors-text-one);
  appearance: none;
  border-radius: 0;
}

@media (width <= 800px) {
  textarea, select {
    font-size: 16px;
  }
}

@media (width <= 599px) {
  .interest-header--dark textarea, .interest-header--dark select {
    --lineheight: 1.5;
    font-size: var(--font-size-small);
  }
}

::selection {
  color: var(--colors-text-one);
  text-shadow: none;
  background: var(--colors-theme-one);
}

:focus {
  outline-style: none;
  outline-color: #0000;
}

.background {
  background-color: var(--colors-theme-one);
  width: 100%;
  height: 100%;
  transition: opacity 0s var(--transition-slow);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.body[data-pagetype-next="banks"] .background {
  opacity: 0;
  transition-delay: 0s;
}

.background .background__sunrise {
  --gradient-size: calc(15rem + 50vmax);
  width: var(--gradient-size);
  height: var(--gradient-size);
  animation: sunrise calc(var(--transition-slow) * 4) var(--transition-easing-out) calc(var(--transition-slow) * 2) both;
  animation-fill-mode: both;
  position: absolute;
}

@keyframes sunrise {
  0% {
    transform: translateY(100vh);
  }

  100% {
    transform: translateY(0);
  }
}

.background .background__sunrise .background__gradient {
  background: radial-gradient(var(--colors-theme-two) 0%, var(--colors-theme-one) 70.7%);
  width: 100%;
  height: 100%;
  position: absolute;
}

.buttons {
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% + 2rem);
  margin: -1rem;
  padding: .5rem;
  display: flex;
}

.buttons--cb {
  margin-bottom: var(--gutter-large);
}

.buttons--right {
  justify-content: flex-end;
}

.buttons--center {
  justify-content: center;
}

.buttons--full {
  flex-direction: column;
  align-items: stretch;
}

.buttons--vertical {
  flex-direction: column;
  align-items: flex-start;
  width: auto;
}

.column__content--gradient .buttons, .column__content--bg-white .buttons, .results .buttons, .calculate .buttons {
  padding-bottom: calc(var(--gutter-block)  + .5rem);
}

.cta1--no-padding .buttons {
  justify-content: center;
}

.buttons .buttons__text {
  margin: 1rem;
}

.buttons .buttons__splitter {
  width: 100%;
}

.buttons--land {
  position: relative;
}

.picture .buttons--land {
  margin-top: var(--gutter-small);
}

.buttons--land .buttons__land {
  width: 16.7rem;
  margin-left: var(--gutter-medium);
  display: flex;
}

.buttons--land .buttons__land svg {
  width: 100%;
  height: auto;
}

.buttons--land .buttons__land--inverted {
  width: 18rem;
}

.interests .buttons--land .buttons__land--inverted {
  margin-bottom: calc(var(--lineheight) * 1em);
  margin-left: .5rem;
}

.buttons--land .buttons__land--inverted svg path {
  fill: var(--colors-text-one);
}

.body--touch .buttons--touch-scroll {
  width: calc(100% + var(--gutter) * 2);
  margin: 0 calc(var(--gutter) * -1) var(--gutter);
  padding: 0 calc(var(--gutter) * 2 - .5rem) .5rem calc(var(--gutter)  - .5rem);
  flex-flow: row;
  overflow: scroll;
}

.body--touch .buttons--touch-scroll:before, .body--touch .buttons--touch-scroll:after {
  pointer-events: none;
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
}

.body--touch .buttons--touch-scroll:before {
  width: calc(var(--gutter) * 1);
  background: linear-gradient(to right, var(--bg-color), transparent);
  left: 0;
}

.body--touch .buttons--touch-scroll:after {
  width: calc(var(--gutter) * 2);
  background: linear-gradient(to left, var(--bg-color), transparent);
  right: 0;
}

.how .body--touch .buttons--touch-scroll:before {
  left: calc(var(--gutter-medium) * -.5);
}

.how .body--touch .buttons--touch-scroll:after {
  right: calc(var(--gutter-medium) * -.5);
}

.body--touch .buttons--touch-scroll a, .body--touch .buttons--touch-scroll label, .body--touch .buttons--touch-scroll button {
  flex-shrink: 0;
}

@media (width <= 1200px) {
  .buttons--small-vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons--small-vertical.buttons--right {
    align-items: flex-end;
  }
}

@media (width <= 1023px) {
  .interest-header--banks .buttons {
    border-radius: 0;
    justify-content: flex-start;
  }

  .buttons--land .buttons__land--inverted {
    width: 16rem;
    margin-left: var(--gutter-between);
  }
}

@media (width <= 800px) {
  .buttons--phablet-vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons--land .buttons__land {
    display: none;
  }

  .interests .buttons--land .buttons__land {
    display: flex;
  }
}

@media (width <= 599px) {
  .buttons--mobile-full {
    flex-direction: column;
    align-items: stretch;
  }

  .buttons--mobile-full.buttons--right {
    align-items: stretch;
  }

  .buttons--mobile-vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons--mobile-scroll {
    width: calc(100% + var(--gutter) * 2);
    margin: 0 calc(var(--gutter) * -1) var(--gutter);
    padding: 0 calc(var(--gutter) * 2 - .5rem) .5rem calc(var(--gutter)  - .5rem);
    flex-flow: row;
    overflow: scroll;
  }

  .buttons--mobile-scroll:before, .buttons--mobile-scroll:after {
    pointer-events: none;
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .buttons--mobile-scroll:before {
    width: calc(var(--gutter) * 1);
    background: linear-gradient(to right, var(--bg-color), transparent);
    left: 0;
  }

  .buttons--mobile-scroll:after {
    width: calc(var(--gutter) * 2);
    background: linear-gradient(to left, var(--bg-color), transparent);
    right: 0;
  }

  .how .buttons--mobile-scroll:before {
    left: calc(var(--gutter-medium) * -.5);
  }

  .how .buttons--mobile-scroll:after {
    right: calc(var(--gutter-medium) * -.5);
  }

  .buttons--mobile-scroll a, .buttons--mobile-scroll label, .buttons--mobile-scroll button {
    flex-shrink: 0;
  }

  .interest-header--banks .buttons {
    padding: .5rem;
  }
}

.calculate .calculate__button2, .calculate .calculate__button {
  display: flex;
}

.calculate .calculate__block {
  margin-bottom: var(--gutter-small);
  background-color: var(--colors-bg-one);
  border-radius: var(--border-radius-large);
  display: block;
  overflow: hidden;
  container-type: inline-size;
}

.calculate .calculate__block .calculate__block-content {
  padding: var(--gutter-block) var(--gutter-block) 0;
}

.calculate .calculate__block .calculate__block-content--no-padding {
  padding-top: 0;
}

.calculate .calculate__block .calculate__block-content:has(.interests) {
  padding: var(--gutter-small);
  padding-left: var(--gutter-medium);
}

@container (width <= 70rem) {
  .calculate .calculate__block .calculate__block-content {
    --gutter-block: var(--gutter-medium);
  }
}

@media (width <= 599px) {
  .calculate .calculate__block .calculate__block-content:has(.interests) {
    padding: var(--gutter-medium);
  }
}

.column {
  --small: calc(50rem + 20vw);
  --vert-padding: 0;
  z-index: 1;
  width: 100%;
  max-width: var(--max-content);
  padding: 0 var(--gutter);
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.column--sticky {
  z-index: 2;
  top: calc(var(--nav-height)  + var(--gutter-small));
  position: sticky;
}

.column--block-smallest-tight {
  margin-bottom: var(--padding);
}

.column--margin {
  margin-bottom: calc(10vmax - var(--gutter-medium));
}

.column--margin-small {
  margin-bottom: var(--padding);
}

.column--padding-small {
  padding-bottom: var(--padding) !important;
}

.main__rest .column--block-small-tight, .main__with-bg .column--block-small-tight, .main__rest .column--block-large-tight, .main__with-bg .column--block-large-tight, .main__rest .column--block-small, .main__with-bg .column--block-small, .main__rest .column--block-large, .main__with-bg .column--block-large {
  padding-bottom: 10vmax;
}

.column--block-smallest-tight, .column--block-small-tight, .column--block-small {
  --small: 100%;
}

.column--block-small {
  --vert-padding: 6vmax;
}

.column--block-large {
  --vert-padding: 8vmax;
}

.column--margin-small, .column--block-smallest-tight, .column--block-small-tight, .column--block-large-tight {
  --vert-padding: var(--gutter-medium);
}

.column .column__content {
  width: 100%;
  max-width: 100%;
  padding-top: var(--vert-padding);
  padding-bottom: calc(var(--vert-padding)  - var(--padding));
  flex-direction: column;
  align-items: center;
  display: flex;
}

.column .column__content--left {
  align-items: flex-start;
}

.column .column__content--broker {
  padding-top: var(--padding);
}

.column .column__content--dark, .column .column__content--bg-white, .column .column__content--bg-grey, .column .column__content--bg-yellow, .column .column__content--bg-blue, .column .column__content--gradient {
  padding-right: var(--gutter-medium);
  padding-left: var(--gutter-medium);
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  position: relative;
}

.column .column__content--dark:has(.image:last-child), .column .column__content--bg-white:has(.image:last-child), .column .column__content--bg-grey:has(.image:last-child), .column .column__content--bg-yellow:has(.image:last-child), .column .column__content--bg-blue:has(.image:last-child), .column .column__content--gradient:has(.image:last-child) {
  padding-bottom: 0;
}

.column .column__content--bg-white {
  --bg-color: var(--colors-bg-one);
}

.column .column__content--bg-grey {
  --bg-color: var(--colors-bg-three);
}

.column .column__content--bg-blue {
  --bg-color: var(--colors-theme-highlight);
}

.column .column__content--bg-yellow {
  --bg-color: var(--colors-theme-two);
}

.column .column__content--dark {
  --color: var(--colors-text-two);
  --bg-color: var(--colors-bg-two);
  padding: var(--gutter);
  color: var(--color);
}

.column .column__content--gradient {
  --bg-color: transparent;
  border: 1px solid var(--colors-theme-highlight);
  overflow: hidden;
}

.splash--berekenen .column .column__content {
  padding-bottom: calc(var(--vert-padding)  - var(--gutter-small));
}

.column .column__content .columns__content-center {
  z-index: 1;
  width: var(--small);
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  display: flex;
  position: relative;
}

.column .column__content .columns__content-center .image:last-child {
  margin-bottom: 0;
}

.column .column__content--dark .columns__content-center, .column .column__content--bg-white .columns__content-center {
  align-items: flex-start;
}

.column--small .column__content {
  width: var(--small);
}

@media (width <= 1200px) {
  .column--padding-small {
    padding-bottom: var(--gutter-medium) !important;
  }
}

@media (width >= 1400px) {
  .column {
    --small: calc(50rem + var(--max-content) * .2);
  }

  .column--block-smallest-tight, .column--block-small-tight, .column--block-small {
    --small: 100%;
  }
}

@media (width <= 1023px) {
  .column .column__content--broker {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 800px) {
  .column .column__content--dark {
    padding: var(--gutter-medium);
  }
}

@media (width <= 599px) {
  .column--sticky {
    top: calc(var(--nav-height)  - 1px);
  }

  .column .column__content--bg-grey {
    background-color: #0000;
    padding-left: 0;
    padding-right: 0;
  }

  .column:has(.how) {
    background-color: var(--colors-bg-three);
    margin-bottom: 10vmax;
    padding-bottom: 0;
  }
}

@media (height <= 440px) {
  .column--sticky {
    position: relative;
    top: 0;
  }
}

.columns {
  --columns-gutter: var(--gutter);
  width: 100%;
  max-width: 1440px;
  padding: 0 calc(var(--columns-gutter) / 2);
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.columns--wide-gutter {
  --columns-gutter: calc(var(--gutter) * 2);
}

.columns--m-s {
  margin-bottom: var(--padding);
}

.columns--m-10 {
  margin-bottom: calc(10vmax - var(--gutter-medium));
}

.footer .columns {
  --columns-gutter: 2rem;
}

.results .columns, .calculate .columns {
  --columns-gutter: 1.2em;
  padding-bottom: calc(var(--gutter-block)  - 1rem);
}

.columns--neg-margins {
  width: calc(100% + var(--columns-gutter) * 2);
  max-width: none;
  margin-right: calc(-1 * var(--columns-gutter));
  margin-left: calc(-1 * var(--columns-gutter));
}

.columns--between {
  justify-content: space-between;
}

.columns--center {
  justify-content: center;
}

.columns--clean {
  padding: 0;
}

.columns--hidden {
  display: none;
}

.columns--vert-center {
  align-items: center;
}

.columns--vert-stretch {
  align-items: stretch;
}

.columns--half-gutter {
  --columns-gutter: calc(var(--gutter) * .5);
}

.columns--no-gutter {
  --columns-gutter: calc(0px);
}

.columns .columns__item {
  width: calc(100% - var(--columns-gutter));
  max-width: calc(100% - var(--columns-gutter));
  margin-right: calc(var(--columns-gutter) / 2);
  margin-left: calc(var(--columns-gutter) / 2);
}

.columns .columns__item--1-2c, .columns .columns__item--1-2 {
  width: calc(50% - var(--columns-gutter));
}

.columns .columns__item--1-3d, .columns .columns__item--1-3c, .columns .columns__item--1-3b, .columns .columns__item--1-3 {
  width: calc(33.3333% - var(--columns-gutter));
}

.columns .columns__item--2-3d, .columns .columns__item--2-3c, .columns .columns__item--2-3b, .columns .columns__item--2-3 {
  width: calc(66.6666% - var(--columns-gutter));
}

.columns .columns__item--1-4c, .columns .columns__item--1-4 {
  width: calc(25% - var(--columns-gutter));
}

.columns .columns__item--3-4 {
  width: calc(75% - var(--columns-gutter));
}

.columns .columns__item--1-5 {
  width: calc(20% - var(--columns-gutter));
}

.columns .columns__item--2-5b, .columns .columns__item--2-5 {
  width: calc(40% - var(--columns-gutter));
}

.columns .columns__item--3-5 {
  width: calc(60% - var(--columns-gutter));
}

.columns .columns__item--4-5 {
  width: calc(80% - var(--columns-gutter));
}

.columns .columns__item--1-6c, .columns .columns__item--1-6 {
  width: calc(16.6666% - var(--columns-gutter));
}

.columns .columns__item--block-c {
  width: calc(27.7777% - var(--columns-gutter));
}

.columns .columns__item--5-6c, .columns .columns__item--5-6 {
  width: calc(83.3333% - var(--columns-gutter));
}

.columns .columns__item--1-12c, .columns .columns__item--1-12 {
  width: calc(8.3333% - var(--columns-gutter));
}

.columns .columns__item--footer {
  width: calc((100% - var(--cta1-width)  - var(--columns-gutter)) / 3 - var(--columns-gutter));
}

.columns .columns__item--cta {
  width: var(--cta1-width);
}

.columns .columns__item--cta-rest {
  width: calc(100% - var(--cta1-width)  - var(--columns-gutter) * 1.25);
}

.columns .columns__item--plus {
  width: calc(55% - var(--columns-gutter));
  align-self: center;
}

.columns .columns__item--end {
  align-self: flex-end;
}

.columns .columns__item--min {
  width: calc(45% - var(--columns-gutter));
}

.columns .columns__item--news, .columns .columns__item--term, .columns .columns__item--person {
  width: calc(33.3333% - var(--columns-gutter));
  max-width: calc(var(--max-content) * .3333 - var(--columns-gutter) * 1.3333);
}

.columns .columns__item--phablet {
  display: none;
}

@media (width <= 1200px) {
  .footer .columns {
    --columns-gutter: var(--gutter);
  }

  .columns .columns__item--block-c {
    width: calc(33.3333% - var(--columns-gutter));
  }

  .columns .columns__item--1-2c {
    width: calc(58.3333% - var(--columns-gutter));
  }

  .columns .columns__item--1-3c {
    width: calc(41.6666% - var(--columns-gutter));
  }

  .columns .columns__item--2-3c {
    width: calc(83.3333% - var(--columns-gutter));
  }

  .columns .columns__item--1-6c {
    width: calc(8.3333% - var(--columns-gutter));
  }

  .columns .columns__item--5-6c, .columns .columns__item--1-12c {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--footer {
    width: calc((var(--max-content)  - var(--columns-gutter) * 2 - var(--cta1-width)) / 3 - var(--columns-gutter));
  }

  .columns .columns__item--news, .columns .columns__item--term, .columns .columns__item--person {
    width: calc(50% - var(--columns-gutter));
  }
}

@media (width <= 1023px) {
  .columns--wide-gutter {
    --columns-gutter: var(--gutter);
  }

  .columns .columns__item--1-3d, .columns .columns__item--2-3d {
    width: calc(50% - var(--columns-gutter));
  }

  .columns .columns__item--2-3 {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--1-4, .columns .columns__item--2-5b {
    width: calc(50% - var(--columns-gutter));
  }

  .columns .columns__item--3-16 {
    width: calc(16.6666% - var(--columns-gutter));
  }

  .columns .columns__item--4-16 {
    width: calc(25% - var(--columns-gutter));
  }

  .columns .columns__item--1-3b, .columns .columns__item--2-3b {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--cta-rest {
    width: calc(var(--max-content) * .4166 - var(--columns-gutter) * 1.25);
  }

  .columns .columns__item--footer {
    width: calc(33.3333% - var(--columns-gutter));
  }

  .columns .columns__item--plus {
    width: calc(65% - var(--columns-gutter));
  }

  .columns .columns__item--min {
    width: calc(35% - var(--columns-gutter));
  }
}

@media (width <= 800px) {
  .columns--wide-gutter {
    --columns-gutter: calc(var(--gutter) * 2);
  }

  .columns .columns__item--block-c {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--1-2c, .columns .columns__item--1-3c {
    width: calc(50% - var(--columns-gutter));
  }

  .columns .columns__item--2-3c, .columns .columns__item--1-6c {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--footer__logo, .columns .columns__item--footer {
    width: calc(50% - var(--columns-gutter));
  }

  .columns .columns__item--plus {
    width: calc(60% - var(--columns-gutter));
  }

  .columns .columns__item--min {
    width: calc(40% - var(--columns-gutter));
  }
}

@media (width <= 599px) {
  .columns .columns__item--1-2, .columns .columns__item--1-3 {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--1-4 {
    width: calc(50% - var(--columns-gutter));
  }

  .columns .columns__item--3-4, .columns .columns__item--1-5, .columns .columns__item--2-5b, .columns .columns__item--4-5 {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--1-6 {
    width: calc(50% - var(--columns-gutter));
  }

  .columns .columns__item--5-6, .columns .columns__item--1-12, .columns .columns__item--5-12 {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--phablet {
    display: block;
  }

  .columns .columns__item--1-2c, .columns .columns__item--1-3c, .columns .columns__item--1-3d, .columns .columns__item--2-3d, .columns .columns__item--footer__logo, .columns .columns__item--footer, .columns .columns__item--person, .columns .columns__item--news, .columns .columns__item--term {
    width: calc(100% - var(--columns-gutter));
  }

  .columns .columns__item--1-3d, .columns .columns__item--2-3d, .columns .columns__item--1-3c, .columns .columns__item--1-2c {
    max-width: calc(var(--max-content) * .3333 - var(--columns-gutter) * 1.3333);
  }

  .columns .columns__item--plus, .columns .columns__item--min {
    width: calc(100% - var(--columns-gutter));
  }
}

@media (width <= 374px) {
  .columns .columns__item--1-2, .columns .columns__item--1-3, .columns .columns__item--2-3, .columns .columns__item--1-4, .columns .columns__item--1-6 {
    width: calc(100% - var(--columns-gutter));
  }
}

.cta1 {
  width: var(--cta1-width);
  max-width: 100%;
  margin-bottom: var(--padding);
  padding: var(--gutter-medium);
  border-radius: var(--border-radius-large);
  position: relative;
  overflow: hidden;
}

.cta1--no-padding {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.cta1--margin-low {
  margin-bottom: var(--gutter-small);
}

.footer .cta1 {
  --color: var(--colors-text-two);
  color: var(--color);
  background-color: var(--colors-glow);
}

.cta1 .cta1__heading {
  margin-bottom: .4em;
}

@media (width <= 1200px) {
  .splash__cta .cta1 {
    display: none;
  }
}

@media (width <= 599px) {
  .cta1--no-padding {
    margin-bottom: var(--padding);
    display: block !important;
  }

  .splash .cta1 {
    display: none;
  }
}

.footer {
  --color: var(--colors-theme-highlight);
  --bg-color: var(--colors-bg-two);
  color: var(--color);
  background-color: var(--bg-color);
  padding: 5vmax 0;
}

.footer a, .footer button {
  color: var(--color);
}

.footer .footer__logo, .footer .footer__list {
  margin-bottom: var(--gutter-large) !important;
}

.footer .footer__logo {
  display: flex;
}

.footer .footer__list {
  --lineheight: 1.8;
  line-height: var(--lineheight);
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.footer .footer__list .footer__title, .footer .footer__list .footer__item {
  display: flex;
}

.footer .footer__list .footer__title {
  font-weight: 700;
}

.footer .footer__list .footer__item--active, .footer .footer__list .footer__item.hover, body:not(.body--touch) .footer .footer__list .footer__item:focus, body:not(.body--touch) .footer .footer__list .footer__item:hover {
  text-underline-offset: .2rem;
  text-decoration: underline;
}

.footer .footer__line {
  margin-top: 10vmax;
}

@media (width <= 599px) {
  .footer .footer__line {
    text-align: center;
    text-wrap: balance;
  }
}

.highlights {
  --swiper-navigation-sides-offset: -4.8rem;
  margin-top: var(--gutter-large);
  padding: 0 calc(var(--gutter)  + 4.8rem) calc(10vmax + var(--gutter-large));
  overflow: hidden;
}

.highlights .highlights__swiper {
  width: 100%;
  overflow: visible;
}

.highlights .highlights__swiper .swiper-slide {
  width: 44rem;
}

.highlights .highlights__swiper .swiper-button-prev, .highlights .highlights__swiper .swiper-button-next {
  --size: 6.4rem;
  width: var(--size);
  height: var(--size);
  background-color: var(--colors-bg-one);
  box-shadow: 0 8px 16px var(--colors-shade);
  border-radius: 10rem;
}

.highlights .highlights__swiper .swiper-button-prev:after, .highlights .highlights__swiper .swiper-button-next:after {
  pointer-events: none;
  content: "";
  border-top: 3px solid var(--color);
  border-right: 3px solid var(--color);
  width: 1.4rem;
  height: 1.4rem;
  transition: border-color, transform;
  transition-duration: var(--transition-default);
  margin-left: -.6rem;
  display: flex;
  transform: rotate(45deg);
}

.highlights .highlights__swiper .swiper-button-prev.hover:after, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-prev:focus:after, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-prev:hover:after, .highlights .highlights__swiper .swiper-button-next.hover:after, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-next:focus:after, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-next:hover:after {
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.highlights .highlights__swiper .swiper-button-prev:after, .highlights .highlights__swiper .swiper-button-next:after {
  border-color: var(--colors-text-one);
}

.highlights .highlights__swiper .swiper-button-prev.hover, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-prev:focus, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-prev:hover, .highlights .highlights__swiper .swiper-button-next.hover, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-next:focus, body:not(.body--touch) .highlights .highlights__swiper .swiper-button-next:hover {
  color: var(--colors-text-one);
  background-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.highlights .highlights__swiper .swiper-button-prev {
  box-shadow: 0 -8px 16px var(--colors-shade);
  transform: rotate(180deg);
}

.highlights .highlights__swiper .swiper-pagination {
  --swiper-pagination-top: calc(100% + var(--gutter-large));
  --swiper-pagination-bottom: auto;
  width: 100% !important;
}

.highlights .highlights__swiper .swiper-pagination-bullet {
  --swiper-pagination-bullet-width: 1.6rem;
  --swiper-pagination-bullet-height: 1.2rem;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 1rem;
  background-color: var(--colors-bg-four);
  transition: width, background-color;
  transition-duration: var(--transition-fast), var(--transition-default);
  border-radius: 10rem;
  transform: none;
}

.highlights .highlights__swiper .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 6.4rem;
  background-color: var(--colors-text-one);
}

.highlights .highlights__swiper .swiper-pagination-bullet-active + .swiper-pagination-bullet, .highlights .highlights__swiper .swiper-pagination-bullet:has( + .swiper-pagination-bullet-active) {
  --swiper-pagination-bullet-width: 3rem;
}

@media (width <= 1200px) {
  .highlights {
    --swiper-navigation-sides-offset: calc((var(--gutter-small)  + 6.4rem) * -1);
    padding-right: calc(var(--gutter)  + var(--gutter-small)  + 6.4rem);
    padding-left: calc(var(--gutter)  + var(--gutter-small)  + 6.4rem);
  }
}

@media (width <= 1023px) {
  .highlights {
    --swiper-navigation-sides-offset: -4.8rem;
    padding-right: calc(var(--gutter)  + 4.8rem);
    padding-left: calc(var(--gutter)  + 4.8rem);
  }
}

@media (width <= 599px) {
  .highlights {
    --swiper-navigation-sides-offset: calc(var(--gutter-small) * -1);
    padding-right: calc(var(--gutter)  + var(--gutter-small));
    padding-left: calc(var(--gutter)  + var(--gutter-small));
  }

  .highlights .highlights__swiper .swiper-button-prev, .highlights .highlights__swiper .swiper-button-next {
    --size: 4.8rem;
  }
}

.how {
  width: 100%;
  padding: var(--gutter-medium) var(--gutter-medium) 0;
}

.how .how__header {
  max-width: 30em;
  margin-bottom: var(--gutter-large);
}

.how .how__content {
  margin-bottom: var(--gutter-large);
  align-items: flex-start;
  display: flex;
  position: relative;
}

.how .how__content .how__buttons {
  flex-shrink: 0;
  width: auto;
}

.how .how__content .how__texts {
  --lineheight: 1.5;
  font-size: var(--font-size-intro);
  flex-grow: 1;
  align-items: flex-start;
  padding: 0 10% 1px;
  display: flex;
}

@media (width <= 1200px) {
  .how {
    padding: 0;
  }

  .how .how__content {
    margin-bottom: var(--gutter-medium);
  }
}

@media (width <= 1023px) {
  .how .how__content .how__texts {
    margin-left: var(--gutter-medium);
    padding: 0;
  }
}

@media (width <= 599px) {
  .how .how__header {
    margin-bottom: var(--gutter-small);
  }

  .how .how__content {
    flex-direction: column;
    width: 100%;
  }

  .how .how__content .how__buttons {
    width: calc(100% + var(--gutter-small) * 2);
    margin: 0 calc(var(--gutter-small) * -1) var(--gutter-small);
    padding: 0 calc(var(--gutter-small)  - .5rem);
    flex-flow: row;
    overflow: scroll;
  }

  .how .how__content .how__texts {
    margin-left: 0;
  }
}

.interest-header {
  width: 100%;
  transition: border-radius var(--transition-fast), box-shadow var(--transition-fast);
}

.interest-header--sticky {
  box-shadow: 0 16px 16px var(--colors-shade);
}

.interest-header .interest-header__content {
  width: calc(100% + var(--gutter-small) * 2);
  margin-right: calc(-1 * var(--gutter-small));
  margin-bottom: var(--gutter-small);
  margin-left: calc(-1 * var(--gutter-small));
  padding: 0 calc(var(--gutter-small) / 2);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.interest-header .interest-header__content .interest-header__block {
  margin: 0 calc(var(--gutter-small) / 2) var(--gutter-medium);
}

.interest-header .interest-header__content .interest-header__block--label {
  width: 15em;
}

.interest-header .interest-header__content .interest-header__block--energy {
  padding-left: 1em;
}

.interest-header .interest-header__content .interest-header__block .interest-header__block-desc {
  align-items: center;
  margin-bottom: .8em;
  display: flex;
}

.interest-header .interest-header__content .interest-header__block .interest-header__block-desc .interest-header__block-info {
  margin-right: .5em;
}

.interest-header .interest-header__content .interest-header__block .interest-header__block-buttons {
  flex-wrap: nowrap;
  display: flex;
}

.interest-header--dark {
  --color: var(--colors-text-two);
  margin-bottom: var(--padding);
  padding: var(--gutter-medium) var(--gutter-medium) 0;
  color: var(--color);
  background-color: var(--colors-bg-two);
  border-radius: var(--border-radius-large);
}

.interest-header--dark .interest-header__content {
  margin-bottom: 0;
}

.interest-header--dark .interest-header__block {
  margin-bottom: var(--gutter-medium);
}

@media (width <= 1023px) {
  .interest-header .interest-header__content .interest-header__block--energy {
    padding-left: 0;
  }

  .interest-header .interest-header__content .interest-header__block--label {
    width: auto;
  }

  .interest-header--dark {
    padding: var(--gutter-medium) calc(var(--gutter-medium) * 2) 0;
  }

  .interest-header--dark .interest-header__content {
    justify-content: space-between;
  }

  .interest-header--dark .interest-header__content .interest-header__block--nhg {
    order: 1;
    width: 100%;
  }

  .interest-header--banks {
    padding: var(--gutter-medium) var(--gutter-medium) 0;
    justify-content: space-between;
  }

  .interest-header--banks .interest-header__content {
    justify-content: space-between;
    align-items: center;
  }

  .interest-header--banks .interest-header__content .interest-header__block--label {
    margin-bottom: 0;
  }
}

@media (width <= 599px) {
  .interest-header .interest-header__content .interest-header__block {
    margin-bottom: calc(var(--gutter-medium) * .5);
  }

  .interest-header--dark {
    --lineheight: 1.5;
    font-size: var(--font-size-small);
    --color: var(--colors-text-one);
    padding: var(--gutter-small) var(--gutter-medium) 0;
    background-color: var(--colors-bg-three);
    border-radius: var(--gutter-medium);
  }

  .interest-header--dark .interest-header__content {
    justify-content: space-between;
  }

  .interest-header--dark .interest-header__content .interest-header__block--nhg {
    order: 0;
    width: auto;
  }

  .interest-header--dark .interest-header__content .interest-header__block--energy, .interest-header--dark .interest-header__content .interest-header__block:first-child {
    order: -1;
  }

  .interest-header--sticky {
    border-radius: 0 0 var(--gutter-medium) var(--gutter-medium);
    box-shadow: 0 0 16px var(--colors-shade);
  }

  .interest-header--banks .interest-header__content .interest-header__block--label {
    order: -2;
    width: 100%;
  }

  .interest-header--banks .interest-header__content .interest-header__block--nhg {
    width: 100%;
  }

  .interest-header--banks .interest-header__content .interest-header__block:first-child {
    width: auto;
  }
}

.interests-holder {
  width: 100%;
  margin-bottom: var(--gutter-medium);
}

@media (width <= 599px) {
  .interests-holder {
    max-height: calc(var(--interest-height) * 10 + var(--interest-gap) * 1);
    transition: max-height var(--transition-slow) var(--transition-easing);
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
  }

  .interests-holder:after {
    pointer-events: none;
    content: "";
    width: 100%;
    height: var(--interest-height);
    background: linear-gradient(180deg, transparent 0%, var(--colors-bg-one) 100%);
    transition: opacity var(--transition-default) var(--transition-default);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .interests-holder--full {
    max-height: calc(var(--scroll-height)  + 1px);
  }

  .interests-holder--full:after {
    opacity: 0;
  }
}

.interests {
  width: 100%;
  overflow: hidden;
}

.interests .interests__header {
  text-indent: -9999px;
  margin-bottom: -1em;
  display: flex;
  overflow: hidden;
}

.interests .interests__item {
  width: 100%;
  padding: var(--gutter-small) var(--gutter-medium);
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.interests .interests__item > * {
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.interests .interests__item .interests__item-brand, .interests .interests__item .interests__item-rate, .interests .interests__item .interests__item-rates {
  flex-grow: 1;
}

.interests .interests__item .interests__item-brand {
  align-items: center;
  display: flex;
}

.interests .interests__item .interests__item-brand .interests__item-logo {
  width: 13rem;
  margin-right: var(--gutter-medium);
  flex-shrink: 0;
}

.interests .interests__item .interests__item-brand .interests__item-logo img {
  max-height: 2.9em;
}

.interests .interests__item .interests__item-brand .interests__item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: .2em 0;
  overflow: hidden;
}

.interests .interests__item .interests__item-rate {
  --lineheight: 1.15;
  font-size: var(--font-size-normal);
  --underline-width: 1px;
  --underline-top: calc(50% + .55em);
  --shadow-width: .08em;
  font-variation-settings: "wdth" 85;
  white-space: nowrap;
  font-weight: 700;
}

.interests .interests__item .interests__item-calc {
  text-align: right;
}

.interests .interests__item .interests__item-calc--faded-out {
  pointer-events: none;
  height: calc(var(--font-size-normal) * 1.5);
  opacity: 0;
}

.interests--splash {
  margin-bottom: var(--gutter-small);
}

.interests--splash .interests__item {
  margin-bottom: var(--gutter-small);
  padding-right: var(--gutter-small);
  background-color: var(--colors-bg-one);
  border-radius: var(--gutter-large);
}

.interests--splash .interests__item > :first-child {
  border-radius: var(--gutter-large) 0 0 var(--gutter-large);
}

.interests--splash .interests__item > :last-child {
  border-radius: 0 var(--gutter-large) var(--gutter-large) 0;
}

.interests--splash .interests__item .interests__item-brand {
  width: calc(13rem + 11em);
}

.interests--calculate .interests__item {
  padding: 0;
}

.interests--banks, .interests--regular {
  border-collapse: separate;
  border: var(--colors-theme-border) 1px solid;
  border-radius: var(--gutter-large);
}

.interests--banks .interests__item, .interests--regular .interests__item {
  border-bottom: var(--colors-theme-border) 1px solid;
}

.interests--banks .interests__item:last-child, .interests--regular .interests__item:last-child {
  border-bottom: 0;
}

.interests--banks .interests__item .interests__item-brand, .interests--regular .interests__item .interests__item-brand {
  width: calc(var(--interest-header-left-width)  + var(--interest-header-right-diff));
}

.interests--banks .interests__gap, .interests--regular .interests__gap {
  width: calc(100% + 2px);
  padding-top: var(--gutter-medium);
  background-color: var(--colors-bg-three);
  border-bottom: var(--colors-theme-border) 1px solid;
  margin: 0 -1px;
  display: flex;
}

.interests--banks .interests__gap td, .interests--regular .interests__gap td {
  width: 100%;
}

.interests--regular {
  background-color: var(--colors-bg-one);
  overflow: visible;
}

.interests--banks {
  margin-bottom: var(--padding);
}

@media (width <= 1200px) {
  .interests--banks .interests__item .interests__item-brand .interests__item-logo img, .interests--regular .interests__item .interests__item-brand .interests__item-logo img {
    max-height: 2.5em;
  }
}

@media (width <= 1023px) {
  .interests--banks .interests__item, .interests--regular .interests__item {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .interests--banks .interests__item .interests__item-brand, .interests--regular .interests__item .interests__item-brand {
    width: calc(100% - 12em);
  }

  .interests--banks .interests__item .interests__item-rates, .interests--regular .interests__item .interests__item-rates {
    order: 1;
    width: 100%;
  }

  .interests--calculate .interests__item .interests__item-brand .interests__item-logo {
    display: flex;
  }
}

@media (width <= 599px) {
  .interests .interests__item {
    margin-bottom: var(--gutter-small);
    padding: var(--gutter-medium);
    border: var(--colors-theme-border) 1px solid;
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }

  .interests .interests__item > * {
    margin-bottom: var(--gutter-small);
    background-color: #0000;
    padding: 0;
  }

  .interests .interests__item > :first-child, .interests .interests__item > :last-child {
    padding: 0;
  }

  .interests .interests__item .interests__item-brand {
    margin-bottom: .6rem;
    margin-right: 0;
    width: auto !important;
  }

  .interests .interests__item .interests__item-logo {
    order: 0;
  }

  .interests .interests__item .interests__item-name {
    width: 100%;
    max-width: calc(100vw - var(--gutter) * 2 - var(--gutter-medium) * 2);
    order: 2;
  }

  .interests .interests__item .interests__item-rate, .interests .interests__item .interests__item-rates {
    flex-grow: 0;
  }

  .interests .interests__item .interests__item-rate {
    flex-grow: 0;
    order: 1;
    margin-bottom: 1rem;
  }

  .interests .interests__item .interests__item-rates {
    width: auto;
    padding-left: 0;
  }

  .interests .interests__item .interests__item-calc {
    flex-direction: column;
    order: 3;
    align-items: stretch;
    width: 100%;
    margin-bottom: 0;
    display: flex;
  }

  .interests--banks, .interests--regular {
    border: 0;
    border-radius: 0;
  }

  .interests--banks .interests__item, .interests--regular .interests__item {
    border-radius: var(--gutter-large);
  }

  .interests--banks .interests__item:last-child, .interests--regular .interests__item:last-child {
    border-bottom: var(--colors-theme-border) 1px solid;
  }

  .interests--banks .interests__gap, .interests--regular .interests__gap {
    background-color: #0000;
    border-bottom: 0;
  }

  .interests--banks {
    align-items: baseline;
  }

  .interests--banks .interests__item .interests__item-brand {
    width: 100% !important;
  }

  .interests--calculate, .interests--calculate .interests__item {
    border: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .interests--calculate .interests__item .interests__item-name {
    order: 1;
    width: 100%;
    margin-top: .5rem;
  }

  .interests--calculate .interests__item .interests__item-rate {
    order: 2;
    margin-bottom: 0;
    margin-left: 0;
  }

  .interests--calculate .interests__item .interests__item-calc {
    width: auto;
  }
}

.main {
  transition: opacity, transform;
  transition-timing-function: ease, var(--transition-easing-out);
  transition-duration: var(--transition-slow);
  position: relative;
}

.html--loader .main {
  transform: translateY(var(--gutter-large));
  opacity: 0;
  transition-timing-function: ease, var(--transition-easing-in);
}

.html--loader .body--firefox .main {
  transform: none;
}

.main .main__with-bg {
  --border-radius: 4.8rem;
  background-color: var(--bg-color);
  --bg-color: var(--colors-bg-one);
  padding-top: calc(var(--gutter-large) * 2);
  flex-direction: column;
  display: flex;
  position: relative;
}

.main .main__with-bg:after, .main .main__with-bg:before {
  content: "";
  z-index: -1;
  width: var(--border-radius);
  height: var(--border-radius);
  position: absolute;
  bottom: 100%;
  left: 0;
}

.main .main__with-bg:before {
  background: radial-gradient(circle at 100% 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
}

.main .main__with-bg:after {
  background: radial-gradient(circle at 0 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
  left: auto;
  right: 0;
}

.main .main__with-bg--home {
  padding-top: 10vmax;
}

.main .main__with-bg--img-article, .main .main__with-bg--rentes {
  padding-top: 0;
}

.main .main__with-bg--img-article > :first-child, .main .main__with-bg--rentes > :first-child {
  margin-top: calc(var(--overlap) * -1);
}

.main .main__with-bg--rentes {
  --overlap: calc(var(--interest-header-height)  + 10vmax);
}

.main .main__with-bg--img-article {
  --overlap: calc(var(--article-img-height) * .5);
}

.main .main__with-bg--banks {
  margin-top: calc(var(--nav-height) * -1);
  padding-top: calc(var(--nav-height)  + var(--gutter-large) * 2);
}

.main .main__with-bg:first-child:before, .main .main__with-bg:first-child:after {
  content: none;
}

.main .main__rest {
  --bg-color: var(--colors-bg-one);
  background-color: var(--bg-color);
  flex-direction: column;
  display: flex;
  position: relative;
}

.main .main__rest--padding-top {
  padding-top: calc(10vmax - var(--padding));
}

@media (width <= 599px) {
  .main .main__with-bg--rentes > :first-child {
    margin-top: var(--gutter-medium);
  }
}

.nav {
  backdrop-filter: blur(8px);
  position: sticky;
  top: 0;
}

.nav:before {
  top: 0;
}

.nav:after {
  bottom: 0;
}

.nav:before, .nav:after {
  content: "";
  background-color: var(--colors-shade);
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
}

.nav .nav__bg {
  z-index: -1;
  opacity: 0;
  background-color: var(--colors-theme-one);
  width: 100%;
  height: 100%;
  transition-duration: var(--transition-default), var(--transition-default);
  transition-property: opacity, background-color;
  position: absolute;
  top: 0;
  left: 0;
}

.body--scrolled .nav .nav__bg {
  opacity: .75;
}

.body--nav-white .nav .nav__bg {
  background-color: var(--colors-bg-one) !important;
}

.body--nav .nav .nav__bg, .body--nav-black .nav .nav__bg {
  background-color: var(--colors-bg-two) !important;
}

.body[data-pagetype="page"] .nav .nav__bg, .body[data-pagetype="news"] .nav .nav__bg, .body[data-pagetype="knowledge"] .nav .nav__bg, .body[data-pagetype="contact"] .nav .nav__bg {
  background-color: var(--colors-bg-three);
}

.nav .nav__logo {
  margin-right: auto;
}

.body--nav .nav .nav__logo svg path, .body--nav-black .nav .nav__logo svg path {
  fill: var(--colors-text-two);
}

.nav .nav__content {
  padding: var(--gutter-small) 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav .nav__menu {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav .nav__menu .nav__menu-item {
  --color: var(--colors-text-one);
  margin: 0 var(--gutter-large);
  font-weight: 500;
  line-height: var(--lineheight);
  color: var(--color);
  background-image: linear-gradient(var(--color), var(--color));
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 50% var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-decoration: none;
  transition-property: background-size, opacity;
}

.body--nav-black .nav .nav__menu .nav__menu-item {
  --color: var(--colors-text-two);
}

.nav .nav__menu .nav__menu-item--active, .nav .nav__menu .nav__menu-item.hover, body:not(.body--touch) .nav .nav__menu .nav__menu-item:focus, body:not(.body--touch) .nav .nav__menu .nav__menu-item:hover {
  transition-duration: var(--transition-fast);
  background-position: 50% var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
}

.nav .nav__menu .nav__menu-address {
  display: none;
}

.nav .nav__lang {
  --lineheight: 1.5;
  font-size: var(--font-size-small);
  margin-left: auto;
  position: relative;
}

.nav .nav__lang .nav__lang-current {
  pointer-events: none;
}

.nav .nav__lang .nav__lang-dropdown {
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  width: auto;
  transition: opacity var(--transition-default);
  padding-top: calc(2.2em + 1.5rem);
  position: absolute;
  top: 0;
}

.nav .nav__lang--active .nav__lang-dropdown, .nav .nav__lang.hover .nav__lang-dropdown, body:not(.body--touch) .nav .nav__lang:focus .nav__lang-dropdown, .nav .nav__lang:hover .nav__lang-dropdown {
  pointer-events: auto;
  opacity: 1;
  transition-duration: var(--transition-fast);
}

.nav .nav__buttons {
  justify-content: space-between;
  align-items: center;
  width: auto;
  display: flex;
}

.nav .nav__buttons .nav__button {
  margin-left: var(--gutter-small);
}

@media (width <= 1400px) {
  .nav .nav__menu .nav__menu-item {
    margin: 0 calc(var(--gutter-large) * .5);
  }
}

@media (width <= 1200px) {
  .nav .nav__menu .nav__menu-item {
    margin: 0 calc(var(--gutter-large) * .3);
  }

  .nav .nav__buttons .nav__button .nav__button-text {
    width: 0;
    overflow: hidden;
  }

  .nav .nav__buttons .nav__button svg {
    margin-right: 0;
  }
}

@media (width <= 1023px) {
  .nav .nav__buttons .nav__button {
    padding: .9em;
  }

  .nav .nav__buttons .nav__button--afspraak {
    display: none;
  }

  .nav .nav__buttons .nav__button--login {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }
}

@media (width <= 800px) {
  .nav .nav__logo {
    z-index: 2;
  }

  .nav .nav__menu {
    pointer-events: none;
    z-index: 1;
    transform: translateY(calc(var(--gutter-large) * -1));
    width: 100%;
    height: 100vh;
    padding: 10rem var(--gutter-small) calc(4rem + var(--navbar-height));
    color: var(--colors-text-two);
    opacity: 0;
    background-color: var(--colors-bg-two);
    transition: transform, opacity;
    transition-duration: var(--transition-slow);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
  }

  .nav .nav__menu .nav__menu-item {
    --lineheight: 1.15;
    font-size: var(--font-size-normal);
    --underline-width: 1px;
    --underline-top: calc(50% + .59em);
    --shadow-width: .08em;
    font-variation-settings: "wdth" 85;
    background-position: 0 var(--underline-top);
    background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
    text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
    --color: var(--colors-text-two);
    --bg-color: var(--colors-bg-two);
    margin: 0;
    font-weight: 700;
    line-height: 1.2;
  }

  .nav .nav__menu .nav__menu-item--active, .nav .nav__menu .nav__menu-item.hover, body:not(.body--touch) .nav .nav__menu .nav__menu-item:focus, body:not(.body--touch) .nav .nav__menu .nav__menu-item:hover {
    background-position: 0 var(--underline-top);
    background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  }

  .nav .nav__menu .nav__menu-address {
    color: var(--colors-theme-highlight);
    display: block;
  }

  .nav .nav__menu .nav__menu-address a {
    color: var(--colors-theme-highlight);
  }

  .nav .nav__lang, .nav .nav__buttons {
    z-index: 0;
  }

  .body--nav .nav .nav__menu {
    pointer-events: auto;
    opacity: 1;
    transition-duration: var(--transition-default);
    transform: translateY(0);
  }
}

.picture {
  --color: var(--colors-text-two);
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium);
  color: var(--color);
  border-radius: var(--border-radius-large);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.picture .picture__overlay {
  background: linear-gradient(180deg, var(--colors-shade) 0%, #0000004d 100%);
  position: absolute;
  inset: 0;
}

.picture .picture__heading {
  max-width: 40rem;
  margin-top: 2em;
  position: relative;
}

.picture .picture__text {
  --lineheight: 1.5;
  font-size: var(--font-size-intro);
  text-wrap: balance;
  margin-top: 1em;
  position: relative;
}

@media (width <= 599px) {
  .picture {
    aspect-ratio: 5 / 6;
  }
}

.results-form {
  flex-direction: column;
  display: flex;
}

.results .results__block {
  margin-bottom: var(--padding);
  background-color: var(--colors-bg-one);
  border-radius: var(--border-radius-large);
  display: block;
  overflow: hidden;
  container-type: inline-size;
}

.results .results__block--overflow {
  overflow: visible;
}

.results .results__block .results__block-content {
  padding: var(--gutter-block) var(--gutter-block) 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.results .results__block .results__block-content--grey {
  background-color: var(--colors-theme-border);
  flex-direction: row;
  overflow: hidden;
}

.results .results__block .results__block-content--grey .results__block-text {
  flex-grow: 1;
  align-self: center;
  display: block;
}

.results .results__block .results__block-content--grey .results__block-image {
  flex-shrink: 0;
  align-self: flex-end;
  width: 40%;
  margin-bottom: -15%;
  margin-left: 0%;
}

.results .results__block .results__block-content--image {
  padding: calc(var(--gutter-block) * 2) var(--gutter-block) var(--gutter-block);
  justify-content: flex-start;
}

.results .results__block .results__block-content--image .results__block-text {
  flex-grow: initial;
  width: 60%;
  position: relative;
}

.results .results__block .results__block-content--image .results__block-bg-image {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.results .results__block .results__block-content--image .results__block-bg-image:after {
  content: "";
  background: linear-gradient(to right, #d7d7d7 0%, #d7d7d7fc 8.1%, #d7d7d7f3 15.5%, #d7d7d7e4 22.5%, #d7d7d7d2 29%, #d7d7d7bd 35.3%, #d7d7d7a5 41.2%, #d7d7d78c 47.1%, #d7d7d773 52.9%, #d7d7d75a 58.8%, #d7d7d742 64.7%, #d7d7d72d 71%, #d7d7d71b 77.5%, #d7d7d70c 84.5%, #d7d7d703 91.9%, #d7d7d700 100%);
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@container (width <= 65rem) {
  .results .results__block .results__block-content--image {
    padding-top: var(--gutter-block);
    flex-direction: column;
    padding-bottom: 0;
  }

  .results .results__block .results__block-content--image .results__block-text {
    width: 100%;
  }

  .results .results__block .results__block-content--image .results__block-bg-image {
    aspect-ratio: 1;
    border-radius: 10rem;
    width: 8.2rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .results .results__block .results__block-content--image .results__block-bg-image:after {
    content: none;
  }
}

.results .results__block .results__block-content:has(.interests) {
  padding: var(--gutter-small);
  padding-left: var(--gutter-medium);
}

@container (width <= 70rem) {
  .results .results__block .results__block-content {
    --gutter-block: var(--gutter-medium);
  }
}

.results .results__item {
  padding: var(--gutter-medium);
  color: var(--color);
  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);
  margin-bottom: 1.6rem;
  padding-bottom: 1px;
}

.results .results__item:last-child, .results .results__item--grey, .results .results__item--dark {
  margin-bottom: var(--gutter-block);
}

.results .results__item--grey, .results .results__item--dark {
  background-color: var(--bg-color);
  border: 0;
}

.results .results__item--grey {
  --bg-color: var(--colors-bg-three);
}

.results .results__item--dark {
  --bg-color: var(--colors-bg-two);
  --color: var(--colors-text-two);
  justify-content: space-between;
  display: flex;
}

.results .results__item .results__item-cell {
  width: calc(50% - var(--gutter-medium) * .5);
  color: var(--color);
}

.results .results__item .results__item-cell--alt {
  --color: var(--colors-theme-two);
}

.results .results__item .results__item-flex {
  margin-bottom: calc(var(--gutter-medium)  - .2em);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (width <= 1200px) {
  .results .results__block .results__block-content {
    --gutter-results: var(--gutter-medium);
  }

  .results .results__block .results__block-content--numbers {
    --gutter-results: 1.6rem;
  }
}

@media (width <= 599px) {
  .results .results__block .results__block-content--grey {
    --gutter-results: var(--gutter-medium);
  }

  .results .results__block .results__block-content--grey .results__block-image {
    display: none;
  }

  .results .results__item--dark {
    display: block;
  }

  .results .results__item .results__item-cell {
    width: 100%;
  }
}

.splash {
  padding-bottom: var(--gutter);
}

.splash--rentes {
  padding-bottom: calc(var(--interest-header-height)  + 10vmax);
}

.splash--img-article {
  padding-bottom: calc(var(--article-img-height) * .5);
}

.splash--berekenen {
  padding-bottom: 0;
}

.splash .splash__jumbo {
  padding: 10vmax 0 calc(10vmax - var(--padding));
  flex-direction: column;
  align-items: center;
  display: flex;
}

.splash .splash__jumbo--small {
  padding: 6vmax 0 calc(6vmax - var(--padding));
}

.splash .splash__jumbo--cbs {
  padding: 6vmax 0 calc(6vmax - var(--padding) * 2);
}

.splash .splash__jumbo .splash__heading {
  text-align: center;
  margin-bottom: .2em;
}

.splash .splash__rest .splash__main {
  padding: var(--padding) var(--gutter-large);
  background-color: var(--colors-bg-layer);
  border: 1px solid var(--colors-bg-three);
  border-radius: var(--border-radius-large);
}

.splash .splash__rest .splash__main .splash__main-heading {
  margin-bottom: var(--gutter-medium);
}

.splash .splash__rest .splash__side {
  flex-direction: column;
  display: flex;
}

.splash .splash__split {
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.splash .splash__split > :first-child {
  margin-right: var(--gutter);
}

.splash .splash__split .splash__cta {
  z-index: 2;
  top: calc(var(--nav-height)  + var(--gutter-small));
  width: var(--cta1-width);
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  position: sticky;
}

.splash .splash__split .splash__cta-rest {
  flex-grow: 1;
}

@media (width <= 1200px) {
  .splash .splash__rest .splash__main {
    padding: var(--gutter-medium);
  }

  .splash .splash__split .splash__cta {
    width: calc(33.3333% - var(--gutter-medium) * .5);
  }
}

@media (width <= 1023px) {
  .splash .splash__split {
    flex-wrap: wrap;
  }

  .splash .splash__split > :first-child {
    margin-right: 0;
  }

  .splash .splash__split .splash__cta {
    width: 40rem;
    position: relative;
    top: auto;
  }

  .splash .splash__split .splash__cta-rest {
    width: 100%;
    max-width: calc(var(--max-content)  - var(--cta1-width)  - var(--gutter) * 3);
  }

  .splash .splash__rest .splash__side {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (width <= 599px) {
  .splash {
    padding-bottom: 0;
  }

  .splash--img-article {
    padding-bottom: calc(var(--article-img-height) * .5);
  }

  .splash .splash__rest {
    --border-radius: 4.8rem;
    background-color: var(--bg-color);
    --bg-color: var(--colors-bg-one);
    padding-top: var(--gutter-large);
    padding-bottom: var(--gutter);
    position: relative;
  }

  .splash .splash__rest:after, .splash .splash__rest:before {
    content: "";
    z-index: -1;
    width: var(--border-radius);
    height: var(--border-radius);
    position: absolute;
    bottom: 100%;
    left: 0;
  }

  .splash .splash__rest:before {
    background: radial-gradient(circle at 100% 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
  }

  .splash .splash__rest:after {
    background: radial-gradient(circle at 0 0, transparent 70.7%, var(--colors-bg-one) 71.7%);
    left: auto;
    right: 0;
  }

  .splash .splash__rest .splash__main {
    background-color: var(--colors-bg-one);
    border: none;
    padding: 0;
  }

  .splash .splash__rest .splash__side {
    flex-direction: column;
    align-items: center;
  }
}

.terms {
  width: 100%;
  margin-bottom: calc(8vmax - var(--gutter-medium));
}

.ticker {
  padding-top: var(--gutter-large);
  padding-bottom: 10vmax;
  overflow: hidden;
}

.ticker .ticker__line {
  display: flex;
}

.ticker .ticker__line:not(:first-child) {
  margin-top: calc(var(--gutter-medium) * 1);
}

.ticker .ticker__line .ticker__repeater {
  animation-name: linear-left;
  animation-duration: var(--duration);
  flex-shrink: 0;
  align-items: center;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: flex;
  position: relative;
}

.ticker .ticker__line:not(:last-child) .ticker__repeater {
  left: -10rem;
}

.toggler {
  --toggler-gutter: var(--gutter-medium);
  margin-bottom: 1.6rem;
  margin-bottom: var(--gutter-block);
  color: var(--color);
  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: relative;
}

.toggler .toggler__bg {
  --color: var(--colors-text-one);
  --active-bg-color: var(--colors-theme-highlight);
  --inactive-bg-color: transparent;
  --lineheight: 1.2;
  z-index: 0;
  color: var(--color);
  background-color: var(--inactive-bg-color);
  border-radius: var(--gutter-medium);
  transition: background-color, color, border-color, opacity;
  transition-duration: var(--transition-default);
  border: 1px solid var(--colors-theme-border);
  height: calc(100% + 2px);
  box-shadow: 0 8px 16px var(--colors-shade);
  transition: transform var(--transition-default) var(--transition-easing);
  justify-content: center;
  align-items: center;
  padding: 1.2em;
  display: inline-flex;
  position: absolute;
  top: -1px;
  left: -1px;
  overflow: hidden;
}

.toggler .toggler__bg:before {
  content: "";
  z-index: -1;
  background: linear-gradient(180deg, var(--inactive-bg-color) 0%, var(--inactive-bg-color) 20%, var(--colors-theme-two) 40%, var(--colors-theme-two) 60%, var(--active-bg-color) 80%, var(--active-bg-color) 100%);
  width: 100%;
  height: 500%;
  transition: transform var(--transition-default) var(--transition-easing);
  position: absolute;
  top: 0;
  left: 0;
}

.toggler .toggler__item {
  --color: var(--colors-text-one);
  --active-bg-color: var(--colors-theme-highlight);
  --inactive-bg-color: transparent;
  --lineheight: 1.2;
  z-index: 0;
  color: var(--color);
  background-color: var(--inactive-bg-color);
  border-radius: var(--gutter-medium);
  transition: background-color, color, border-color, opacity;
  transition-duration: var(--transition-default);
  cursor: pointer;
  line-height: var(--lineheight);
  text-align: center;
  mix-blend-mode: multiply;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1.2em;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.toggler .toggler__item:before {
  content: "";
  z-index: -1;
  background: linear-gradient(180deg, var(--inactive-bg-color) 0%, var(--inactive-bg-color) 20%, var(--colors-theme-two) 40%, var(--colors-theme-two) 60%, var(--active-bg-color) 80%, var(--active-bg-color) 100%);
  width: 100%;
  height: 500%;
  transition: transform var(--transition-default) var(--transition-easing);
  position: absolute;
  top: 0;
  left: 0;
}

.column__content--dark .toggler .toggler__item, .interest-header .toggler .toggler__item, .toggler .toggler__item--back, .toggler .toggler__item--border, .toggler .toggler__item--inverted {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-bg-one);
}

.nav .column__content--dark .toggler .toggler__item, .nav .interest-header .toggler .toggler__item, .nav .toggler .toggler__item--back, .nav .toggler .toggler__item--border, .nav .toggler .toggler__item--inverted {
  --inactive-bg-color: var(--colors-bg-three);
}

.toggler .toggler__item--border {
  border: 1px solid var(--colors-theme-border);
}

.interest-header:not(.interest-header--dark) .toggler .toggler__item {
  --active-bg-color: var(--colors-bg-two);
}

.interest-header:not(.interest-header--dark) .toggler .toggler__item--active {
  --color: var(--colors-text-two) !important;
}

.interest-header--dark .toggler .toggler__item {
  --inactive-bg-color: var(--colors-glow);
  --color: var(--colors-text-two);
}

.interest-header--dark .toggler .toggler__item--active {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-theme-highlight);
}

.interest-header--dark .toggler .toggler__item--div {
  --inactive-bg-color: var(--colors-bg-two);
}

.buttons .toggler .toggler__item {
  margin: .5rem;
}

.buttons--div .toggler .toggler__item {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.toggler .toggler__item svg {
  transition: transform var(--transition-default);
  flex-shrink: 0;
  margin: -.5rem .75em -.5rem 0;
}

.toggler .toggler__item svg path {
  fill: var(--color);
  transition: fill var(--transition-default);
}

.toggler .toggler__item--icon-right svg {
  margin-left: .75em;
  margin-right: 0;
}

.toggler .toggler__item:has(input[type="radio"]:focus):not(.rate), .toggler .toggler__item:has(select:hover):not(.rate), .toggler .toggler__item:has(select:focus):not(.rate), .toggler .toggler__item.hover:not(.rate), body:not(.body--touch) .toggler .toggler__item:focus:not(.rate), body:not(.body--touch) .toggler .toggler__item:hover:not(.rate) {
  --color: var(--colors-text-one);
  background-color: var(--colors-theme-two);
  border-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.toggler .toggler__item:has(input[type="radio"]:focus):not(.rate):after, .toggler .toggler__item:has(select:hover):not(.rate):after, .toggler .toggler__item:has(select:focus):not(.rate):after, .toggler .toggler__item.hover:not(.rate):after, body:not(.body--touch) .toggler .toggler__item:focus:not(.rate):after, body:not(.body--touch) .toggler .toggler__item:hover:not(.rate):after {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.toggler .toggler__item:has(input[type="radio"]:focus):not(.rate):before, .toggler .toggler__item:has(select:hover):not(.rate):before, .toggler .toggler__item:has(select:focus):not(.rate):before, .toggler .toggler__item.hover:not(.rate):before, body:not(.body--touch) .toggler .toggler__item:focus:not(.rate):before, body:not(.body--touch) .toggler .toggler__item:hover:not(.rate):before {
  transition-duration: var(--transition-fast);
  transform: translateY(-40%);
}

.toggler .toggler__item:has(input[type="radio"]:focus):not(.rate) svg, .toggler .toggler__item:has(select:hover):not(.rate) svg, .toggler .toggler__item:has(select:focus):not(.rate) svg, .toggler .toggler__item.hover:not(.rate) svg, body:not(.body--touch) .toggler .toggler__item:focus:not(.rate) svg, body:not(.body--touch) .toggler .toggler__item:hover:not(.rate) svg {
  transition-duration: var(--transition-fast);
}

.toggler .toggler__item:has(input[type="radio"]:focus):not(.rate) svg path, .toggler .toggler__item:has(select:hover):not(.rate) svg path, .toggler .toggler__item:has(select:focus):not(.rate) svg path, .toggler .toggler__item.hover:not(.rate) svg path, body:not(.body--touch) .toggler .toggler__item:focus:not(.rate) svg path, body:not(.body--touch) .toggler .toggler__item:hover:not(.rate) svg path {
  fill: var(--colors-text-one);
  transition-duration: var(--transition-fast);
}

.toggler .toggler__item.hover {
  transition-duration: .1s;
}

@media (width <= 1200px) {
  .buttons--touch-scroll .toggler .toggler__item, .calculate .toggler .toggler__item, .interests .toggler .toggler__item, .interest-header .toggler .toggler__item {
    padding: .8em .65em;
  }

  .interests__gap .toggler .toggler__item {
    padding: 1.2em;
  }

  .interest-header--banks .toggler .toggler__item {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 1023px) {
  .calculate .toggler .toggler__item {
    padding: 1.2em;
  }

  .interest-header--banks .toggler .toggler__item {
    border-radius: 0;
    padding: 0;
  }
}

@media (width <= 599px) {
  .calculate .toggler .toggler__item {
    padding: .8em .65em;
  }

  .interest-header .toggler .toggler__item {
    --color: var(--colors-text-one);
    --inactive-bg-color: var(--colors-bg-three);
  }

  .interest-header--dark .toggler .toggler__item {
    --inactive-bg-color: var(--colors-bg-one);
  }

  .interest-header--dark .toggler .toggler__item--div {
    --inactive-bg-color: var(--colors-bg-three);
  }
}

.toggler .toggler__item.hover, body:not(.body--touch) .toggler .toggler__item:focus, body:not(.body--touch) .toggler .toggler__item:hover {
  --inactive-bg-color: transparent;
}

.toggler .toggler__item .toggler__item-span {
  margin-right: .3em;
}

.toggler .toggler__item input[type="radio"] {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}

.toggler--2 .toggler__bg {
  width: calc(50% - var(--toggler-gutter) / 2 + 1px);
}

.toggler--2 .toggler__item {
  width: calc(50% - var(--toggler-gutter) / 2);
}

.toggler--2:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) .toggler__bg {
  transform: translateX(calc(100% + var(--toggler-gutter)));
}

.toggler--3 .toggler__bg {
  width: calc(33.3333% - var(--toggler-gutter) / 3 + .6666px);
}

.toggler--3 .toggler__item {
  width: calc(33.3333% - var(--toggler-gutter) / 3);
  padding: 1.2em 0;
}

.toggler--3:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) .toggler__bg {
  transform: translateX(calc(100% + var(--toggler-gutter) * .5));
}

.toggler--3:has(.toggler__item[data-nr="2"] input[type="radio"]:checked) .toggler__bg {
  transform: translateX(calc(200% + var(--toggler-gutter) * 1));
}

@media (width <= 1400px) {
  .toggler .toggler__item {
    padding: 1.2em .4em;
  }

  .toggler--3 .toggler__item {
    flex-direction: column;
  }

  .toggler--3 .toggler__item svg {
    margin-bottom: .25em;
    margin-right: 0;
  }
}

@media (width <= 599px) {
  .toggler {
    --toggler-gutter: 1px;
  }

  .toggler .toggler__item {
    padding-left: 0;
    padding-right: 0;
  }

  .toggler--2 {
    justify-content: space-around;
  }

  .toggler--2 .toggler__bg {
    width: var(--toggler-item-width);
    transition-property: transform, width;
  }

  .toggler--2 .toggler__item {
    flex-grow: 1;
    width: auto;
  }

  .toggler--2:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) .toggler__bg {
    transform: translateX(calc(var(--toggler-item-width)  + 2px));
    width: calc(100% - var(--toggler-item-width));
  }

  .toggler--mobile-vertical {
    flex-direction: column;
  }

  .toggler--mobile-vertical .toggler__bg {
    width: calc(100% + 2px) !important;
  }

  .toggler--mobile-vertical .toggler__item {
    width: 100%;
    padding: .8em 0;
  }

  .toggler--mobile-vertical.toggler--2 .toggler__bg {
    height: calc(50% - var(--toggler-gutter) / 2 + 1px);
  }

  .toggler--mobile-vertical.toggler--2 .toggler__item {
    height: calc(50% - var(--toggler-gutter) / 2);
  }

  .toggler--mobile-vertical.toggler--2:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) .toggler__bg {
    transform: translateY(calc(100% + var(--toggler-gutter)));
  }

  .toggler--mobile-vertical.toggler--3 .toggler__bg {
    height: calc(33.3333% - var(--toggler-gutter) / 3 + .6666px);
  }

  .toggler--mobile-vertical.toggler--3 .toggler__item {
    height: calc(33.3333% - var(--toggler-gutter) / 3);
  }

  .toggler--mobile-vertical.toggler--3:has(.toggler__item[data-nr="1"] input[type="radio"]:checked) .toggler__bg {
    transform: translateY(calc(100% + var(--toggler-gutter) * .5));
  }

  .toggler--mobile-vertical.toggler--3:has(.toggler__item[data-nr="2"] input[type="radio"]:checked) .toggler__bg {
    transform: translateY(calc(200% + var(--toggler-gutter) * 1));
  }
}

@media (width <= 374px) {
  .toggler:not(.toggler--mobile-vertical) {
    --lineheight: 1.5;
    font-size: var(--font-size-small);
  }
}

.avatar {
  width: 6.2rem;
  height: 6.2rem;
  margin-bottom: var(--gutter-small);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.avatar .avatar__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 599px) {
  .avatar {
    display: none;
  }
}

.bank-logo {
  width: 13rem;
  height: 2.9em;
  margin-bottom: var(--gutter-small);
}

.bank-logo img {
  max-height: 2.9em;
}

.block1 {
  --logo-width: 9rem;
  width: 40rem;
  margin-right: var(--gutter);
  padding: var(--gutter-medium);
  background-color: var(--colors-bg-three);
  border-radius: var(--border-radius-large);
  align-items: center;
  display: flex;
}

.block1 .block1__logo {
  margin-right: var(--gutter-medium);
}

.block1 .block1__logo img {
  max-width: var(--logo-width);
  mix-blend-mode: multiply;
}

.block1 .block1__content {
  width: calc(100% - var(--logo-width)  - var(--gutter-medium));
  flex-direction: column;
  align-self: start;
  display: flex;
}

.block1 .block1__content .block1__heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: .3em;
  padding-bottom: .15em;
  overflow: hidden;
}

.block1 .block1__content .block1__rate {
  --lineheight: 1.15;
  font-weight: 700;
  font-size: var(--font-size-normal);
}

.block2 {
  --bg-color: var(--colors-bg-three);
  aspect-ratio: 4 / 3;
  width: 31rem;
  margin-right: var(--gutter);
  padding: var(--gutter-medium);
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.block2 .block2__heading {
  --color: var(--colors-text-one);
  background-image: linear-gradient(var(--color), var(--color));
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
  margin-bottom: .3em;
  padding-bottom: .15em;
  text-decoration: none;
  transition-property: background-size, opacity;
  display: inline;
}

.block2.hover .block2__heading, body:not(.body--touch) .block2:focus .block2__heading, body:not(.body--touch) .block2:hover .block2__heading {
  background-position: 0 var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

.block2.hover .button, body:not(.body--touch) .block2:focus .button, body:not(.body--touch) .block2:hover .button {
  background-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.block2.hover .button:after, body:not(.body--touch) .block2:focus .button:after, body:not(.body--touch) .block2:hover .button:after {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.3rem, -.3rem);
}

.block2.hover .button:before, body:not(.body--touch) .block2:focus .button:before, body:not(.body--touch) .block2:hover .button:before {
  transform: translateY(-40%);
}

.block3 {
  width: 100%;
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium);
  background-color: var(--colors-theme-four);
  border-radius: var(--border-radius-large);
  display: flex;
}

.block3 .block3__image {
  width: 28rem;
  margin-bottom: calc(var(--gutter-medium) * -1);
  padding-left: var(--gutter-medium);
  flex-wrap: wrap;
  flex-shrink: 0;
  align-self: flex-end;
  display: flex;
}

.block3 .block3__image img {
  width: 100%;
  height: auto;
}

.block3 .block3__texts {
  padding-left: var(--gutter-medium);
}

.block3 .block3__buttons {
  padding-left: var(--gutter-medium);
  flex-shrink: 0;
}

@media (width <= 1023px) {
  .block3 {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .block3 .block3__texts {
    width: 100%;
    padding-right: var(--gutter-medium);
    order: -1;
  }
}

@media (width <= 800px) {
  .block3 .block3__image {
    width: 23rem;
    padding-left: 1rem;
  }
}

@media (width <= 599px) {
  .block3 .block3__image {
    display: none;
  }

  .block3 .block3__texts {
    padding-left: 0;
  }

  .block3 .block3__buttons {
    width: 100%;
    padding-left: 0;
  }
}

.block4 {
  --bg-color: var(--colors-bg-three);
  margin-bottom: var(--padding);
  padding: var(--gutter-medium);
  background-color: var(--bg-color);
  border-radius: var(--gutter-medium);
  transition: background-color var(--transition-default);
  align-items: center;
  display: flex;
  max-width: calc((var(--max-content)  - var(--gutter) * 3) * .2777) !important;
}

.block4 .block4__image {
  max-width: 6rem;
  padding-right: var(--gutter-medium);
  transition: transform var(--transition-default);
  flex-shrink: 0;
}

.block4 .block4__text {
  background-image: linear-gradient(var(--color), var(--color));
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
  text-decoration: none;
  transition-property: background-size, opacity;
  display: inline;
}

.block4.hover, body:not(.body--touch) .block4:focus, body:not(.body--touch) .block4:hover {
  transition-duration: var(--transition-fast);
}

.block4.hover .block4__image, body:not(.body--touch) .block4:focus .block4__image, body:not(.body--touch) .block4:hover .block4__image {
  transition-duration: var(--transition-fast);
  transform: scale(1.1);
}

.block4.hover .block4__text, body:not(.body--touch) .block4:focus .block4__text, body:not(.body--touch) .block4:hover .block4__text {
  background-position: 0 var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

@media (width <= 800px) {
  .block4 {
    margin-bottom: var(--gutter-small);
    max-width: 100% !important;
  }
}

.block5 {
  --bg-color: var(--colors-bg-three);
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium);
  background-color: var(--bg-color);
  border-radius: var(--gutter-medium);
  align-items: center;
  display: flex;
}

.block5 .block5__texts {
  flex-grow: 1;
}

.block5 .block5__side {
  margin-left: var(--gutter-medium);
}

@media (width <= 800px) {
  .block5 {
    margin-bottom: var(--gutter-small);
  }
}

.block6 {
  z-index: 1;
  width: 100%;
  margin-bottom: var(--gutter-medium);
  border-radius: var(--gutter-medium);
  display: flex;
  position: relative;
  overflow: hidden;
}

.block6 .block6__texts {
  padding: calc(var(--gutter-medium) * 2) 0 calc(var(--gutter-medium) * 2) calc(var(--gutter-medium) * 1);
}

.block6 .block6__image {
  position: relative;
}

@media (width <= 599px) {
  .block6 .block6__image {
    display: none;
  }

  .block6 .block6__texts {
    padding: var(--gutter-medium);
  }

  .block6 .block6__buttons {
    width: 100%;
    padding-left: 0;
  }
}

.button {
  --color: var(--colors-text-two);
  --active-bg-color: var(--colors-theme-highlight);
  --inactive-bg-color: var(--colors-bg-two);
  --lineheight: 1.2;
  z-index: 0;
  color: var(--color);
  background-color: var(--inactive-bg-color);
  border-radius: var(--gutter-medium);
  transition: background-color, color, border-color, opacity;
  transition-duration: var(--transition-default);
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 1.2em;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  font-weight: 400 !important;
}

.button:before {
  content: "";
  z-index: -1;
  background: linear-gradient(180deg, var(--inactive-bg-color) 0%, var(--inactive-bg-color) 20%, var(--colors-theme-two) 40%, var(--colors-theme-two) 60%, var(--active-bg-color) 80%, var(--active-bg-color) 100%);
  width: 100%;
  height: 500%;
  transition: transform var(--transition-default) var(--transition-easing);
  position: absolute;
  top: 0;
  left: 0;
}

.button--div {
  pointer-events: none;
}

.button--small {
  padding: .6em 1em;
}

.button--margin {
  margin-bottom: var(--gutter-small);
}

.button--round:after, .button--circle:after, .button--corner:after {
  pointer-events: none;
  content: "";
  border-top: 3px solid var(--color);
  border-right: 3px solid var(--color);
  width: 1.4rem;
  height: 1.4rem;
  transition: border-color, transform;
  transition-duration: var(--transition-default);
  margin-left: -.6rem;
  display: flex;
  transform: rotate(45deg);
}

.button--round.hover:after, body:not(.body--touch) .button--round:focus:after, body:not(.body--touch) .button--round:hover:after, .button--circle.hover:after, body:not(.body--touch) .button--circle:focus:after, body:not(.body--touch) .button--circle:hover:after, .button--corner.hover:after, body:not(.body--touch) .button--corner:focus:after, body:not(.body--touch) .button--corner:hover:after {
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.button--back, .button--round, .button--circle {
  border-radius: 10rem;
  justify-content: center;
  align-items: center;
  width: 5.6rem;
  height: 5.6rem;
  padding: 0;
  display: flex;
}

.button--back svg, .button--round svg, .button--circle svg {
  margin-right: 0 !important;
}

.button--round {
  --inactive-bg-color: var(--colors-theme-five);
  --color: var(--colors-text-one);
}

.button--round:after {
  content: none;
}

.button--corner {
  aspect-ratio: 1;
}

.button--corner:after {
  border-width: 2px;
  width: 1rem;
  height: 1rem;
  margin-left: -.4rem;
}

.splash-block .button {
  margin-top: var(--gutter-medium);
}

.buttons-min-width .button {
  min-width: var(--rente-btn-width);
  padding-left: 0;
  padding-right: 0;
}

.buttons--grow .button {
  flex-grow: 1;
  padding-left: 0;
  padding-right: 0;
}

.results .button {
  margin-bottom: var(--gutter-block);
}

.column__content--dark .button, .interest-header .button, .button--back, .button--border, .button--inverted {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-bg-one);
}

.nav .column__content--dark .button, .nav .interest-header .button, .nav .button--back, .nav .button--border, .nav .button--inverted {
  --inactive-bg-color: var(--colors-bg-three);
}

.button--border {
  border: 1px solid var(--colors-theme-border);
}

.interest-header:not(.interest-header--dark) .button {
  --active-bg-color: var(--colors-bg-two);
}

.interest-header:not(.interest-header--dark) .button--active {
  --color: var(--colors-text-two) !important;
}

.interest-header--dark .button {
  --inactive-bg-color: var(--colors-glow);
  --color: var(--colors-text-two);
}

.interest-header--dark .button--active {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-theme-highlight);
}

.interest-header--dark .button--div {
  --inactive-bg-color: var(--colors-bg-two);
}

.buttons .button {
  margin: .5rem;
}

.buttons--div .button {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.button svg {
  transition: transform var(--transition-default);
  flex-shrink: 0;
  margin: -.5rem .75em -.5rem 0;
}

.button svg path {
  fill: var(--color);
  transition: fill var(--transition-default);
}

.button--icon-right svg {
  margin-left: .75em;
  margin-right: 0;
}

.button:has(input[type="radio"]:focus):not(.rate), .button:has(select:hover):not(.rate), .button:has(select:focus):not(.rate), .button.hover:not(.rate), body:not(.body--touch) .button:focus:not(.rate), body:not(.body--touch) .button:hover:not(.rate) {
  --color: var(--colors-text-one);
  background-color: var(--colors-theme-two);
  border-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.button:has(input[type="radio"]:focus):not(.rate):after, .button:has(select:hover):not(.rate):after, .button:has(select:focus):not(.rate):after, .button.hover:not(.rate):after, body:not(.body--touch) .button:focus:not(.rate):after, body:not(.body--touch) .button:hover:not(.rate):after {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.button:has(input[type="radio"]:focus):not(.rate):before, .button:has(select:hover):not(.rate):before, .button:has(select:focus):not(.rate):before, .button.hover:not(.rate):before, body:not(.body--touch) .button:focus:not(.rate):before, body:not(.body--touch) .button:hover:not(.rate):before {
  transition-duration: var(--transition-fast);
  transform: translateY(-40%);
}

.button:has(input[type="radio"]:focus):not(.rate) svg, .button:has(select:hover):not(.rate) svg, .button:has(select:focus):not(.rate) svg, .button.hover:not(.rate) svg, body:not(.body--touch) .button:focus:not(.rate) svg, body:not(.body--touch) .button:hover:not(.rate) svg {
  transition-duration: var(--transition-fast);
}

.button:has(input[type="radio"]:focus):not(.rate) svg path, .button:has(select:hover):not(.rate) svg path, .button:has(select:focus):not(.rate) svg path, .button.hover:not(.rate) svg path, body:not(.body--touch) .button:focus:not(.rate) svg path, body:not(.body--touch) .button:hover:not(.rate) svg path {
  fill: var(--colors-text-one);
  transition-duration: var(--transition-fast);
}

.button.hover {
  transition-duration: .1s;
}

@media (width <= 1200px) {
  .buttons--touch-scroll .button, .calculate .button, .interests .button, .interest-header .button {
    padding: .8em .65em;
  }

  .interests__gap .button {
    padding: 1.2em;
  }

  .interest-header--banks .button {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 1023px) {
  .calculate .button {
    padding: 1.2em;
  }

  .interest-header--banks .button {
    border-radius: 0;
    padding: 0;
  }
}

@media (width <= 599px) {
  .calculate .button {
    padding: .8em .65em;
  }

  .interest-header .button {
    --color: var(--colors-text-one);
    --inactive-bg-color: var(--colors-bg-three);
  }

  .interest-header--dark .button {
    --inactive-bg-color: var(--colors-bg-one);
  }

  .interest-header--dark .button--div {
    --inactive-bg-color: var(--colors-bg-three);
  }
}

.interests .button, .block5 .button {
  margin-bottom: 0;
}

.results .buttons .button {
  margin-bottom: .5rem;
}

.button input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.button:has(input[type="radio"]:focus) {
  background-color: var(--colors-theme-two);
  border-color: var(--colors-theme-two);
}

.button:has(input[type="radio"]:focus):before {
  transform: translateY(-40%);
}

.button--big {
  line-height: var(--lineheight);
  white-space: normal;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem !important;
}

.button--big svg {
  height: 3.4rem;
  margin-top: 0;
  margin-bottom: .75em;
  margin-right: 0;
}

.button--back.hover svg, body:not(.body--touch) .button--back:focus svg, body:not(.body--touch) .button--back:hover svg {
  transform: translateX(-.5rem);
}

.button--arrow {
  align-self: flex-start;
}

.button--arrow svg {
  margin-left: .75em;
  margin-right: 0;
  transform: rotate(180deg);
}

.button--arrow.hover svg, body:not(.body--touch) .button--arrow:focus svg, body:not(.body--touch) .button--arrow:hover svg {
  transform: rotate(180deg)translateX(-.5rem);
}

.button:has(input[type="radio"]:checked), .button--active {
  background-color: var(--active-bg-color);
}

.button:has(input[type="radio"]:checked):before, .button--active:before {
  transform: translateY(calc(1px - 80%)) !important;
}

.button:has(input[type="radio"]:checked).button--border, .button--active.button--border {
  border-color: var(--active-bg-color) !important;
}

.button .button__span {
  margin-right: .3em;
}

@media (width <= 1023px) {
  .buttons--land .button svg {
    display: none;
  }
}

@media (width <= 800px) {
  .buttons--land .button svg {
    display: block;
  }
}

@media (width <= 599px) {
  .button--mobile-border {
    border: 1px solid var(--colors-theme-border);
  }

  .button--margin {
    width: 100%;
    padding: 1.2em !important;
  }
}

.checkbox {
  --lineheight: 1.2;
  cursor: pointer;
  margin-bottom: var(--gutter-small);
  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);
  transition: background-color var(--transition-default);
  padding: 1.2em 1.2em 0;
  position: relative;
}

.buttons .checkbox {
  margin: .5rem;
}

.checkbox .checkbox__header {
  align-items: center;
  width: 100%;
  margin-bottom: 1.2em;
  display: flex;
}

.checkbox .checkbox__header input[type="checkbox"] {
  opacity: 0;
  margin-right: 3rem;
}

.checkbox .checkbox__header .checkbox__text {
  text-align: left;
  flex-grow: 1;
}

.checkbox .checkbox__header .checkbox__box {
  border: 1px solid var(--colors-theme-border);
  border-radius: .6rem;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: .75em;
  padding: 2px;
  display: flex;
  position: absolute;
}

.checkbox .checkbox__header .checkbox__box:after {
  content: "";
  width: 100%;
  height: 100%;
  transition: background-color var(--transition-default);
  border-radius: .4rem;
}

.checkbox .checkbox__header:has(input[type="checkbox"]:focus) .checkbox__box:after, .checkbox .checkbox__header.hover .checkbox__box:after, body:not(.body--touch) .checkbox .checkbox__header:focus .checkbox__box:after, body:not(.body--touch) .checkbox .checkbox__header:hover .checkbox__box:after {
  background-color: var(--colors-theme-three);
}

.checkbox .checkbox__header:has(input[type="checkbox"]:checked) .checkbox__box:after {
  background-color: var(--colors-theme-one) !important;
}

.checkbox .checkbox__desc {
  line-height: var(--lineheight);
  margin-bottom: 1em;
}

.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.crumbs {
  line-height: var(--lineheight);
  margin-bottom: 3em;
}

.crumbs .crumbs__link, .crumbs .crumbs__separator, .crumbs .crumbs__current {
  margin-right: .5em;
}

.crumbs .crumbs__link {
  color: var(--colors-text-transparent);
  text-decoration: none;
  transition: color .2s;
  display: inline;
}

.crumbs .crumbs__link.hover, body:not(.body--touch) .crumbs .crumbs__link:focus, body:not(.body--touch) .crumbs .crumbs__link:hover {
  text-underline-offset: .2rem;
  text-decoration: underline;
}

.crumbs .crumbs__separator {
  display: inline-block;
}

.crumbs .crumbs__separator:after {
  pointer-events: none;
  content: "";
  border-top: 3px solid var(--color);
  border-right: 3px solid var(--color);
  width: 1.4rem;
  height: 1.4rem;
  transition: border-color, transform;
  transition-duration: var(--transition-default);
  margin-left: -.6rem;
  display: flex;
  transform: rotate(45deg);
}

.crumbs .crumbs__separator.hover:after, body:not(.body--touch) .crumbs .crumbs__separator:focus:after, body:not(.body--touch) .crumbs .crumbs__separator:hover:after {
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.crumbs .crumbs__separator:after {
  border-color: var(--colors-text-transparent);
  border-width: 2px;
  width: 1rem;
  height: 1rem;
  margin-left: -.4rem;
}

.crumbs .crumbs__current {
  color: var(--colors-text-transparent);
  display: inline;
}

.error {
  box-shadow: 0 0 1rem var(--colors-theme-error-glow);
  border-color: var(--colors-theme-error) !important;
}

.excerpt {
  z-index: 0;
  aspect-ratio: 5 / 6;
  max-width: calc((var(--max-content)  - var(--gutter) * 3) * .3333);
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-large) var(--gutter-medium) 0;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.excerpt .excerpt__gradient {
  border-radius: var(--border-radius-large);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.excerpt .excerpt__heading, .excerpt .excerpt__preface, .excerpt .excerpt__text {
  text-align: center;
  margin-bottom: .5em;
}

.excerpt .excerpt__heading {
  text-align: center;
  display: inline;
}

.excerpt .excerpt__preface {
  margin-top: auto;
}

.excerpt .excerpt__text {
  margin-bottom: auto;
}

.excerpt .excerpt__img {
  will-change: transform;
  transform-origin: bottom;
  width: 80%;
  transition: transform var(--transition-default);
  margin-top: .5em;
}

.excerpt.hover .excerpt__img, body:not(.body--touch) .excerpt:focus .excerpt__img, body:not(.body--touch) .excerpt:hover .excerpt__img {
  transition-duration: var(--transition-fast);
  transform: scale(1.03);
}

.field-caption {
  margin-top: calc(var(--gutter-block) * -1 + .5em);
  margin-bottom: var(--gutter-block);
  flex-direction: column;
  padding-left: 1.2em;
  display: flex;
}

.field-caption .field-caption__title {
  margin-bottom: .2em;
}

.field {
  margin-bottom: var(--gutter-block);
  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);
  align-items: center;
  padding: 1.2em;
  display: flex;
}

.checkbox .field {
  border-radius: 1.2rem;
  margin-bottom: 1.2em;
  padding-top: .6em;
  padding-bottom: .6em;
}

.field .field__currency {
  --lineheight: 1.15;
  font-size: var(--font-size-normal);
  --underline-width: 1px;
  --underline-top: calc(50% + .55em);
  --shadow-width: .08em;
  font-variation-settings: "wdth" 85;
  margin-right: var(--gutter-small);
  font-weight: 700;
}

.field .field__input {
  flex-grow: 1;
}

.full-touch {
  height: 100vh;
  position: fixed;
}

.gradient {
  z-index: -1;
  background: radial-gradient(var(--colors-theme-two) 0%, var(--colors-theme-three) 70.7%);
  width: 200%;
  height: 200%;
  transition: opacity var(--transition-slow);
  position: absolute;
  top: -50%;
  left: 0;
}

.gradient--secondary {
  background: radial-gradient(var(--colors-theme-one) 0%, var(--colors-theme-three) 70.7%);
}

.background .gradient {
  z-index: 1;
  opacity: 0;
}

.body[data-pagetype="page"] .background .gradient, .body[data-pagetype="news"] .background .gradient, .body[data-pagetype="knowledge"] .background .gradient, .body[data-pagetype="contact"] .background .gradient {
  opacity: 1;
}

.hamburger {
  --line-width: 2px;
  pointer-events: auto;
  z-index: 2;
  width: 3rem;
  height: 18px;
  margin-bottom: var(--line-width);
  margin-left: var(--gutter-small);
  display: none;
  position: relative;
}

.hamburger .hamburger__line {
  width: 100%;
  height: var(--line-width);
  background-color: var(--colors-text-one);
  transition: transform, background-color;
  transition-timing-function: var(--transition-easing), linear;
  transition-duration: var(--transition-fast), var(--transition-fast);
  position: absolute;
}

.hamburger .hamburger__line:first-of-type {
  transform: translateY(-.7rem);
}

.hamburger .hamburger__line:nth-of-type(3) {
  transform: translateY(.7rem);
}

.hamburger--active, body:not(.body--touch) .hamburger:focus, body:not(.body--touch) .hamburger:hover, .hamburger.hover {
  transition-duration: var(--transition-fast);
}

.hamburger--active .hamburger__line, body:not(.body--touch) .hamburger:focus .hamburger__line, body:not(.body--touch) .hamburger:hover .hamburger__line, .hamburger.hover .hamburger__line {
  background-color: var(--colors-text-one);
}

.hamburger.hover {
  transition-duration: .1s;
}

.body--nav .hamburger .hamburger__line, .body--nav-black .hamburger .hamburger__line, .hamburger--dark .hamburger__line {
  background-color: var(--colors-text-two);
}

.body--nav .hamburger--active .hamburger__line, body:not(.body--touch) .body--nav .hamburger:focus .hamburger__line, body:not(.body--touch) .body--nav .hamburger:hover .hamburger__line, .body--nav .hamburger.hover .hamburger__line, .body--nav-black .hamburger--active .hamburger__line, body:not(.body--touch) .body--nav-black .hamburger:focus .hamburger__line, body:not(.body--touch) .body--nav-black .hamburger:hover .hamburger__line, .body--nav-black .hamburger.hover .hamburger__line, .hamburger--dark--active .hamburger__line, body:not(.body--touch) .hamburger--dark:focus .hamburger__line, body:not(.body--touch) .hamburger--dark:hover .hamburger__line, .hamburger--dark.hover .hamburger__line {
  background-color: var(--colors-text-one);
}

.body--nav .hamburger .hamburger__line {
  background-color: var(--colors-text-two);
}

.body--nav .hamburger .hamburger__line:first-of-type {
  transform: rotate(45deg)scaleX(1.1);
}

.body--nav .hamburger .hamburger__line:nth-of-type(2) {
  transform: scaleX(0);
}

.body--nav .hamburger .hamburger__line:nth-of-type(3) {
  transform: rotate(-45deg)scaleX(1.1);
}

@media (width <= 800px) {
  .hamburger {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

h1.heading:has(.heading__bold, .heading__regular):has(.fader):not(:has(.fader2)), h2.heading:has(.heading__bold, .heading__regular):has(.fader):not(:has(.fader2)) {
  opacity: 0;
}

.heading {
  width: 100%;
  margin-bottom: .5em;
}

.heading--cb {
  margin-bottom: .1em;
}

.heading--center {
  text-align: center;
}

.heading--bold {
  font-weight: 940;
}

.heading--condensed {
  --underline-width: 1px;
  --underline-top: calc(50% + .55em);
  --shadow-width: .08em;
  font-variation-settings: "wdth" 110;
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 85;
  font-family: Encode Sans, serif;
  font-style: normal;
  font-weight: 700;
}

.heading:has(svg) {
  align-items: center;
  display: flex;
}

.results__item .heading {
  width: auto;
  margin-bottom: calc(var(--gutter-medium)  - .2em);
}

.results__item-flex .heading {
  margin-bottom: 0;
  margin-right: .5em;
}

.heading svg {
  flex-shrink: 0;
  margin-right: .65em;
}

.heading svg path {
  fill: var(--color);
}

.heading .heading__regular-no-fader, .heading .heading__regular {
  font-weight: 400;
}

.heading .heading__bold-no-fader, .heading .heading__bold {
  font-weight: 940;
}

.highlight {
  opacity: 0;
  transition: opacity, transform;
  transition-duration: var(--transition-default);
  display: block;
  transform: scale(.85);
}

.swiper-slide-prev .highlight, .swiper-slide-next .highlight {
  opacity: 1;
}

.highlight .highlight__content {
  --bg-color: var(--colors-bg-three);
  aspect-ratio: 1;
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
  display: flex;
  position: relative;
}

.highlight .highlight__content .highlight__gradient {
  z-index: -1;
  opacity: 0;
  border-radius: var(--border-radius-large);
  width: 100%;
  height: 100%;
  transition: opacity var(--transition-default);
  position: absolute;
  left: 0;
  overflow: hidden;
}

.highlight .highlight__content .highlight__image {
  width: 42%;
  margin-bottom: var(--gutter-large);
  justify-content: center;
  align-items: center;
  display: flex;
}

.highlight .highlight__content .highlight__image .highlight__image-star {
  aspect-ratio: 1;
  clip-path: polygon(98.49% 62.18%, 84.95% 69.45%, 85.91% 84.8%, 70.54% 84.32%, 63.7% 98.09%, 50.63% 90%, 37.82% 98.49%, 30.55% 84.95%, 15.2% 85.91%, 15.68% 70.54%, 1.91% 63.7%, 10% 50.63%, 1.51% 37.82%, 15.05% 30.55%, 14.09% 15.2%, 29.46% 15.68%, 36.3% 1.91%, 49.37% 10%, 62.18% 1.51%, 69.45% 15.05%, 84.8% 14.09%, 84.32% 29.46%, 98.09% 36.3%, 90% 49.37%);
  width: 150%;
  transition: transform var(--transition-slow) var(--transition-easing);
  position: absolute;
  overflow: hidden;
  transform: scale(0)rotate(180deg);
}

.highlight .highlight__content .highlight__image img {
  aspect-ratio: 5 / 6;
  object-fit: contain;
  width: 100%;
  position: relative;
}

.highlight .highlight__content .highlight__image--circle {
  aspect-ratio: 1;
  border: 1px solid var(--colors-theme-border);
  border-radius: 50%;
  width: 45%;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
}

.highlight .highlight__content .highlight__image--link img {
  will-change: transform;
  transition: transform var(--transition-default);
}

.highlight .highlight__content .highlight__image--link.hover, body:not(.body--touch) .highlight .highlight__content .highlight__image--link:focus, body:not(.body--touch) .highlight .highlight__content .highlight__image--link:hover {
  transition-duration: var(--transition-fast);
}

.highlight .highlight__content .highlight__image--link.hover img, body:not(.body--touch) .highlight .highlight__content .highlight__image--link:focus img, body:not(.body--touch) .highlight .highlight__content .highlight__image--link:hover img {
  transition-duration: var(--transition-fast);
  transform: scale(1.1);
}

.highlight .highlight__content .highlight__heading {
  text-align: center;
  background-image: linear-gradient(var(--color), var(--color));
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 50% var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
  margin-bottom: .5em;
  text-decoration: none;
  transition-property: background-size, opacity;
  position: relative;
}

.highlight .highlight__content .highlight__heading--link {
  display: inline;
}

.highlight .highlight__content .highlight__heading--link.hover, body:not(.body--touch) .highlight .highlight__content .highlight__heading--link:focus, body:not(.body--touch) .highlight .highlight__content .highlight__heading--link:hover {
  background-position: 50% var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

.highlight .highlight__content .highlight__hider {
  opacity: 0;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 0;
  transition: max-height .4s, opacity .2s;
  display: flex;
  overflow: hidden;
}

.highlight .highlight__content .highlight__hider .highlight__text {
  text-align: center;
  text-wrap: balance;
  width: 100%;
  opacity: var(--text-opacity);
}

.highlight .highlight__content--no-padding {
  justify-content: space-evenly;
  padding: 5% 0;
}

.highlight .highlight__content--no-padding .highlight__image, .highlight .highlight__content--no-padding .highlight__heading {
  margin-bottom: 0;
}

.highlight .highlight__content--no-padding .highlight__hider .highlight__text {
  margin-bottom: 1em;
}

.highlight .highlight__content--link .highlight__image {
  will-change: transform;
  transition: transform var(--transition-default);
}

.highlight .highlight__content--link.hover, body:not(.body--touch) .highlight .highlight__content--link:focus, body:not(.body--touch) .highlight .highlight__content--link:hover {
  transition-duration: var(--transition-fast);
}

.highlight .highlight__content--link.hover .highlight__image, body:not(.body--touch) .highlight .highlight__content--link:focus .highlight__image, body:not(.body--touch) .highlight .highlight__content--link:hover .highlight__image {
  transition-duration: var(--transition-fast);
  transform: scale(1.05);
}

.highlight .highlight__content--link.hover .highlight__heading, body:not(.body--touch) .highlight .highlight__content--link:focus .highlight__heading, body:not(.body--touch) .highlight .highlight__content--link:hover .highlight__heading {
  background-position: 50% var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

.highlight .highlight__content--review {
  z-index: 0;
  align-items: flex-start;
  position: relative;
}

.highlight .highlight__content--review .highlight__header {
  margin-bottom: var(--padding);
  align-items: baseline;
  display: flex;
}

.highlight .highlight__content--review .highlight__header .highlight__header-initials {
  --lineheight: 1.15;
  font-size: var(--font-size-normal);
  --underline-width: 1px;
  --underline-top: calc(50% + .55em);
  --shadow-width: .08em;
  font-variation-settings: "wdth" 85;
  pointer-events: none;
  color: var(--colors-text-one);
  background-color: #0000;
  margin-right: 1em;
  font-weight: 700;
  font-weight: 700 !important;
}

.highlight .highlight__content--review .highlight__header .highlight__header-initials:before, .highlight .highlight__content--review .highlight__header .highlight__header-initials:after {
  content: none;
}

.highlight .highlight__content--review .highlight__header .highlight__header-initials .highlight__header-gradient {
  z-index: -1;
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: opacity var(--transition-default);
  position: absolute;
}

.highlight .highlight__content--review .highlight__heading {
  text-align: left;
  text-shadow: none;
  background-image: none;
}

.highlight .highlight__content--review .highlight__text {
  text-align: left;
  opacity: 1;
  margin-bottom: auto;
}

.highlight .highlight__content--review .highlight__link {
  text-underline-offset: .2rem;
  margin-top: .8em;
  text-decoration: underline;
}

body:not(.body--touch) .highlight .highlight__content--review .highlight__link:focus, body:not(.body--touch) .highlight .highlight__content--review .highlight__link:hover, .highlight .highlight__content--review .highlight__link.hover {
  text-decoration: none;
}

.highlight .highlight__content--review .highlight__link svg {
  width: 1rem;
  height: 1rem;
  margin-left: .5em;
}

.highlight .highlight__content--review .highlight__link svg path {
  fill: var(--colors-text-one);
}

.swiper-slide-active .highlight {
  opacity: 1;
  transform: scale(1);
}

.swiper-slide-active .highlight .highlight__content .highlight__gradient {
  opacity: 1;
}

.swiper-slide-active .highlight .highlight__content .highlight__image .highlight__image-star {
  transition: transform var(--transition-slow) var(--transition-easing-out);
  transform: scale(1)rotate(0);
}

.swiper-slide-active .highlight .highlight__content .highlight__hider {
  max-height: calc(var(--scroll-height)  + 1px);
  opacity: 1;
  transition: max-height .4s, opacity .2s .2s;
}

.swiper-slide-active .highlight .highlight__content--review .highlight__header .highlight__header-initials {
  color: var(--colors-text-two);
  background-color: var(--colors-text-one);
}

.swiper-slide-active .highlight .highlight__content--review .highlight__header .highlight__header-initials .highlight__header-gradient {
  opacity: 0;
}

@media (width <= 1200px) {
  .swiper-slide-prev .highlight {
    opacity: 0;
  }

  .swiper-slide-next .highlight {
    opacity: 1;
    transform: scale(1);
  }

  .swiper-slide-next .highlight .highlight__content .highlight__gradient {
    opacity: 1;
  }

  .swiper-slide-next .highlight .highlight__content .highlight__image .highlight__image-star {
    transition: transform var(--transition-slow) var(--transition-easing-out);
    transform: scale(1)rotate(0);
  }

  .swiper-slide-next .highlight .highlight__content .highlight__hider {
    max-height: calc(var(--scroll-height)  + 1px);
    opacity: 1;
    transition: max-height .4s, opacity .2s .2s;
  }

  .swiper-slide-next .highlight .highlight__content--review .highlight__header .highlight__header-initials {
    color: var(--colors-text-two);
    background-color: var(--colors-text-one);
  }

  .swiper-slide-next .highlight .highlight__content--review .highlight__header .highlight__header-initials .highlight__header-gradient {
    opacity: 0;
  }
}

@media (width <= 800px) {
  .highlight .highlight__content--link {
    aspect-ratio: 1 / 1.2;
  }

  .swiper-slide-prev .highlight, .swiper-slide-next .highlight {
    opacity: .5;
    transform: scale(.85);
  }

  .swiper-slide-prev .highlight .highlight__content .highlight__gradient, .swiper-slide-next .highlight .highlight__content .highlight__gradient {
    opacity: 0;
  }

  .swiper-slide-prev .highlight .highlight__content .highlight__header .highlight__header-initials, .swiper-slide-next .highlight .highlight__content .highlight__header .highlight__header-initials {
    color: var(--colors-text-one);
    background-color: #0000;
  }

  .swiper-slide-prev .highlight .highlight__content .highlight__header .highlight__header-initials .highlight__header-gradient, .swiper-slide-next .highlight .highlight__content .highlight__header .highlight__header-initials .highlight__header-gradient {
    opacity: 1;
  }

  .swiper-slide-prev .highlight .highlight__content .highlight__image .highlight__image-star, .swiper-slide-next .highlight .highlight__content .highlight__image .highlight__image-star {
    transform: scale(0)rotate(180deg);
  }

  .swiper-slide-prev .highlight .highlight__content .highlight__hider, .swiper-slide-next .highlight .highlight__content .highlight__hider {
    opacity: 0;
    max-height: 0;
    transition: max-height .4s, opacity .2s;
  }
}

@media (width <= 599px) {
  .swiper-slide-prev .highlight, .swiper-slide-next .highlight {
    opacity: 1;
  }
}

.holder {
  max-width: 100%;
}

.image {
  margin-bottom: var(--padding);
  flex-direction: column;
  display: flex;
  position: relative;
}

.image img {
  width: 100%;
}

.image--rounded img {
  border-radius: var(--gutter-large);
}

.image--broker {
  margin-bottom: 0;
}

.image--icon {
  width: 10rem;
}

.image .image__caption {
  --lineheight: 1.5;
  font-size: var(--font-size-small);
  margin-top: 1rem;
}

@media (width <= 599px) {
  .image--broker {
    margin-left: 20%;
    margin-right: -20%;
  }
}

.info {
  align-items: flex-start;
  margin: -.5rem 0;
  display: flex;
  position: relative;
}

.interest-header--dark .info svg path {
  fill: var(--colors-text-two);
}

.info .info__content {
  --lineheight: 1.5;
  font-size: var(--font-size-small);
  --bg-color: var(--colors-bg-two);
  --color: var(--colors-text-two);
  pointer-events: none;
  z-index: 2;
  width: 52rem;
  max-width: calc(var(--to-window-side)  - var(--gutter) * .5);
  padding: var(--gutter-between);
  color: var(--color);
  opacity: 0;
  background-color: var(--bg-color);
  border-radius: var(--gutter-small);
  box-shadow: -1px 0 16px var(--colors-glow2);
  transition: opacity var(--transition-default) ease, transform var(--transition-default) var(--transition-easing-in);
  position: absolute;
  top: -1.7rem;
  left: calc(100% + 1.5rem);
  transform: translateY(1rem);
}

.info .info__content:before {
  content: "";
  border-top: 1rem solid #0000;
  border-right: 1rem solid var(--bg-color);
  border-bottom: 1rem solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 1.7rem;
  left: -1rem;
}

.info .info__content--left {
  box-shadow: 1px 0 16px var(--colors-glow2);
  left: auto;
  right: calc(100% + 2rem);
}

.info .info__content--left:before {
  border-right: 0;
  border-left: 1rem solid var(--bg-color);
  left: auto;
  right: -1rem;
}

.info:focus .info__content, .info:hover .info__content {
  opacity: 1;
  transition-timing-function: ease, var(--transition-easing-out);
  transform: none;
}

@media (width <= 599px) {
  .info {
    width: 1.5rem;
  }

  .info svg {
    width: 100%;
    height: auto;
  }

  .interest-header--dark .info svg path {
    fill: var(--colors-text-one);
  }
}

.intro {
  --underline-width: 1px;
  --underline-top: calc(50% + .57em);
  --shadow-width: .075em;
  font-variation-settings: "wdth" 110;
  font-optical-sizing: auto;
  --lineheight: 1.5;
  font-family: Encode Sans, serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-intro);
  margin-bottom: calc(var(--lineheight) * 1em);
  color: var(--colors-text-transparent);
  text-align: center;
  text-wrap: balance;
}

.intro--left {
  text-align: left;
  align-self: flex-start;
}

.intro--opaque {
  color: var(--colors-text-one);
}

.column__content--bg-blue .intro, .splash .intro {
  margin-bottom: calc(var(--lineheight) * 1.5em);
}

.results__item .intro {
  margin-bottom: var(--gutter-medium);
  text-align: left;
}

.results__item--dark .intro {
  color: var(--colors-theme-highlight);
}

.splash__cta .intro {
  --lineheight: 1.5;
  font-size: var(--font-size-book);
}

@media (width <= 1200px) {
  .splash__cta .intro {
    margin-bottom: var(--gutter-small);
  }
}

@media (width <= 1023px) {
  .splash__cta .intro {
    margin-bottom: calc(var(--lineheight) * 1em);
  }
}

@media (width <= 800px) {
  .intro {
    color: var(--colors-text-one);
  }
}

.label {
  --color: var(--colors-text-two);
  --active-bg-color: var(--colors-theme-highlight);
  --inactive-bg-color: var(--colors-bg-two);
  --lineheight: 1.2;
  z-index: 0;
  color: var(--color);
  background-color: var(--inactive-bg-color);
  border-radius: var(--gutter-medium);
  transition: background-color, color, border-color, opacity;
  transition-duration: var(--transition-default);
  justify-content: center;
  align-items: center;
  padding: 1.2em;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.label:before {
  content: "";
  z-index: -1;
  background: linear-gradient(180deg, var(--inactive-bg-color) 0%, var(--inactive-bg-color) 20%, var(--colors-theme-two) 40%, var(--colors-theme-two) 60%, var(--active-bg-color) 80%, var(--active-bg-color) 100%);
  width: 100%;
  height: 500%;
  transition: transform var(--transition-default) var(--transition-easing);
  position: absolute;
  top: 0;
  left: 0;
}

.column__content--dark .label, .interest-header .label, .label--back, .label--border, .label--inverted {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-bg-one);
}

.nav .column__content--dark .label, .nav .interest-header .label, .nav .label--back, .nav .label--border, .nav .label--inverted {
  --inactive-bg-color: var(--colors-bg-three);
}

.label--border {
  border: 1px solid var(--colors-theme-border);
}

.interest-header:not(.interest-header--dark) .label {
  --active-bg-color: var(--colors-bg-two);
}

.interest-header:not(.interest-header--dark) .label--active {
  --color: var(--colors-text-two) !important;
}

.interest-header--dark .label {
  --inactive-bg-color: var(--colors-glow);
  --color: var(--colors-text-two);
}

.interest-header--dark .label--active {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-theme-highlight);
}

.interest-header--dark .label--div {
  --inactive-bg-color: var(--colors-bg-two);
}

.buttons .label {
  margin: .5rem;
}

.buttons--div .label {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.label svg {
  transition: transform var(--transition-default);
  flex-shrink: 0;
  margin: -.5rem .75em -.5rem 0;
}

.label svg path {
  fill: var(--color);
  transition: fill var(--transition-default);
}

.label--icon-right svg {
  margin-left: .75em;
  margin-right: 0;
}

.label:has(input[type="radio"]:focus):not(.rate), .label:has(select:hover):not(.rate), .label:has(select:focus):not(.rate), .label.hover:not(.rate), body:not(.body--touch) .label:focus:not(.rate), body:not(.body--touch) .label:hover:not(.rate) {
  --color: var(--colors-text-one);
  background-color: var(--colors-theme-two);
  border-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.label:has(input[type="radio"]:focus):not(.rate):after, .label:has(select:hover):not(.rate):after, .label:has(select:focus):not(.rate):after, .label.hover:not(.rate):after, body:not(.body--touch) .label:focus:not(.rate):after, body:not(.body--touch) .label:hover:not(.rate):after {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.label:has(input[type="radio"]:focus):not(.rate):before, .label:has(select:hover):not(.rate):before, .label:has(select:focus):not(.rate):before, .label.hover:not(.rate):before, body:not(.body--touch) .label:focus:not(.rate):before, body:not(.body--touch) .label:hover:not(.rate):before {
  transition-duration: var(--transition-fast);
  transform: translateY(-40%);
}

.label:has(input[type="radio"]:focus):not(.rate) svg, .label:has(select:hover):not(.rate) svg, .label:has(select:focus):not(.rate) svg, .label.hover:not(.rate) svg, body:not(.body--touch) .label:focus:not(.rate) svg, body:not(.body--touch) .label:hover:not(.rate) svg {
  transition-duration: var(--transition-fast);
}

.label:has(input[type="radio"]:focus):not(.rate) svg path, .label:has(select:hover):not(.rate) svg path, .label:has(select:focus):not(.rate) svg path, .label.hover:not(.rate) svg path, body:not(.body--touch) .label:focus:not(.rate) svg path, body:not(.body--touch) .label:hover:not(.rate) svg path {
  fill: var(--colors-text-one);
  transition-duration: var(--transition-fast);
}

.label.hover {
  transition-duration: .1s;
}

@media (width <= 1200px) {
  .buttons--touch-scroll .label, .calculate .label, .interests .label, .interest-header .label {
    padding: .8em .65em;
  }

  .interests__gap .label {
    padding: 1.2em;
  }

  .interest-header--banks .label {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 1023px) {
  .calculate .label {
    padding: 1.2em;
  }

  .interest-header--banks .label {
    border-radius: 0;
    padding: 0;
  }
}

@media (width <= 599px) {
  .calculate .label {
    padding: .8em .65em;
  }

  .interest-header .label {
    --color: var(--colors-text-one);
    --inactive-bg-color: var(--colors-bg-three);
  }

  .interest-header--dark .label {
    --inactive-bg-color: var(--colors-bg-one);
  }

  .interest-header--dark .label--div {
    --inactive-bg-color: var(--colors-bg-three);
  }
}

.label:after {
  pointer-events: none;
  content: "";
  border-top: 3px solid var(--color);
  border-right: 3px solid var(--color);
  width: 1.4rem;
  height: 1.4rem;
  transition: border-color, transform;
  transition-duration: var(--transition-default);
  margin-left: -.6rem;
  display: flex;
  transform: rotate(45deg);
}

.label.hover:after, body:not(.body--touch) .label:focus:after, body:not(.body--touch) .label:hover:after {
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.label--toggle:after {
  content: none;
}

.label--locaties {
  width: 100%;
}

.results .label, .calculate .label {
  --inactive-bg-color: var(--colors-bg-one);
  --color: var(--colors-text-one);
  margin-bottom: var(--gutter-block);
  border: var(--colors-theme-border) solid 1px;
}

.results .label.hover, body:not(.body--touch) .results .label:focus, body:not(.body--touch) .results .label:hover, .calculate .label.hover, body:not(.body--touch) .calculate .label:focus, body:not(.body--touch) .calculate .label:hover {
  --inactive-bg-color: var(--colors-theme-two);
}

.label--select {
  padding: 0;
}

.label--select:after {
  border-width: 2px;
  width: 1rem;
  height: 1rem;
  margin-top: -.2em;
  position: absolute;
  right: 1.2em;
  transform: rotate(135deg);
}

.interest-header .label--select {
  float: right;
}

.label--select:has(select:hover):after, .label--select:has(select:focus):after, .label--select.hover:after, body:not(.body--touch) .label--select:focus:after, body:not(.body--touch) .label--select:hover:after {
  transform: rotate(135deg)translate(.3rem, -.3rem) !important;
}

.label--period select {
  width: 5.2em;
}

.label .label__select {
  pointer-events: auto;
  cursor: pointer;
  min-height: 1em;
  color: var(--color);
  background-color: #0000;
  border: 0;
  flex-grow: 1;
  padding: 1em calc(1.2em + 2rem) 1em 1.2em;
  line-height: 1.4;
}

@media (width <= 1200px) {
  .label--select {
    padding: 0 !important;
  }

  .label--period select {
    width: 4.4em;
  }

  .buttons--touch-scroll .label, .calculate .label, .interests .label, .interest-header .label {
    border-radius: 10rem;
    padding: 0;
  }

  .buttons--touch-scroll .label--select:after, .calculate .label--select:after, .interests .label--select:after, .interest-header .label--select:after {
    right: 1em;
  }

  .buttons--touch-scroll .label .label__select, .calculate .label .label__select, .interests .label .label__select, .interest-header .label .label__select {
    padding: .6em calc(.8em + 2rem) .6em .8em;
  }
}

@media (width <= 599px) {
  .label--period select {
    width: 4.7em;
  }

  .interest-header .label {
    background-color: var(--colors-bg-one);
  }

  .interest-header--banks .label {
    float: initial;
    font-size: var(--font-size-small);
  }

  .interest-header--banks .label .label__select {
    font-size: var(--font-size-small);
  }
}

.loader {
  pointer-events: none;
  opacity: 0;
  width: 100%;
  height: 2px;
  transition: opacity var(--transition-default);
  position: fixed;
  top: 0;
  left: 0;
}

.loader--bottom {
  top: auto;
  bottom: 0;
}

.html--loader-pagination .loader, .html--loader-filter .loader, .html--loader .loader {
  opacity: 1;
}

.loader .loader__line {
  transform-origin: 0;
  background-color: var(--colors-text-one);
  width: 100%;
  height: 100%;
  transition-duration: var(--transition-default);
  transition-property: transform, opacity, background-color;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
}

.logo {
  align-items: center;
  display: flex;
  position: relative;
}

.logo .logo__icon, .logo .logo__rest {
  transition: opacity var(--transition-default);
  display: flex;
}

.logo .logo__icon svg, .logo .logo__rest svg {
  width: 100%;
  height: auto;
}

.logo .logo__icon svg path, .logo .logo__rest svg path {
  transition: fill var(--transition-default);
}

.logo .logo__icon {
  --width: 3.18;
  --margin: 1.57;
  width: calc(var(--width) / 30 * 100%);
  margin-right: calc(var(--margin) / 30 * 100%);
}

.logo .logo__rest {
  --width: 25.1;
  --top: .4;
  width: calc(var(--width) / 30 * 100%);
  margin-top: calc(var(--top) / 30 * 100%);
  position: relative;
}

.nav .logo {
  width: 21rem;
}

.nav .logo .logo__rest svg path, .nav .logo .logo__icon svg path {
  fill: var(--colors-text-one);
}

.body--nav .nav .logo .logo__rest svg path, .body--nav-black .nav .logo .logo__rest svg path, .body--nav .nav .logo .logo__icon svg path, .body--nav-black .nav .logo .logo__icon svg path {
  fill: var(--colors-text-two);
}

.footer .logo {
  width: 30rem;
}

@media (width <= 1200px) {
  .nav .logo {
    width: 19rem;
  }
}

@media (width <= 800px) {
  .nav .logo {
    width: 24rem;
  }
}

@media (width <= 599px) {
  .nav .logo {
    width: 13rem;
  }

  .nav .logo .logo__icon {
    width: calc(var(--width) / 15 * 100%);
  }

  .nav .logo .logo__rest {
    --left: 4.75;
    pointer-events: none;
    left: calc(var(--left) / 15 * 100%);
    width: calc(var(--width) / 15 * 100%);
    margin-top: calc(var(--top) / 15 * 100%);
    opacity: 0;
    margin-right: -100%;
    position: absolute;
  }

  .nav .logo .logo__rest svg path {
    fill: var(--colors-text-two);
  }

  .body--nav .nav .logo .logo__rest {
    pointer-events: auto;
    opacity: 1;
  }
}

.news {
  margin-bottom: var(--gutter-medium);
  border-radius: var(--gutter-large);
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.news .news__image {
  aspect-ratio: 3 / 2;
  position: relative;
  overflow: hidden;
}

.news .news__image img {
  will-change: transform;
  transition: transform var(--transition-default) var(--transition-easing);
}

.news .news__texts {
  --bg-color: var(--colors-bg-three);
  padding: var(--gutter-medium);
  background-color: var(--bg-color);
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.news .news__texts .news__date {
  margin-bottom: .5em;
}

.news .news__texts .news__heading {
  background-image: linear-gradient(var(--color), var(--color));
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
  text-decoration: none;
  transition-property: background-size, opacity;
  display: inline;
}

.news .news__texts .news__text {
  color: var(--colors-text-transparent);
  margin-top: .5em;
}

.news--hor {
  --bg-color: var(--colors-bg-three);
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  flex-direction: row;
}

.news--hor .news__image {
  aspect-ratio: 1 / 1.1;
  border-radius: var(--gutter-large);
  width: 50%;
  margin: 1rem;
}

.news--hor .news__texts {
  width: 50%;
  padding: var(--gutter-medium) var(--gutter-large);
  align-self: flex-end;
}

.news--hor .news__texts .news__heading {
  background: none;
}

.news.hover .news__image img, body:not(.body--touch) .news:focus .news__image img, body:not(.body--touch) .news:hover .news__image img {
  transition-duration: var(--transition-fast);
  transform: scale(1.05);
}

.news.hover .news__texts .news__heading, body:not(.body--touch) .news:focus .news__texts .news__heading, body:not(.body--touch) .news:hover .news__texts .news__heading {
  background-position: 0 var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

@media (width <= 1023px) {
  .news--hor {
    flex-direction: column;
  }

  .news--hor .news__image {
    aspect-ratio: 16 / 9;
    border-radius: 0;
    width: 100%;
    margin: 0;
  }

  .news--hor .news__texts {
    width: 100%;
    padding: var(--gutter-medium);
  }
}

.person {
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium) var(--gutter-small) var(--gutter-medium) var(--gutter-medium);
  border: .1rem solid var(--colors-theme-border);
  border-radius: var(--border-radius-large);
  align-items: center;
  display: flex;
  container-type: inline-size;
}

.person .person__image {
  width: 12rem;
  height: 12rem;
  margin-right: var(--gutter-large);
  background-color: var(--colors-bg-three);
  border-radius: 10rem;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

@container (width <= 38rem) {
  .person .person__image {
    width: 8rem;
    height: 8rem;
    margin-right: var(--gutter-medium);
  }
}

@container (width <= 32rem) {
  .person .person__image {
    width: 6rem;
    height: 6rem;
    margin-right: var(--gutter-small);
  }
}

.person .person__texts {
  max-width: calc(100% - 6rem - var(--gutter-small));
  flex-grow: 1;
}

.person .person__texts .person__email {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  height: 1.2em;
  display: block;
  overflow: hidden;
}

body:not(.body--touch) .person .person__texts .person__email:focus, body:not(.body--touch) .person .person__texts .person__email:hover, .person .person__texts .person__email.hover {
  text-underline-offset: .2rem;
  text-decoration: underline;
}

.radio {
  --lineheight: 1.2;
  cursor: pointer;
  min-height: 4.2em;
  margin-bottom: var(--gutter-small);
  line-height: var(--lineheight);
  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);
  transition: background-color var(--transition-default);
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2em;
  display: flex;
  position: relative;
}

.buttons .radio {
  margin: .5rem;
}

.radio .radio__icon {
  width: 2.6rem;
  margin-right: .75em;
}

.radio .radio__icon svg {
  max-width: 100%;
  height: auto;
}

.radio .radio__text {
  flex-grow: 1;
  padding: .35em 0;
}

.radio .radio__circle, .radio .radio__box {
  right: var(--gutter-medium);
  border: 1px solid var(--colors-theme-border);
  transition: background-color var(--transition-default);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.radio .radio__circle {
  border-radius: 10rem;
  width: 3.2rem;
  height: 3.2rem;
}

.radio .radio__circle svg {
  margin-right: 0;
}

.radio .radio__circle svg path {
  fill: var(--colors-text-two);
  transition: fill var(--transition-default);
}

.radio .radio__box {
  border-radius: .6rem;
  width: 2.4rem;
  height: 2.4rem;
  padding: 2px;
}

.radio .radio__box:after {
  content: "";
  width: 100%;
  height: 100%;
  transition: background-color var(--transition-default);
  border-radius: .4rem;
}

.radio input[type="checkbox"], .radio input[type="radio"] {
  opacity: 0;
  margin-left: 3rem;
}

.radio:has(input[type="checkbox"]:focus) .radio__circle svg path, .radio:has(input[type="radio"]:focus) .radio__circle svg path, .radio.hover .radio__circle svg path, body:not(.body--touch) .radio:focus .radio__circle svg path, body:not(.body--touch) .radio:hover .radio__circle svg path {
  fill: var(--colors-text-one);
  transition-duration: var(--transition-fast);
}

.radio:has(input[type="checkbox"]:focus) .radio__box:after, .radio:has(input[type="radio"]:focus) .radio__box:after, .radio.hover .radio__box:after, body:not(.body--touch) .radio:focus .radio__box:after, body:not(.body--touch) .radio:hover .radio__box:after {
  background-color: var(--colors-theme-three);
}

.radio:has(input[type="checkbox"]:checked), .radio:has(input[type="radio"]:checked) {
  background-color: var(--colors-bg-three);
}

.radio:has(input[type="checkbox"]:checked) .radio__circle, .radio:has(input[type="radio"]:checked) .radio__circle {
  background-color: var(--colors-bg-two);
}

.radio:has(input[type="checkbox"]:checked) .radio__circle svg path, .radio:has(input[type="radio"]:checked) .radio__circle svg path {
  fill: var(--colors-text-two);
}

.radio:has(input[type="checkbox"]:checked) .radio__box:after, .radio:has(input[type="radio"]:checked) .radio__box:after {
  background-color: var(--colors-theme-one) !important;
}

.rate {
  --color: var(--colors-text-one);
  --active-bg-color: var(--colors-theme-highlight);
  --inactive-bg-color: transparent;
  --lineheight: 1.2;
  z-index: 0;
  color: var(--color);
  background-color: var(--inactive-bg-color);
  border-radius: var(--gutter-medium);
  transition: background-color, color, border-color, opacity;
  transition-duration: var(--transition-default);
  z-index: initial;
  min-width: var(--rente-btn-width);
  justify-content: center;
  align-items: center;
  padding: 1.2em 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.rate:before {
  content: "";
  z-index: -1;
  background: linear-gradient(180deg, var(--inactive-bg-color) 0%, var(--inactive-bg-color) 20%, var(--colors-theme-two) 40%, var(--colors-theme-two) 60%, var(--active-bg-color) 80%, var(--active-bg-color) 100%);
  width: 100%;
  height: 500%;
  transition: transform var(--transition-default) var(--transition-easing);
  position: absolute;
  top: 0;
  left: 0;
}

.column__content--dark .rate, .interest-header .rate, .rate--back, .rate--border, .rate--inverted {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-bg-one);
}

.nav .column__content--dark .rate, .nav .interest-header .rate, .nav .rate--back, .nav .rate--border, .nav .rate--inverted {
  --inactive-bg-color: var(--colors-bg-three);
}

.rate--border {
  border: 1px solid var(--colors-theme-border);
}

.interest-header:not(.interest-header--dark) .rate {
  --active-bg-color: var(--colors-bg-two);
}

.interest-header:not(.interest-header--dark) .rate--active {
  --color: var(--colors-text-two) !important;
}

.interest-header--dark .rate {
  --inactive-bg-color: var(--colors-glow);
  --color: var(--colors-text-two);
}

.interest-header--dark .rate--active {
  --color: var(--colors-text-one);
  --inactive-bg-color: var(--colors-theme-highlight);
}

.interest-header--dark .rate--div {
  --inactive-bg-color: var(--colors-bg-two);
}

.buttons .rate {
  margin: .5rem;
}

.buttons--div .rate {
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.rate svg {
  transition: transform var(--transition-default);
  flex-shrink: 0;
  margin: -.5rem .75em -.5rem 0;
}

.rate svg path {
  fill: var(--color);
  transition: fill var(--transition-default);
}

.rate--icon-right svg {
  margin-left: .75em;
  margin-right: 0;
}

.rate:has(input[type="radio"]:focus):not(.rate), .rate:has(select:hover):not(.rate), .rate:has(select:focus):not(.rate), .rate.hover:not(.rate), body:not(.body--touch) .rate:focus:not(.rate), body:not(.body--touch) .rate:hover:not(.rate) {
  --color: var(--colors-text-one);
  background-color: var(--colors-theme-two);
  border-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.rate:has(input[type="radio"]:focus):not(.rate):after, .rate:has(select:hover):not(.rate):after, .rate:has(select:focus):not(.rate):after, .rate.hover:not(.rate):after, body:not(.body--touch) .rate:focus:not(.rate):after, body:not(.body--touch) .rate:hover:not(.rate):after {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.5rem, -.5rem);
}

.rate:has(input[type="radio"]:focus):not(.rate):before, .rate:has(select:hover):not(.rate):before, .rate:has(select:focus):not(.rate):before, .rate.hover:not(.rate):before, body:not(.body--touch) .rate:focus:not(.rate):before, body:not(.body--touch) .rate:hover:not(.rate):before {
  transition-duration: var(--transition-fast);
  transform: translateY(-40%);
}

.rate:has(input[type="radio"]:focus):not(.rate) svg, .rate:has(select:hover):not(.rate) svg, .rate:has(select:focus):not(.rate) svg, .rate.hover:not(.rate) svg, body:not(.body--touch) .rate:focus:not(.rate) svg, body:not(.body--touch) .rate:hover:not(.rate) svg {
  transition-duration: var(--transition-fast);
}

.rate:has(input[type="radio"]:focus):not(.rate) svg path, .rate:has(select:hover):not(.rate) svg path, .rate:has(select:focus):not(.rate) svg path, .rate.hover:not(.rate) svg path, body:not(.body--touch) .rate:focus:not(.rate) svg path, body:not(.body--touch) .rate:hover:not(.rate) svg path {
  fill: var(--colors-text-one);
  transition-duration: var(--transition-fast);
}

.rate.hover {
  transition-duration: .1s;
}

@media (width <= 1200px) {
  .buttons--touch-scroll .rate, .calculate .rate, .interests .rate, .interest-header .rate {
    padding: .8em .65em;
  }

  .interests__gap .rate {
    padding: 1.2em;
  }

  .interest-header--banks .rate {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 1023px) {
  .calculate .rate {
    padding: 1.2em;
  }

  .interest-header--banks .rate {
    border-radius: 0;
    padding: 0;
  }
}

@media (width <= 599px) {
  .calculate .rate {
    padding: .8em .65em;
  }

  .interest-header .rate {
    --color: var(--colors-text-one);
    --inactive-bg-color: var(--colors-bg-three);
  }

  .interest-header--dark .rate {
    --inactive-bg-color: var(--colors-bg-one);
  }

  .interest-header--dark .rate--div {
    --inactive-bg-color: var(--colors-bg-three);
  }
}

.interests--banks .rate {
  background-color: #0000 !important;
}

.interests--banks .rate:after {
  content: none;
}

.rate--active {
  background-color: var(--colors-theme-highlight);
}

.rate--no-bg {
  overflow: visible;
  --inactive-bg-color: transparent !important;
}

.rate--no-bg:before {
  display: none;
}

.rate--no-bg svg {
  margin-right: 0;
}

@media (width <= 1200px) {
  .interests--banks .rate {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width <= 1023px) {
  .interests--banks .rate {
    border-radius: 0;
    justify-content: flex-start;
    padding: 0;
  }
}

@media (width <= 599px) {
  .rate {
    --lineheight: 1.5;
    font-size: var(--font-size-small);
    padding: 0;
  }
}

.ratio {
  width: 100%;
  height: 0;
  padding-bottom: var(--percentage);
  background-position: center;
  background-size: cover;
  display: block;
  position: relative;
  overflow: hidden;
}

.ratio--2-1 {
  padding-bottom: 50%;
}

.ratio--16-9 {
  padding-bottom: 56.25%;
}

.ratio--3-2 {
  padding-bottom: 66.66%;
}

.ratio--4-3 {
  padding-bottom: 75%;
}

.ratio--1-1 {
  padding-bottom: 100%;
}

.ratio--3-4 {
  padding-bottom: 133.33%;
}

.ratio--4-5 {
  padding-bottom: 125%;
}

.ratio img, .ratio video {
  object-fit: cover;
  width: 100%;
  position: absolute;
  height: 100% !important;
}

.ratio--placeholder {
  position: initial;
}

.ratio--placeholder img, .ratio--placeholder video {
  position: initial;
  object-fit: initial;
  height: auto !important;
}

.ratio--cover img, .ratio--cover video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ratio--contain img, .ratio--contain video {
  border-radius: var(--border-radius);
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.ratio--aspect-ratio {
  aspect-ratio: var(--ar);
  height: auto;
}

.ratio .ratio__holder {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.ratio .ratio__overlay {
  opacity: 0;
  background-color: var(--colors-bg-two);
  width: 100%;
  height: 100%;
  transition: opacity var(--transition-slow);
  position: absolute;
}

.ratio .ratio__overlay--visible {
  opacity: 1;
}

.ratio .ratio__overlay .ratio__text {
  color: var(--colors-text-two);
  padding: 1.8rem 2.6rem;
}

@media (width <= 1023px) {
  .ratio .ratio__overlay .ratio__text {
    padding: 1.1rem var(--padding);
  }
}

@media (width <= 800px) {
  .columns__item--1-1 .ratio--16-9 {
    padding-bottom: 100%;
  }
}

.spacer {
  height: var(--gutter-medium);
}

.splash-block {
  z-index: 1;
  aspect-ratio: 1;
  max-width: calc((var(--max-content)  - var(--gutter) * 3) * .3333);
  margin-bottom: var(--gutter);
  padding: var(--gutter-medium);
  border-radius: var(--border-radius-large);
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.splash-block .splash-block__img {
  width: 90%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  transform: scaleX(-1);
}

.splash-block .splash-block__heading {
  position: relative;
}

@media (width <= 1023px) {
  .splash-block {
    width: calc(50% - var(--gutter) * .5);
    margin: var(--padding) 0 0 0;
  }
}

@media (width <= 599px) {
  .splash-block {
    width: 100%;
  }
}

.splash-form {
  width: 66rem;
  max-width: 100%;
  margin-bottom: var(--padding);
  background-color: var(--colors-bg-one);
  border: 1px solid var(--colors-theme-border);
  box-shadow: 0 16px 16px var(--colors-shade);
  border-radius: 28px;
  align-items: center;
  padding: 3px;
  display: flex;
}

.splash-form .splash-form__label {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.splash-form .splash-form__label .splash-form__icon, .splash-form .splash-form__label .splash-form__euro {
  margin: 0 var(--gutter-small);
}

.splash-form .splash-form__label .splash-form__euro {
  --lineheight: 1.15;
  font-size: var(--font-size-between);
  --underline-width: 1px;
  --underline-top: calc(50% + .55em);
  --shadow-width: .08em;
  font-variation-settings: "wdth" 85;
  font-weight: 700;
}

.splash-form .splash-form__label .splash-form__input {
  border: 0;
  flex-grow: 1;
  width: 100%;
  margin-right: auto;
}

.splash-form .splash-form__submit {
  margin-left: auto;
}

@media (width <= 599px) {
  .splash-form {
    box-shadow: none;
    background-color: #0000;
    border: 0;
    flex-direction: column;
    align-items: stretch;
  }

  .splash-form .splash-form__label {
    margin-bottom: var(--gutter-small);
    background-color: var(--colors-bg-one);
    border: 1px solid var(--colors-theme-border);
    border-radius: 28px;
    padding: 1.2em;
  }

  .splash-form .splash-form__label .splash-form__euro, .splash-form .splash-form__submit {
    margin-left: 0;
  }
}

.splash-rect {
  --bg-color: var(--colors-bg-one);
  max-width: calc((var(--max-content)  - var(--gutter) * 3) * .3333);
  padding: var(--gutter-large);
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  flex-direction: column;
  display: flex;
}

.splash-rect .splash-rect__heading {
  background-image: linear-gradient(var(--color), var(--color));
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
  text-decoration: none;
  transition-property: background-size, opacity;
  display: inline;
}

.splash-rect .splash-rect__content {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: .5em;
  display: flex;
}

.splash-rect .splash-rect__content .splash-rect__text {
  text-wrap: balance;
  opacity: var(--text-opacity);
}

.splash-rect .splash-rect__content .splash-rect__side {
  margin: -1rem 0 -1rem 1em;
  display: flex;
}

.splash-rect.hover .splash-rect__heading, body:not(.body--touch) .splash-rect:focus .splash-rect__heading, body:not(.body--touch) .splash-rect:hover .splash-rect__heading {
  background-position: 0 var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

.splash-rect.hover .button, body:not(.body--touch) .splash-rect:focus .button, body:not(.body--touch) .splash-rect:hover .button {
  color: var(--colors-text-one);
  background-color: var(--colors-theme-two);
  transition-duration: var(--transition-fast);
}

.splash-rect.hover .button:after, body:not(.body--touch) .splash-rect:focus .button:after, body:not(.body--touch) .splash-rect:hover .button:after {
  border-color: var(--colors-text-one);
  transition-duration: var(--transition-fast);
  transform: rotate(45deg)translate(.3rem, -.3rem);
}

.splash-rect.hover .button:before, body:not(.body--touch) .splash-rect:focus .button:before, body:not(.body--touch) .splash-rect:hover .button:before {
  transition-duration: var(--transition-fast);
  transform: translateY(-40%);
}

@media (width <= 1023px) {
  .splash-rect {
    width: calc(50% - var(--gutter) * .5);
    margin: var(--padding) 0 0 0;
    padding: var(--gutter-medium);
  }

  .splash-rect .splash-rect__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .splash-rect .splash-rect__content .splash-rect__side {
    margin: .5em 0 0;
  }
}

@media (width <= 800px) {
  .splash-rect {
    --bg-color: var(--colors-bg-three);
  }
}

@media (width <= 599px) {
  .splash-rect {
    width: 100%;
  }
}

.term {
  --bg-color: var(--colors-bg-three);
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium) var(--gutter-small) var(--gutter-medium) var(--gutter-medium);
  text-align: left;
  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);
  display: none;
}

.term--visible {
  display: block;
}

.term .term__button {
  text-align: left;
  width: 100%;
  display: flex;
}

.term .term__button .term__heading {
  --color: var(--colors-text-one);
  text-overflow: ellipsis;
  white-space: nowrap;
  background-image: linear-gradient(var(--color), var(--color));
  max-width: 100%;
  transition-timing-function: var(--transition-easing);
  transition-duration: var(--transition-default);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 var(--underline-top);
  background-size: 0% clamp(1px, var(--underline-width), var(--underline-width));
  text-shadow: calc(var(--shadow-width) * -1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * -1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * -1) var(--bg-color), calc(var(--shadow-width) * 1) calc(var(--shadow-width) * 1) var(--bg-color), calc(var(--shadow-width) * -1.5) 0 var(--bg-color), calc(var(--shadow-width) * 1.5) 0 var(--bg-color), 0 calc(var(--shadow-width) * 1) var(--bg-color), 0 calc(var(--shadow-width) * -1) var(--bg-color);
  line-height: 1.2;
  text-decoration: none;
  transition-property: background-size, opacity;
  overflow: hidden;
}

.term .term__button.hover, body:not(.body--touch) .term .term__button:focus, body:not(.body--touch) .term .term__button:hover {
  transition-duration: var(--transition-fast);
}

.term .term__button.hover .term__heading, body:not(.body--touch) .term .term__button:focus .term__heading, body:not(.body--touch) .term .term__button:hover .term__heading {
  background-position: 0 var(--underline-top);
  background-size: 100% clamp(1px, var(--underline-width), var(--underline-width));
  transition-duration: var(--transition-fast);
}

.term .term__content {
  -webkit-line-clamp: 2;
  max-height: calc(var(--lineheight) * 2em);
  transition: max-height var(--transition-default) var(--transition-easing);
  -webkit-box-orient: vertical;
  margin-top: .3em;
  padding-bottom: 1px;
  display: -webkit-box;
  overflow: hidden;
}

.term .term__content--no-clamp {
  -webkit-line-clamp: unset;
}

.term .term__content--active {
  max-height: calc(var(--scroll-height)  + 1px);
}

.term .term__content .term__text {
  color: var(--colors-text-transparent);
}

.term .term__content .term__link {
  text-underline-offset: .2rem;
  margin-top: .8em;
  text-decoration: underline;
  display: inline-block;
}

body:not(.body--touch) .term .term__content .term__link:focus, body:not(.body--touch) .term .term__content .term__link:hover, .term .term__content .term__link.hover {
  text-decoration: none;
}

.text {
  width: 100%;
  margin-bottom: calc(var(--lineheight) * 1em);
}

.text--balance {
  text-wrap: balance;
}

.text--grey {
  color: var(--colors-text-transparent);
}

.text--low-margin {
  margin-bottom: .5em;
}

.text--cb {
  margin-bottom: calc(var(--lineheight) * 2em);
}

.text--large {
  --lineheight: 1.5;
  font-size: var(--font-size-intro);
  margin-bottom: calc(var(--lineheight) * 1.2em);
}

.text--2-columns {
  column-count: 2;
  column-gap: 1em;
}

.toggle {
  --toggle-size: 2rem;
  --toggle-width: 4rem;
  --toggle-border-width: 2px;
  --toggle-color: var(--colors-text-two);
  --bg-color: var(--colors-bg-two);
  cursor: pointer;
  transition: opacity;
  transition-duration: var(--transition-default);
  align-items: center;
  display: flex;
  position: relative;
}

.interest-header .toggle {
  padding: .6em .8em;
  line-height: 1.4;
}

.toggle svg {
  flex-shrink: 0;
  margin-right: .75em;
}

.toggle .toggle__btn {
  padding: .6em calc(var(--toggle-width) * .5 - var(--toggle-border-width) * 2) .4em;
  font-size: var(--toggle-size);
  vertical-align: middle;
  background-color: var(--bg-color);
  transition: background-color var(--transition-default);
  border-radius: 1em;
  flex-shrink: 0;
  margin-right: .5em;
  display: inline-block;
  position: relative;
}

.toggle .toggle__btn:before {
  content: "";
  top: var(--toggle-border-width);
  left: var(--toggle-border-width);
  transform: translateX(calc(var(--toggle-width)  - 1em - var(--toggle-border-width) * 4));
  width: calc(1em - var(--toggle-border-width) * 2);
  height: calc(1em - var(--toggle-border-width) * 2);
  background-color: var(--toggle-color);
  border-radius: calc(1em - var(--toggle-border-width) * 2);
  transition: transform var(--transition-default) var(--transition-easing), background-color var(--transition-default);
  position: absolute;
}

.toggle--text-on .toggle__btn {
  margin-right: 0;
}

.toggle--text-on .toggle__text {
  color: var(--toggle-color);
  transition: opacity var(--transition-default), color var(--transition-default);
  position: absolute;
}

.toggle--text-on .toggle__text--on {
  left: .5em;
}

.toggle--text-on .toggle__text--off {
  opacity: 0;
  right: .5em;
}

.toggle--switch {
  justify-content: flex-end;
}

.toggle--switch .toggle__btn {
  order: 1;
  margin-left: .5em;
  margin-right: 0;
}

.toggle--inverted {
  mix-blend-mode: screen;
}

.toggle--off {
  opacity: .3;
}

.toggle--off .toggle__btn:before {
  transform: none;
}

.toggle--off.toggle--text-on {
  opacity: 1;
}

.toggle--off.toggle--text-on .toggle__text--on {
  opacity: 0;
}

.toggle--off.toggle--text-on .toggle__text--off {
  opacity: 1;
}

body:not(.body--touch) .toggle:focus, body:not(.body--touch) .toggle:hover, .toggle.hover {
  opacity: .6;
}

.calculate .toggle {
  --toggle-size: 2.6rem;
  --toggle-width: 5.6rem;
  --bg-color: var(--colors-theme-one);
  width: 100%;
  margin-bottom: var(--gutter-block);
}

.calculate .toggle--off {
  --bg-color: var(--colors-bg-four);
  opacity: 1;
}

.calculate .toggle .toggle__btn {
  margin-left: auto;
}

.calculate .toggle:has(button:focus), body:not(.body--touch) .calculate .toggle:focus, body:not(.body--touch) .calculate .toggle:hover, .calculate .toggle.hover {
  --bg-color: var(--colors-theme-two);
  opacity: 1;
}

.toggle--bordered .toggle__btn {
  padding: calc(.6em - var(--toggle-border-width)) calc(var(--toggle-width) * .5 - var(--toggle-border-width)) calc(.4em - var(--toggle-border-width));
  background-color: var(--bg-color);
  border: var(--toggle-color) solid var(--toggle-border-width);
  border-radius: 10rem;
}

.toggle--bordered .toggle__btn:before {
  transform: translateX(calc(var(--toggle-width)  - 1em));
  width: calc(1em - var(--toggle-border-width) * 4);
  height: calc(1em - var(--toggle-border-width) * 4);
  background-color: var(--toggle-color);
}

.toggle--bordered.toggle--off .toggle__btn:before {
  transform: none;
}

body:not(.body--touch) .toggle--bordered:focus, body:not(.body--touch) .toggle--bordered:hover, .toggle--bordered.hover {
  opacity: 1;
}

body:not(.body--touch) .toggle--bordered:focus .toggle__btn, body:not(.body--touch) .toggle--bordered:hover .toggle__btn, .toggle--bordered.hover .toggle__btn {
  background-color: var(--toggle-color);
}

body:not(.body--touch) .toggle--bordered:focus .toggle__btn:before, body:not(.body--touch) .toggle--bordered:hover .toggle__btn:before, .toggle--bordered.hover .toggle__btn:before {
  background-color: var(--bg-color);
}

body:not(.body--touch) .toggle--bordered.toggle--text-on:focus .toggle__text, body:not(.body--touch) .toggle--bordered.toggle--text-on:hover .toggle__text, .toggle--bordered.toggle--text-on.hover .toggle__text {
  color: var(--bg-color);
}

.body--transparent-header .header:not(.header--cookies, .header--force-btn-bg-color) .toggle {
  --bg-color: var(--colors-bg-one);
}

@media (width <= 599px) {
  .interest-header--dark .toggle {
    --toggle-size: 1.6rem;
    --toggle-width: 3.6rem;
  }
}

.word {
  margin: 0 -.03em -.05em;
  display: inline-flex;
  overflow: hidden;
}

.word .word__text {
  will-change: transform;
  padding: 0 .03em .05em;
}

@media print {
  html, body {
    width: 900px;
  }

  html {
    font-size: 62.5%;
  }

  body {
    padding-top: 0;
  }

  .nav, .footer {
    display: none;
  }

  .text {
    padding-bottom: 1em;
  }

  section {
    page-break-inside: avoid;
  }

  .gallery-holder .gallery-holder__gallery {
    width: 100%;
  }

  .gallery {
    flex-wrap: wrap;
    display: flex;
  }

  .gallery .gallery__item {
    width: 45%;
    margin-right: 5%;
  }

  .gallery .gallery__item .gallery__item__caption {
    margin-bottom: 1em;
  }
}

:root {
  --swiper-wrapper-transition-timing-function: var(--transition-easing);
}
/*# sourceMappingURL=index.90ab90e2.css.map */
