@use "../3-core/fonts" as fonts;

.text {
  width: 100%;
  margin-bottom: calc(var(--lineheight) * 1em);

  &--balance {
    text-wrap: balance;
  }

  &--grey {
    color: var(--colors-text-transparent);
  }

  &--low-margin {
    margin-bottom: 0.5em;
  }

  &--cb {
    margin-bottom: calc(var(--lineheight) * 2em);
  }

  &--large {
    @include fonts.font-intro;

    margin-bottom: calc(var(--lineheight) * 1.2em);
  }

  &--2-columns {
    column-count: 2;
    column-gap: 1em;
  }
}
