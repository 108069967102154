.loader {
  pointer-events: none;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;

  // height: 100%;
  height: 2px;

  // background-color: var(--colors-bg-one);

  opacity: 0;

  transition: opacity var(--transition-default);

  // &:not(.loader--bg) {
  //   background-color: transparent;
  // }

  // .loader
  &--bottom {
    top: auto;
    bottom: 0;
  }

  // .loader
  .html--loader-pagination &,
  .html--loader-filter &,
  .html--loader & {
    opacity: 1;
  }

  .loader__line {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left;
    transform: scaleX(0);

    width: 100%;
    height: 100%;

    background-color: var(--colors-text-one);

    transition-duration: var(--transition-default);
    transition-property: transform, opacity, background-color;
  }
}
