@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.term {
  --bg-color: var(--colors-bg-three);

  display: none;

  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium) var(--gutter-small) var(--gutter-medium) var(--gutter-medium);

  text-align: left;

  background-color: var(--bg-color);
  border-radius: var(--border-radius-large);

  &--visible {
    display: block;
  }

  .term__button {
    display: flex;
    width: 100%;
    text-align: left;

    .term__heading {
      --color: var(--colors-text-one);

      overflow: hidden;

      max-width: 100%;

      line-height: 1.2;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include mixins.sick-underline;
      @include mixins.underline-left;
      @include mixins.underline-text-shadow;
    }

    // .term__button
    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      transition-duration: var(--transition-fast);

      .term__heading {
        @include mixins.underline-full-left;

        transition-duration: var(--transition-fast);
      }
    }
  }

  .term__content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    max-height: calc(var(--lineheight) * 2em);
    margin-top: 0.3em;
    padding-bottom: 1px; // for non collapsing margin

    transition: max-height var(--transition-default) var(--transition-easing);

    &--no-clamp {
      -webkit-line-clamp: unset;
    }

    &--active {
      max-height: calc(var(--scroll-height) + 1px);
    }

    .term__text {
      color: var(--colors-text-transparent);
    }

    .term__link {
      display: inline-block;
      margin-top: 0.8em;
      text-decoration: underline;
      text-underline-offset: 0.2rem;

      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover,
      &.hover {
        text-decoration: none;
      }
    }
  }
}
