@use "../1-base/functions" as functions;
@use "../1-base/vars" as vars;

// @font-face {
//   font-family: 'NeueHaasUnicaW1G';
//
//   font-display: fallback;
//   src: url('../fonts/NeueHaasUnicaW1GRegular/font.woff2') format('woff2'),
//   url('../fonts/NeueHaasUnicaW1GRegular/font.woff') format('woff');
// }
// @font-face {
//   font-family: 'NeueHaasUnicaW1G';
//   font-weight: bold;
//
//   font-display: fallback;
//   src: url('../fonts/NeueHaasUnicaW1Glarge/font.woff2') format('woff2'),
//   url('../fonts/NeueHaasUnicaW1Glarge/font.woff') format('woff');
// }

$multiplier: 1; // based on difference between design width and max content width

// font sizes are designed sizes
$font-xs: 10px * $multiplier;
$font-small: 13px;
$font-book: 16px;
$font-intro: 18px;
$font-intro-mobile: 16px;
$font-normal: 20px;
$font-between: 24px;
$font-medium: 32px; // 32 on 1440px
$font-medium-mobile: calc(24px / 1.02); // 24 on 393px
$font-large: 48px; // 48 on 1440px
$font-large-mobile: calc(32px / 1.02); // 32 on 393px
// $font-xl: 64px * 1.18; // 64 on 1440px
$font-xl: 64px; // 64 on 1440px
$font-xl-mobile: calc(40px / 1.02); // 40 on 393px

:root {
  // FONTS
  --font-family-serif: "Times", serif;
  --font-family-sans: "Arial", sans-serif;
  --font-family-display: "Stencil", sans-serif;

  // SIZES
  // to calculate above the design width, use this formula:
  // https://www.omnicalculator.com/math/line-equation-from-two-points
  // x1 = mobile width, y1 = font mobile
  // x2 = desktop width, y2 = font desktop

  // name: #(fluid(max size, min size, min width, max width));
  --font-size-small: #{$font-small};
  --font-size-book: #{$font-book};
  --font-size-intro: #{functions.fluid($font-intro, $font-intro-mobile, vars.$mq-mobile-small, vars.$mq-max-content)};
  --font-size-normal: #{$font-normal};
  --font-size-between: #{$font-between};
  --font-size-medium: #{functions.fluid($font-medium, $font-medium-mobile, vars.$mq-mobile-small, vars.$mq-max-content)};
  --font-size-large: #{functions.fluid($font-large, $font-large-mobile, vars.$mq-mobile-small, vars.$mq-max-content)};
  --font-size-xl: #{functions.fluid($font-xl, $font-xl-mobile, vars.$mq-mobile-small, vars.$mq-max-content)};
}

@mixin font-margins {
  margin-top: -0.1em;
  margin-bottom: -0.15em;
}

@mixin font-display {
  --underline-width: 0.07em;
  --underline-top: calc(50% + 0.47em);

  font-family: "Nunito Sans", sans-serif;
  font-variation-settings:
    "wdth" 125,
    "YTLC" 500;
  font-optical-sizing: auto;
  font-weight: 940;
  font-style: normal;
}

@mixin font-sans {
  --underline-width: 1px;
  --underline-top: calc(50% + 0.57em);
  --shadow-width: 0.075em;

  font-family: "Encode Sans", serif;
  font-variation-settings: "wdth" 110;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@mixin font-sans-condensed {
  --underline-width: 1px;
  --underline-top: calc(50% + 0.55em);
  --shadow-width: 0.08em;

  font-variation-settings: "wdth" 85;
  font-weight: 700;
}

@mixin font-small {
  --lineheight: 1.5;

  font-size: var(--font-size-small);
}

@mixin font-book {
  --lineheight: 1.5;

  font-size: var(--font-size-book);
}

@mixin font-intro {
  --lineheight: 1.5;

  font-size: var(--font-size-intro);
}

@mixin font-normal {
  --lineheight: 1.15;

  font-size: var(--font-size-normal);
}

@mixin font-between {
  --lineheight: 1.15;

  font-size: var(--font-size-between);
}

@mixin font-medium {
  --lineheight: 1.1;

  font-size: var(--font-size-medium);
}

@mixin font-large {
  --lineheight: 1;

  font-size: var(--font-size-large);
}

@mixin font-xl {
  --lineheight: 1;
  --underline-width: 0.06em;

  font-size: var(--font-size-xl);
}
