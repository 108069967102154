@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.how {
  width: 100%;
  padding: var(--gutter-medium) var(--gutter-medium) 0;

  .how__header {
    max-width: 30em;
    margin-bottom: var(--gutter-large);
  }

  .how__content {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--gutter-large);

    .how__buttons {
      flex-shrink: 0;
      width: auto;
    }

    .how__texts {
      @include fonts.font-intro;

      display: flex;
      flex-grow: 1;
      align-items: flex-start;
      padding: 0 10% 1px;
    }
  }

  @media (max-width: vars.$mq-small) {
    padding: 0;

    .how__content {
      margin-bottom: var(--gutter-medium);
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .how__content {
      .how__texts {
        margin-left: var(--gutter-medium);
        padding: 0;
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .how__header {
      margin-bottom: var(--gutter-small);
    }

    .how__content {
      flex-direction: column;
      width: 100%;

      .how__buttons {
        overflow: scroll;
        flex-flow: row nowrap;

        width: calc(100% + var(--gutter-small) * 2);
        margin: 0 calc(var(--gutter-small) * -1) var(--gutter-small);
        padding: 0 calc(var(--gutter-small) - 0.5rem);
      }

      .how__texts {
        margin-left: 0;
      }
    }
  }
}
