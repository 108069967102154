@use "../3-core/fonts" as fonts;

b,
strong { font-weight: 800; }

img {
  width: auto;
  max-width: 100%;
  height: auto;
}

a {
  cursor: pointer;
  color: var(--colors-text-one);
  text-decoration: none;

  // filled links animate
  // &[href]:not([href='#']):not([href='']) {
  //   animation-name: fade-in-out;
  //   animation-duration: 3s;
  //   animation-iteration-count: infinite;
  // }
}

button {
  cursor: pointer;

  padding: 0;

  line-height: 1;
  color: var(--colors-text-one);

  background-color: transparent;
  border: 0;

  // animation-name: fade-in-out;
  // animation-duration: 3s;
  // animation-iteration-count: infinite;
}

ul {
  list-style: disc;

  .text--search &,
  .footer & {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
  }
}

li {
  .text--search & {
    margin-bottom: calc(var(--lineheight) * 1em);
  }
}

ol { list-style: decimal; }

h1,
h2,
h3,
h4 {
  @include fonts.font-display;
  @include fonts.font-margins;

  line-height: var(--lineheight);

  .text & {
    margin-bottom: 0.2em;
  }
}

h3,
h4 {
  text-wrap: balance; // breaks on h1/h2 using 'words'
}

h1 { @include fonts.font-xl; }
h2 { @include fonts.font-large; }
h3 { @include fonts.font-medium; }
h4 { @include fonts.font-between; }

h5 {
  @include fonts.font-normal;
  @include fonts.font-sans-condensed;

  line-height: var(--lineheight);
  text-wrap: balance;
}

h6 {
  @include fonts.font-intro;

  font-variation-settings: "wdth" 125;
}

p,
ul,
ol {
  margin: 0 0 calc(var(--lineheight) * 1em);
  line-height: var(--lineheight);

  button,
  a {
    text-decoration: underline;
    text-underline-offset: 0.2rem;

    // text-decoration-thickness: .07em;

    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover,
    &.hover {
      text-decoration: none;
    }

    .footer & {
      text-decoration: none;

      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover,
      &.hover {
        text-decoration: underline;
        text-underline-offset: 0.2rem;
      }
    }
  }
}

p {
  .field-caption &,
  .highlight__text &,
  .info &,
  .interest-header &,
  .term & {
    margin-bottom: 0;

    + p {
      padding-top: calc(var(--lineheight) * 1em);
    }
  }
}

button :focus {
  outline: none;
}

@keyframes fade-in-out {
  0% { opacity: 1; }
  10% { opacity: 0.5; }
  20% { opacity: 1; }
  100% { opacity: 1; }
}

address {
  font-style: normal;
}

@mixin form-element {
  @include fonts.font-sans;

  min-height: 1.7em; // needed for select to be same height as input
  padding: 0.2em;

  font-weight: normal !important;
  line-height: 1.3; // so letters are not cut off
  color: var(--colors-text-one);

  // border: var(--colors-text-one) 1px solid;
  border: 0;

  // border-radius: .5rem;

  transition: border-color var(--transition-default);

  // input
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover,
  &.hover {
    border-color: var(--colors-text-one);
    transition-duration: var(--transition-fast);
  }

  &.hover { transition-duration: 0.1s; }
}

// button[type="submit"],
textarea,
select,
input[type="text"],
input[type="time"],
input[type="date"],
input[type="month"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[data-type="number"],
input[type="number"],
input[type="url"],
input[type="submit"] {
  @include form-element;
}
