@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.splash-form {
  display: flex;
  align-items: center;

  width: 66rem;
  max-width: 100%;
  margin-bottom: var(--padding);
  padding: 3px;

  background-color: var(--colors-bg-one);
  border: 1px solid var(--colors-theme-border);
  border-radius: 28px;
  box-shadow: 0 16px 16px var(--colors-shade);

  .splash-form__label {
    display: flex;
    flex-grow: 1;
    align-items: center;

    .splash-form__icon,
    .splash-form__euro {
      margin: 0 var(--gutter-small);
    }

    .splash-form__euro {
      @include fonts.font-between;
      @include fonts.font-sans-condensed;

      font-weight: 700;
    }

    .splash-form__input {
      flex-grow: 1;
      width: 100%;
      margin-right: auto;
      border: 0;
    }
  }

  .splash-form__submit {
    margin-left: auto;
  }

  @media (max-width: vars.$mq-mobile) {
    flex-direction: column;
    align-items: stretch;

    background-color: #0000;
    border: 0;
    box-shadow: none;

    .splash-form__label {
      margin-bottom: var(--gutter-small);
      padding: 1.2em;

      background-color: var(--colors-bg-one);
      border: 1px solid var(--colors-theme-border);
      border-radius: 28px;

      .splash-form__euro {
        margin-left: 0;
      }
    }

    .splash-form__submit {
      margin-left: 0;
    }
  }
}
