@use "../1-base/vars" as vars;

.logo {
  position: relative;
  display: flex;
  align-items: center;

  .logo__icon,
  .logo__rest {
    display: flex;
    transition: opacity var(--transition-default);

    svg {
      width: 100%;
      height: auto;

      path {
        transition: fill var(--transition-default);
      }
    }
  }

  .logo__icon {
    --width: 3.18;
    --margin: 1.57;

    width: calc(var(--width) / 30 * 100%);
    margin-right: calc(var(--margin) / 30 * 100%);
  }

  .logo__rest {
    --width: 25.1;
    --top: 0.4;

    position: relative;
    width: calc(var(--width) / 30 * 100%);
    margin-top: calc(var(--top) / 30 * 100%);
  }

  // .logo
  .nav & {
    width: 21rem;

    .logo__rest,
    .logo__icon {
      svg path {
        fill: var(--colors-text-one);

        .body--nav &,
        .body--nav-black & {
          fill: var(--colors-text-two);
        }
      }
    }
  }

  .footer & {
    width: 30rem;
  }

  @media (max-width: vars.$mq-small) {
    .nav & {
      width: 19rem;
    }
  }

  @media (max-width: vars.$mq-phablet) {
    .nav & {
      width: 24rem;
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .nav & {
      width: 13rem;

      .logo__icon {
        width: calc(var(--width) / 15 * 100%);
      }

      .logo__rest {
        --left: 04.75;

        pointer-events: none;

        position: absolute;
        left: calc(var(--left) / 15 * 100%);

        width: calc(var(--width) / 15 * 100%);
        margin-top: calc(var(--top) / 15 * 100%);
        margin-right: -100%;

        opacity: 0;

        svg path { fill: var(--colors-text-two); }
      }

      .body--nav & {
        .logo__rest {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }
}
