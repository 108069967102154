@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.excerpt {
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  aspect-ratio: 5/6;
  max-width: calc(calc(var(--max-content) - var(--gutter) * 3) * 0.3333);
  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-large) var(--gutter-medium) 0;

  text-align: center;

  .excerpt__gradient {
    position: absolute;

    overflow: hidden;

    width: 100%;
    height: 100%;

    border-radius: var(--border-radius-large);
  }

  .excerpt__heading,
  .excerpt__preface,
  .excerpt__text {
    margin-bottom: 0.5em;
    text-align: center;
  }

  .excerpt__heading {
    display: inline;
    text-align: center;
  }

  .excerpt__preface { margin-top: auto; }
  .excerpt__text { margin-bottom: auto; }

  .excerpt__img {
    will-change: transform;

    transform-origin: bottom;

    width: 80%;
    margin-top: 0.5em;

    transition: transform var(--transition-default);
  }

  // .excerpt
  &.hover,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover {
    .excerpt__img {
      transform: scale(1.03);
      transition-duration: var(--transition-fast);
    }
  }
}
