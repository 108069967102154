@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;

.highlights {
  --swiper-navigation-sides-offset: -4.8rem;

  overflow: hidden;
  margin-top: var(--gutter-large);
  padding: 0 calc(var(--gutter) + 4.8rem) calc(10vmax + var(--gutter-large));

  .highlights__swiper {
    overflow: visible;
    width: 100%;

    .swiper-slide {
      width: 44rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      @include mixins.corner;

      --size: 6.4rem;

      width: var(--size);
      height: var(--size);

      background-color: var(--colors-bg-one);
      border-radius: 10rem;
      box-shadow: 0 8px 16px var(--colors-shade);

      &::after {
        border-color: var(--colors-text-one);
      }

      &.hover,
      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover {
        color: var(--colors-text-one);

        // opacity: var(--hover-opacity);
        background-color: var(--colors-theme-two);
        transition-duration: var(--transition-fast);
      }
    }

    .swiper-button-prev {
      transform: rotate(180deg);
      box-shadow: 0 -8px 16px var(--colors-shade);
    }

    .swiper-pagination {
      --swiper-pagination-top: calc(100% + var(--gutter-large));
      --swiper-pagination-bottom: auto;

      width: 100% !important;
    }

    .swiper-pagination-bullet {
      --swiper-pagination-bullet-width: 1.6rem;
      --swiper-pagination-bullet-height: 1.2rem;
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-bullet-horizontal-gap: 1rem;

      transform: none;

      background-color: var(--colors-bg-four);
      border-radius: 10rem;

      transition: width, background-color;
      transition-duration: var(--transition-fast), var(--transition-default);

      &-active {
        --swiper-pagination-bullet-width: 6.4rem;

        background-color: var(--colors-text-one);

        // after active
        & + .swiper-pagination-bullet {
          --swiper-pagination-bullet-width: 3rem;
        }
      }

      // before active
      &:has(+ .swiper-pagination-bullet-active) {
        --swiper-pagination-bullet-width: 3rem;
      }
    }
  }

  @media (max-width: vars.$mq-small) {
    --swiper-navigation-sides-offset: calc((var(--gutter-small) + 6.4rem) * -1);

    padding-right: calc(var(--gutter) + var(--gutter-small) + 6.4rem);
    padding-left: calc(var(--gutter) + var(--gutter-small) + 6.4rem);
  }

  @media (max-width: vars.$mq-tablet) {
    --swiper-navigation-sides-offset: -4.8rem;

    padding-right: calc(var(--gutter) + 4.8rem);
    padding-left: calc(var(--gutter) + 4.8rem);
  }

  @media (max-width: vars.$mq-mobile) {
    --swiper-navigation-sides-offset: calc(var(--gutter-small) * -1);

    padding-right: calc(var(--gutter) + var(--gutter-small));
    padding-left: calc(var(--gutter) + var(--gutter-small));

    .highlights__swiper {
      .swiper-button-prev,
      .swiper-button-next {
        --size: 4.8rem;
      }
    }
  }
}
