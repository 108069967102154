.background {
  position: fixed;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: var(--colors-theme-one);

  // border-radius: 0 0 4.8rem 4.8rem;

  transition: opacity 0s var(--transition-slow);

  .body[data-pagetype-next="banks"] & {
    opacity: 0;
    transition-delay: 0s;
  }

  .background__sunrise {
    --gradient-size: calc(15rem + 50vmax);

    position: absolute;

    width: var(--gradient-size);
    height: var(--gradient-size);

    animation: sunrise calc(var(--transition-slow) * 4) var(--transition-easing-out) calc(var(--transition-slow) * 2) both;
    animation-fill-mode: both;

    @keyframes sunrise {
      0% { transform: translateY(100vh); }
      100% { transform: translateY(0); }
    }

    .background__gradient {
      position: absolute;
      width: 100%;
      height: 100%;

      // background-image: radial-gradient(circle, #fff7c3 0%, #c2f2c5 17.5%, #83e8de 35%, #64d6fa 52.5%, #90bbff 70%);

      background: radial-gradient(var(--colors-theme-two) 0%, var(--colors-theme-one) 70.7%); // 1 / sqrt(2)
    }
  }
}
