.checkbox {
  --lineheight: 1.2;

  cursor: pointer;

  position: relative;

  margin-bottom: var(--gutter-small);
  padding: 1.2em;
  padding-bottom: 0;

  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);

  transition: background-color var(--transition-default);

  .buttons & { margin: 0.5rem; }

  .checkbox__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1.2em;

    input[type="checkbox"] {
      margin-right: 3rem;
      opacity: 0;
    }

    .checkbox__text {
      flex-grow: 1;
      text-align: left;
    }

    .checkbox__box {
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.75em;
      padding: 2px;

      border: 1px solid var(--colors-theme-border);
      border-radius: 0.6rem;

      &::after {
        content: "";

        width: 100%;
        height: 100%;

        border-radius: 0.4rem;

        transition: background-color var(--transition-default);
      }
    }

    &:has(input[type="checkbox"]:focus),
    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      .checkbox__box {
        &::after { background-color: var(--colors-theme-three); }
      }
    }

    &:has(input[type="checkbox"]:checked) {
      .checkbox__box {
        &::after { background-color: var(--colors-theme-one) !important; }
      }
    }
  }

  // &:has(input[type="checkbox"]:checked) {

  .checkbox__desc {
    margin-bottom: 1em;
    line-height: var(--lineheight);
  }
}
