@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.picture {
  --color: var(--colors-text-two);

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium);

  color: var(--color);

  border-radius: var(--border-radius-large);

  .picture__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    // gradien tot black at bottom
    background: linear-gradient(180deg, var(--colors-shade) 0%, rgb(0 0 0 / 30%) 100%);
  }

  .picture__heading {
    position: relative;
    max-width: 40rem;
    margin-top: 2em;
  }

  .picture__text {
    @include fonts.font-intro;

    position: relative;
    margin-top: 1em;
    text-wrap: balance;
  }

  @media (max-width: vars.$mq-mobile) {
    aspect-ratio: 5/6;
  }
}
