.field-caption {
  display: flex;
  flex-direction: column;

  margin-top: calc(var(--gutter-block) * -1 + 0.5em);
  margin-bottom: var(--gutter-block);
  padding-left: 1.2em;

  .field-caption__title {
    margin-bottom: 0.2em;
  }
}
