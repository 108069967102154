@use "../1-base/vars" as vars;

// visiblilily
.hidden { display: none !important; }
.faded { opacity: 0; }
.invisible { visibility: hidden; }

.disabled {
  pointer-events: none;
  opacity: var(--hover-opacity);
}

.img-responsive {
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

// types
.iframe { background: transparent; }
.sprite { display: inline-block; }

// clear fix css
.clear {
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after { clear: both; }
}

// text
.text--left { text-align: left; }
.text--right { text-align: right; }
.text--center { text-align: center; }
.text--medium { font-size: var(--font-size-medium); }

.capitalize { text-transform: capitalize; }
.legibility { text-rendering: optimizelegibility; }

// position basics
.relative { position: relative; }
.fixed { position: fixed; }

.absolute {
  position: absolute;
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0;
}

// visiblility  ----------------------------------------
// xl
@media (min-width: vars.$mq-xl + 1) {
  .visible-xl { display: none !important; }
}

@media (max-width: vars.$mq-xl) {
  .hidden-xl { display: none !important; }
}

// large
@media (min-width: vars.$mq-large + 1) {
  .visible-large { display: none !important; }
}

@media (max-width: vars.$mq-large) {
  .hidden-large { display: none !important; }
}

// small
@media (min-width: vars.$mq-small + 1) {
  .visible-small { display: none !important; }
}

@media (max-width: vars.$mq-small) {
  .hidden-small { display: none !important; }
}

// tablet
@media (min-width: vars.$mq-tablet + 1) {
  .visible-tablet { display: none !important; }
}

@media (max-width: vars.$mq-tablet) {
  .hidden-tablet { display: none !important; }
}

// phablet
@media (min-width: vars.$mq-phablet + 1) {
  .visible-phablet { display: none !important; }
}

@media (max-width: vars.$mq-phablet) {
  .hidden-phablet { display: none !important; }
}

// mobile
@media (min-width: vars.$mq-mobile + 1) {
  .visible-mobile { display: none !important; }
}

@media (max-width: vars.$mq-mobile) {
  .hidden-mobile { display: none !important; }
}

@media (min-width: vars.$mq-mobile-small + 1) {
  .visible-mobile-small { display: none !important; }
}

@media (max-width: vars.$mq-mobile-small) {
  .hidden-mobile-small { display: none !important; }
}

// portrait / landscape
@media (orientation: portrait) {
  .visible-landscape { display: none !important; }
}

@media (orientation: landscape) {
  .visible-portrait { display: none !important; }
}
