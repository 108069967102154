@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.info {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: -0.5rem 0; // becaues its bigger than lineheight

  .interest-header--dark & {
    svg path { fill: var(--colors-text-two); }
  }

  .info__content {
    @include fonts.font-small;

    --bg-color: var(--colors-bg-two);
    --color: var(--colors-text-two);

    pointer-events: none;

    position: absolute;
    z-index: 2;
    top: -1.7rem;
    left: calc(100% + 1.5rem);
    transform: translateY(1rem);

    width: 52rem;
    max-width: calc(var(--to-window-side) - var(--gutter) * 0.5);
    padding: var(--gutter-between);

    color: var(--color);

    opacity: 0;
    background-color: var(--bg-color);
    border-radius: var(--gutter-small);
    box-shadow: -1px 0 16px var(--colors-glow2);

    transition: opacity var(--transition-default) ease, transform var(--transition-default) var(--transition-easing-in);

    &::before {
      // triangle pointing left

      content: "";

      position: absolute;

      // top: 50%;
      top: 1.7rem;
      left: -1rem;

      // transform: translateY(-50%);

      width: 0;
      height: 0;

      border-top: 1rem solid transparent;
      border-right: 1rem solid var(--bg-color);
      border-bottom: 1rem solid transparent;
    }

    // .info__content
    &--left {
      right: calc(100% + 2rem);
      left: auto;
      box-shadow: 1px 0 16px var(--colors-glow2);

      &::before {
        right: -1rem;
        left: auto;
        border-right: 0;
        border-left: 1rem solid var(--bg-color);
      }
    }
  }

  // different because it should stay open on touch
  &:focus,
  &:hover {
    .info__content {
      transform: none;
      opacity: 1;
      transition-timing-function: ease, var(--transition-easing-out);
    }
  }

  // info
  @media (max-width: vars.$mq-mobile) {
    width: 1.5rem;

    svg {
      width: 100%;
      height: auto;
    }

    .interest-header--dark & {
      svg path { fill: var(--colors-text-one); }
    }
  }
}
