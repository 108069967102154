@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

@mixin form-reset {
  color: var(--colors-text-one);
  appearance: none;
  border-radius: 0;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

.chromeframe {
  position: fixed;
  z-index: 1000;
  bottom: 0;

  box-sizing: border-box;
  width: 100%;
  padding: 20px;

  font-weight: bold;

  background-color: var(--colors-theme-one);
}

blockquote,
figure {
  margin: 0;
  padding: 0;
}

cite {
  font-style: normal;
}

input {
  &[type="text"],
  &[type="time"],
  &[type="date"],
  &[type="month"],
  &[type="email"],
  &[type="password"],
  &[type="tel"],
  &[type="number"],
  &[type="url"],
  &[type="submit"] {
    @include form-reset;

    word-break: normal;

    &::placeholder {
      color: var(--colors-text-one);
      opacity: 0.45;
    }

    &:placeholder-shown { text-overflow: ellipsis; }

    // removes zoom
    @media (max-width: vars.$mq-phablet) { font-size: 16px; }
  }
}

textarea,
select {
  @include form-reset;

  // removes zoom
  @media (max-width: vars.$mq-phablet) { font-size: 16px; }

  @media (max-width: vars.$mq-mobile) {
    .interest-header--dark & {
      @include fonts.font-small;
    }
  }
}

::selection {
  color: var(--colors-text-one);
  text-shadow: none;
  background: var(--colors-theme-one);
}

// remove blue glow
:focus {
  outline-color: transparent;
  outline-style: none;
}
