@use "../1-base/vars" as vars;

.avatar {
  position: relative;

  overflow: hidden;

  width: 6.2rem;
  height: 6.2rem;
  margin-bottom: var(--gutter-small);

  border-radius: 50%;

  .avatar__img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @media (max-width: vars.$mq-mobile) {
    display: none;
  }
}
