@use "../1-base/vars" as vars;

.hamburger {
  --line-width: 2px;

  pointer-events: auto;

  position: relative;
  z-index: 2;

  display: none;

  width: 3rem;
  height: 18px;
  margin-bottom: var(--line-width);
  margin-left: var(--gutter-small);

  .hamburger__line {
    position: absolute;

    width: 100%;
    height: var(--line-width);

    background-color: var(--colors-text-one);

    transition: transform, background-color;
    transition-timing-function: var(--transition-easing), linear;
    transition-duration: var(--transition-fast), var(--transition-fast);

    // .hamburger__line
    &:nth-of-type(1) { transform: translateY(-0.7rem); }
    &:nth-of-type(2) {}
    &:nth-of-type(3) { transform: translateY(0.7rem); }
  }

  // .hamburger
  &--active,
  body:not(.body--touch) &:focus,
  body:not(.body--touch) &:hover,
  &.hover {
    transition-duration: var(--transition-fast);

    .hamburger__line {
      background-color: var(--colors-text-one);
    }
  }

  &.hover { transition-duration: 0.1s; }

  // .hamburger
  .body--nav &,
  .body--nav-black &,
  &--dark {
    // background-color: var(--colors-text-two);

    .hamburger__line {
      background-color: var(--colors-text-two);
    }

    &--active,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover,
    &.hover {
      // background-color: var(--colors-bg-two);

      .hamburger__line {
        background-color: var(--colors-text-one);
      }
    }
  }

  // .hamburger
  .body--nav & {
    // transform: translateX(0.5rem);

    .hamburger__line {
      background-color: var(--colors-text-two);

      &:nth-of-type(1) { transform: rotate(45deg) scaleX(1.1); }
      &:nth-of-type(2) { transform: scaleX(0); }
      &:nth-of-type(3) { transform: rotate(-45deg) scaleX(1.1); }
    }
  }

  // .hamburger
  @media (max-width: vars.$mq-phablet) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
