// Switcher V1.0 SASS
// set --switcher-duration to duration vriable in Switcher.js

.switcher {
  --switcher-duration: 0.4s;
  --switcher-scroll-height: 0; // overwritten by Switcher.js
  --switcher-easing: cubic-bezier(0.56, 0, 0.24, 1);

  transition: opacity var(--switcher-duration);

  &--faded-out { opacity: 0; }

  &--resizing {
    overflow: hidden;
    height: var(--switcher-scroll-height);
    transition: opacity var(--switcher-duration), height var(--switcher-duration) var(--switcher-easing);
  }

  .switcher__item {
    width: 100%;
    padding-top: 1px; // for non collapsing margin
    padding-bottom: 1px; // for non collapsing margin

    &--hidden { display: none; }
  }
}
