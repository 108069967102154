@use "../1-base/vars" as vars;

.column {
  --small: calc(50rem + 20vw);

  // background-color: rgb(255 255 255 / 10%);
  --vert-padding: 0;

  position: relative;
  z-index: 1;

  display: flex;

  // justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: var(--max-content);
  margin: 0 auto;
  padding: 0 var(--gutter);

  &--sticky {
    position: sticky;
    z-index: 2;
    top: calc(var(--nav-height) + var(--gutter-small));
  }

  &--block-smallest-tight { margin-bottom: var(--padding); }
  &--margin { margin-bottom: calc(10vmax - var(--gutter-medium)); }
  &--margin-small { margin-bottom: var(--padding); }
  &--padding-small { padding-bottom: var(--padding) !important; }

  &--block-small-tight,
  &--block-large-tight,
  &--block-small,
  &--block-large {
    .main__rest &,
    .main__with-bg & {
      padding-bottom: 10vmax;
    }
  }

  &--block-smallest-tight,
  &--block-small-tight,
  &--block-small {
    --small: 100%;
  }

  &--block-small { --vert-padding: 6vmax; }
  &--block-large { --vert-padding: 8vmax; }

  &--margin-small,
  &--block-smallest-tight,
  &--block-small-tight,
  &--block-large-tight { --vert-padding: var(--gutter-medium); }

  .column__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 100%;
    padding-top: var(--vert-padding);
    padding-bottom: calc(var(--vert-padding) - var(--padding));

    &--left { align-items: flex-start; }

    &--broker { padding-top: var(--padding); }

    &--dark,
    &--bg-white,
    &--bg-grey,
    &--bg-yellow,
    &--bg-blue,
    &--gradient {
      position: relative;

      padding-right: var(--gutter-medium);
      padding-left: var(--gutter-medium);

      background-color: var(--bg-color);
      border-radius: var(--border-radius-large);

      &:has(.image:last-child) {
        padding-bottom: 0;
      }
    }

    &--bg-white { --bg-color: var(--colors-bg-one); }
    &--bg-grey { --bg-color: var(--colors-bg-three); }
    &--bg-blue { --bg-color: var(--colors-theme-highlight); }
    &--bg-yellow { --bg-color: var(--colors-theme-two); }

    &--dark {
      --color: var(--colors-text-two);
      --bg-color: var(--colors-bg-two);

      padding: var(--gutter);
      color: var(--color);
    }

    &--gradient {
      --bg-color: transparent;

      overflow: hidden;
      border: 1px solid var(--colors-theme-highlight);
    }

    .splash--berekenen & {
      padding-bottom: calc(var(--vert-padding) - var(--gutter-small));
    }

    .columns__content-center {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: column;
      align-items: center;

      width: var(--small);
      max-width: 100%;

      & .image:last-child {
        margin-bottom: 0;
      }
    }

    &--dark,
    &--bg-white {
      .columns__content-center {
        align-items: flex-start;
      }
    }
  }

  &--small {
    .column__content {
      width: var(--small);
    }
  }

  @media (max-width: vars.$mq-small) {
    &--padding-small {
      padding-bottom: var(--gutter-medium) !important;
    }
  }

  @media (min-width: vars.$mq-large) {
    --small: calc(50rem + var(--max-content) * 0.2);

    &--block-smallest-tight,
    &--block-small-tight,
    &--block-small {
      --small: 100%; // override
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .column__content {
      &--broker {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  @media (max-width: vars.$mq-phablet) {
    .column__content--dark {
      padding: var(--gutter-medium);
    }
  }

  @media (max-width: vars.$mq-mobile) {
    &--sticky {
      top: calc(var(--nav-height) - 1px);
    }

    .column__content {
      &--bg-grey {
        padding-right: 0;
        padding-left: 0;
        background-color: transparent;
      }
    }

    &:has(.how) {
      margin-bottom: 10vmax;
      padding-bottom: 0;
      background-color: var(--colors-bg-three);
    }
  }

  @media (max-height: vars.$mq-mobile-horizontal) {
    &--sticky {
      position: relative;
      top: 0;
    }
  }
}
