@use "fonts";

html {
  box-sizing: border-box;

  // height: 100%;
  height: var(--inner-height);
  font-size: 62.5% !important; // shopify override
}

.body {
  @include fonts.font-sans;
  @include fonts.font-book;

  --color: var(--colors-text-one);

  overflow-x: hidden;
  overflow-y: scroll;
  display: block;

  height: auto;
  min-height: 100%;

  font-kerning: normal;
  line-height: 1; // always leave this! larger ones overwrite inline elements
  color: var(--color);
  word-break: break-word;
  word-wrap: break-word;

  -webkit-tap-highlight-color: transparent;

  // .body
  &--touch {
    cursor: pointer; // OMG HOVER ON TOUCH AND HOVER OFF TOO
  }
}
