@use "../3-core/fonts" as fonts;

.field {
  display: flex;
  align-items: center;

  margin-bottom: var(--gutter-block);
  padding: 1.2em;

  border: 1px solid var(--colors-theme-border);
  border-radius: var(--gutter-medium);

  .checkbox & {
    margin-bottom: 1.2em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    border-radius: 1.2rem;
  }

  .field__currency {
    @include fonts.font-normal;
    @include fonts.font-sans-condensed;

    margin-right: var(--gutter-small);
  }

  .field__input {
    flex-grow: 1;
  }
}
