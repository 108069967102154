@use "../1-base/vars" as vars;

.cta1 {
  position: relative;

  overflow: hidden;

  width: var(--cta1-width);
  max-width: 100%;
  margin-bottom: var(--padding);
  padding: var(--gutter-medium);

  border-radius: var(--border-radius-large);

  &--no-padding {
    margin: 0;
    padding: 0;
    border-radius: 0;
  }

  &--margin-low {
    margin-bottom: var(--gutter-small);
  }

  .footer & {
    --color: var(--colors-text-two);

    color: var(--color);
    background-color: var(--colors-glow);
  }

  .cta1__heading {
    margin-bottom: 0.4em;
  }

  @media (max-width: vars.$mq-small) {
    .splash__cta & {
      display: none;
    }
  }

  @media (max-width: vars.$mq-mobile) {
    &--no-padding {
      display: block !important; // override below
      margin-bottom: var(--padding);
    }

    .splash & {
      display: none;
    }
  }
}
