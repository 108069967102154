@use "../1-base/vars" as vars;

.ratio {
  position: relative;

  overflow: hidden;
  display: block;

  width: 100%;
  height: 0;
  padding-bottom: var(--percentage);

  background-position: center;
  background-size: cover;

  // .ratio
  &--2-1 { padding-bottom: 50%; }
  &--16-9 { padding-bottom: 56.25%; }
  &--3-2 { padding-bottom: 66.66%; }
  &--4-3 { padding-bottom: 75%; }
  &--1-1 { padding-bottom: 100%; }
  &--3-4 { padding-bottom: 133.33%; }
  &--4-5 { padding-bottom: 125%; }

  img,
  video {
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }

  // for a ratio block that is a placeholder for an image
  &--placeholder {
    position: initial;

    img,
    video {
      position: initial;
      height: auto !important;
      object-fit: initial;
    }
  }

  &--cover {
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--contain {
    img,
    video {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;

      border-radius: var(--border-radius);
    }
  }

  &--aspect-ratio {
    aspect-ratio: var(--ar);
    height: auto;
  }

  .ratio__holder {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
  }

  .ratio__overlay {
    position: absolute;

    width: 100%;
    height: 100%;

    opacity: 0;
    background-color: var(--colors-bg-two);

    transition: opacity var(--transition-slow);

    // .ratio__overlay
    &--visible { opacity: 1; }

    .ratio__text {
      // padding: .4em .6em;
      padding: 1.8rem 2.6rem;
      color: var(--colors-text-two);
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .ratio__overlay {
      .ratio__text { padding: 1.1rem var(--padding); }
    }
  }

  // .ratio
  @media (max-width: vars.$mq-phablet) {
    &--16-9 {
      .columns__item--1-1 & { padding-bottom: 100%; }
    }
  }
}
