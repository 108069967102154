@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.nav {
  position: sticky;
  top: 0;
  backdrop-filter: blur(8px);

  &::before { top: 0; }
  &::after { bottom: 0; }

  &::before,
  &::after {
    content: "";

    position: absolute;
    right: 0;
    left: 0;

    height: 1px;

    background-color: var(--colors-shade);
  }

  .nav__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    background-color: var(--colors-theme-one);

    transition-duration: var(--transition-default), var(--transition-default);
    transition-property: opacity, background-color;

    .body--scrolled & { opacity: 0.75; }

    .body--nav-white & { background-color: var(--colors-bg-one) !important; }

    .body--nav &,
    .body--nav-black & { background-color: var(--colors-bg-two) !important; }

    .body[data-pagetype="page"] &,
    .body[data-pagetype="news"] &,
    .body[data-pagetype="knowledge"] &,
    .body[data-pagetype="contact"] & {
      background-color: var(--colors-bg-three);
    }
  }

  .nav__logo {
    margin-right: auto;

    .body--nav &,
    .body--nav-black & {
      svg path {
        fill: var(--colors-text-two);
      }
    }
  }

  .nav__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: var(--gutter-small) 0;
  }

  .nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav__menu-item {
      --color: var(--colors-text-one);

      margin: 0 var(--gutter-large);
      font-weight: 500;
      line-height: var(--lineheight);
      color: var(--color);

      @include mixins.sick-underline;
      @include mixins.underline-center;

      .body--nav-black & {
        --color: var(--colors-text-two);
      }

      &--active,
      &.hover,
      body:not(.body--touch) &:focus,
      body:not(.body--touch) &:hover {
        transition-duration: var(--transition-fast);

        @include mixins.underline-full-center;
      }
    }

    .nav__menu-address { display: none; }
  }

  .nav__lang {
    position: relative;
    margin-left: auto;

    @include fonts.font-small;

    .nav__lang-current {
      pointer-events: none;
    }

    .nav__lang-dropdown {
      pointer-events: none;

      position: absolute;
      z-index: 1;
      top: 0;

      width: auto;
      padding-top: calc(2.2em + 1.5rem);

      opacity: 0;

      transition: opacity var(--transition-default);
    }

    // .nav__lang
    &--active,
    &.hover,
    body:not(.body--touch) &:focus,
    &:hover { // for keeping hover on mobile after click
      .nav__lang-dropdown {
        pointer-events: auto;
        opacity: 1;
        transition-duration: var(--transition-fast);
      }
    }
  }

  .nav__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto; // override buttons

    .nav__button {
      margin-left: var(--gutter-small);
    }
  }

  @media (max-width: vars.$mq-large) {
    .nav__menu {
      .nav__menu-item {
        margin: 0 calc(var(--gutter-large) * 0.5);
      }
    }
  }

  @media (max-width: vars.$mq-small) {
    .nav__menu {
      .nav__menu-item {
        margin: 0 calc(var(--gutter-large) * 0.3);
      }
    }

    .nav__buttons {
      .nav__button {
        .nav__button-text {
          overflow: hidden;
          width: 0;
        }

        svg { margin-right: 0; }
      }
    }
  }

  @media (max-width: vars.$mq-tablet) {
    // .nav__menu {
    //   justify-content: center;
    //   order: 1;

    //   width: 100%;
    //   margin-top: var(--gutter-small);

    //   .nav__menu-item {
    //     margin: 0 calc(var(--gutter-large) *1);
    //   }
    // }

    .nav__buttons {
      .nav__button {
        padding: 0.9em;

        &--afspraak {
          display: none;
        }

        &--login {
          margin-top: -0.5rem;
          margin-bottom: -0.5rem;
        }
      }
    }
  }

  @media (max-width: vars.$mq-phablet) {
    .nav__logo {
      z-index: 2;
    }

    .nav__menu {
      pointer-events: none;

      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      transform: translateY(calc(var(--gutter-large) * -1));

      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      width: 100%;
      height: 100vh;

      // padding: 10rem var(--gutter-small) 4rem;
      padding: 10rem var(--gutter-small) calc(4rem + var(--navbar-height));

      color: var(--colors-text-two);

      opacity: 0;
      background-color: var(--colors-bg-two);

      transition: transform, opacity;
      transition-duration: var(--transition-slow);

      .nav__menu-item {
        @include fonts.font-normal;
        @include fonts.font-sans-condensed;
        @include mixins.underline-left;
        @include mixins.underline-text-shadow;

        --color: var(--colors-text-two);
        --bg-color: var(--colors-bg-two);
        --underline-top: calc(50% + 0.59em);

        margin: 0;
        line-height: 1.2;

        &--active,
        &.hover,
        body:not(.body--touch) &:focus,
        body:not(.body--touch) &:hover {
          @include mixins.underline-full-left;
        }
      }

      .nav__menu-address {
        display: block;
        color: var(--colors-theme-highlight);

        a {
          color: var(--colors-theme-highlight);
        }
      }
    }

    .nav__lang,
    .nav__buttons {
      z-index: 0;
    }

    .body--nav & {
      .nav__menu {
        pointer-events: auto;
        transform: translateY(0);
        opacity: 1;
        transition-duration: var(--transition-default);
      }
    }
  }
}
