@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.intro {
  @include fonts.font-sans;
  @include fonts.font-intro;

  margin-bottom: calc(var(--lineheight) * 1em);
  color: var(--colors-text-transparent);
  text-align: center;
  text-wrap: balance;

  &--left {
    align-self: flex-start;
    text-align: left;
  }

  &--opaque {
    color: var(--colors-text-one);
  }

  .column__content--bg-blue &,
  .splash & {
    margin-bottom: calc(var(--lineheight) * 1.5em);
  }

  .results__item & {
    margin-bottom: var(--gutter-medium);

    // color: var(--colors-text-transparent);
    text-align: left;
  }

  .results__item--dark & {
    color: var(--colors-theme-highlight);
  }

  .splash__cta & {
    @include fonts.font-book;
  }

  @media (max-width: vars.$mq-small) {
    .splash__cta & {
      margin-bottom: var(--gutter-small);
    }
  }

  // .intro
  @media (max-width: vars.$mq-tablet) {
    .splash__cta & {
      margin-bottom: calc(var(--lineheight) * 1em);
    }
  }

  @media (max-width: vars.$mq-phablet) {
    color: var(--colors-text-one);
  }
}
