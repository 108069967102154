@use "../3-core/fonts" as fonts;

h1.heading,
h2.heading {
  &:has(.heading__bold, .heading__regular):has(.fader):not(:has(.fader2)) {
    opacity: 0;
  }
}

.heading {
  width: 100%;
  margin-bottom: 0.5em;

  &--cb { margin-bottom: 0.1em; }
  &--center { text-align: center; }
  &--bold { font-weight: 940; }

  &--condensed {
    @include fonts.font-sans;
    @include fonts.font-sans-condensed;
  }

  &:has(svg) {
    display: flex;
    align-items: center;
  }

  .results__item & {
    width: auto;
    margin-bottom: calc(var(--gutter-medium) - 0.2em);
  }

  .results__item-flex & {
    margin-right: 0.5em;
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
    margin-right: 0.65em;
    path { fill: var(--color); }
  }

  .heading__regular-no-fader,
  .heading__regular {
    font-weight: 400;
  }

  .heading__bold-no-fader,
  .heading__bold {
    font-weight: 940;
  }
}
