@use "../1-base/vars" as vars;

.columns {
  // --columns-gutter: max(var(--gutter), var(--gutter-medium));
  --columns-gutter: var(--gutter);

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: vars.$mq-max-content;
  margin-right: auto;
  margin-left: auto;
  padding: 0 calc(var(--columns-gutter) / 2);

  // background-color: rgb(128 128 128 / 10%);

  &--wide-gutter {
    --columns-gutter: calc(var(--gutter) * 2);
  }

  &--m-s {
    margin-bottom: var(--padding);
  }

  &--m-10 {
    margin-bottom: calc(10vmax - var(--gutter-medium));
  }

  .footer & { --columns-gutter: 2rem; }

  .results &,
  .calculate & {
    --columns-gutter: 1.2em;

    padding-bottom: calc(var(--gutter-block) - 1rem);
  }

  // .columns
  &--neg-margins {
    width: calc(100% + var(--columns-gutter) * 2);
    max-width: none;
    margin-right: calc(-1 * var(--columns-gutter));
    margin-left: calc(-1 * var(--columns-gutter));
  }

  &--between { justify-content: space-between; }
  &--center { justify-content: center; }
  &--clean { padding: 0; }
  &--hidden { display: none; }
  &--vert-center { align-items: center; }
  &--vert-stretch { align-items: stretch; }
  &--half-gutter { --columns-gutter: calc(var(--gutter) * 0.5); }
  &--no-gutter { --columns-gutter: calc(0px); }

  .columns__item {
    width: calc(100% - var(--columns-gutter));
    max-width: calc(100% - var(--columns-gutter));

    // min-height: 2rem;
    margin-right: calc(var(--columns-gutter) / 2);
    margin-left: calc(var(--columns-gutter) / 2);

    // background-color: rgb(128 128 128 / 10%);

    // .columns__item
    &--1-2c,
    &--1-2 { width: calc(50% - var(--columns-gutter)); }

    &--1-3d,
    &--1-3c,
    &--1-3b,
    &--1-3 { width: calc(33.3333% - var(--columns-gutter)); }

    &--2-3d,
    &--2-3c,
    &--2-3b,
    &--2-3 { width: calc(66.6666% - var(--columns-gutter)); }

    &--1-4c,
    &--1-4 { width: calc(25% - var(--columns-gutter)); }
    &--3-4 { width: calc(75% - var(--columns-gutter)); }
    &--1-5 { width: calc(20% - var(--columns-gutter)); }

    &--2-5b,
    &--2-5 { width: calc(40% - var(--columns-gutter)); }
    &--3-5 { width: calc(60% - var(--columns-gutter)); }
    &--4-5 { width: calc(80% - var(--columns-gutter)); }

    &--1-6c,
    &--1-6 { width: calc(16.6666% - var(--columns-gutter)); }

    &--block-c { width: calc(27.7777% - var(--columns-gutter)); }

    &--5-6c,
    &--5-6 { width: calc(83.3333% - var(--columns-gutter)); }

    &--1-12c,
    &--1-12 { width: calc(08.3333% - var(--columns-gutter)); }

    // &--footer { width: calc(var(--max-content) * 0.1944 - var(--columns-gutter) * 1.25); }

    // &--cta-rest { width: calc(100% - var(--max-content) * 0.4166 - var(--columns-gutter) * 0.75); }
    // &--cta { width: calc(var(--max-content) * 0.4166 - var(--columns-gutter) * 1.25); }

    &--footer {
      width: calc((100% - var(--cta1-width) - var(--columns-gutter)) / 3 - var(--columns-gutter));
    }
    &--cta { width: var(--cta1-width); }
    &--cta-rest { width: calc(100% - var(--cta1-width) - var(--columns-gutter) * 1.25); }

    &--plus {
      align-self: center;
      width: calc(55% - var(--columns-gutter));
    }
    &--end { align-self: flex-end; }
    &--min { width: calc(45% - var(--columns-gutter)); }

    &--news,
    &--term,
    &--person {
      width: calc(33.3333% - var(--columns-gutter));
      max-width: calc(var(--max-content) * 0.3333 - var(--columns-gutter) * 1.3333);
    }

    &--phablet { display: none; }
  }

  // .columns
  // @media (max-width: vars.$mq-large) {
  //   .columns__item {
  //   }
  // }

  // .columns
  @media (max-width: vars.$mq-small) {
    .footer & { --columns-gutter: var(--gutter); }

    .columns__item {
      &--block-c { width: calc(33.3333% - var(--columns-gutter)); }
      &--1-2c { width: calc(58.3333% - var(--columns-gutter)); }
      &--1-3c { width: calc(41.6666% - var(--columns-gutter)); }
      &--2-3c { width: calc(83.3333% - var(--columns-gutter)); }
      &--1-6c { width: calc(08.3333% - var(--columns-gutter)); }
      &--5-6c { width: calc(100% - var(--columns-gutter)); }
      &--1-12c { width: calc(100% - var(--columns-gutter)); }

      &--footer {
        width: calc(((var(--max-content) - var(--columns-gutter) * 2) - var(--cta1-width)) / 3 - var(--columns-gutter));
      }

      &--news,
      &--term,
      &--person { width: calc(50% - var(--columns-gutter)); }
    }
  }

  // .columns
  @media (max-width: vars.$mq-tablet) {
    &--wide-gutter { --columns-gutter: var(--gutter); }

    .columns__item {
      &--1-3d { width: calc(50% - var(--columns-gutter)); }

      &--2-3d { width: calc(50% - var(--columns-gutter)); }
      &--2-3 { width: calc(100% - var(--columns-gutter)); }
      &--1-4 { width: calc(50% - var(--columns-gutter)); }

      &--2-5b { width: calc(50% - var(--columns-gutter)); }

      &--3-16 { width: calc(16.6666% - var(--columns-gutter)); }
      &--4-16 { width: calc(25% - var(--columns-gutter)); }

      &--1-3b { width: calc(100% - var(--columns-gutter)); }
      &--2-3b { width: calc(100% - var(--columns-gutter)); }

      &--cta-rest { width: calc(var(--max-content) * 0.4166 - var(--columns-gutter) * 1.25); }
      &--footer { width: calc(33.3333% - var(--columns-gutter)); }

      &--plus { width: calc(65% - var(--columns-gutter)); }
      &--min { width: calc(35% - var(--columns-gutter)); }
    }
  }

  // .columns
  @media (max-width: vars.$mq-phablet) {
    &--wide-gutter { --columns-gutter: calc(var(--gutter) * 2); }

    .columns__item {
      &--block-c { width: calc(100% - var(--columns-gutter)); }
      &--1-2c { width: calc(50% - var(--columns-gutter)); }
      &--1-3c { width: calc(50% - var(--columns-gutter)); }

      &--2-3c { width: calc(100% - var(--columns-gutter)); }
      &--1-6c { width: calc(100% - var(--columns-gutter)); }

      &--footer__logo,
      &--footer { width: calc(50% - var(--columns-gutter)); }

      &--plus { width: calc(60% - var(--columns-gutter)); }
      &--min { width: calc(40% - var(--columns-gutter)); }
    }
  }

  // .columns
  @media (max-width: vars.$mq-mobile) {
    .columns__item {
      &--1-2 { width: calc(100% - var(--columns-gutter)); }
      &--1-3 { width: calc(100% - var(--columns-gutter)); }
      &--1-4 { width: calc(50% - var(--columns-gutter)); }
      &--3-4 { width: calc(100% - var(--columns-gutter)); }
      &--1-5 { width: calc(100% - var(--columns-gutter)); }
      &--2-5b { width: calc(100% - var(--columns-gutter)); }
      &--4-5 { width: calc(100% - var(--columns-gutter)); }
      &--1-6 { width: calc(50% - var(--columns-gutter)); }
      &--5-6 { width: calc(100% - var(--columns-gutter)); }
      &--1-12 { width: calc(100% - var(--columns-gutter)); }
      &--5-12 { width: calc(100% - var(--columns-gutter)); }
      &--phablet { display: block; }

      &--1-2c,
      &--1-3c { width: calc(100% - var(--columns-gutter)); }

      &--1-3d,
      &--2-3d { width: calc(100% - var(--columns-gutter)); }

      &--footer__logo,
      &--footer { width: calc(100% - var(--columns-gutter)); }

      &--person,
      &--news,
      &--term { width: calc(100% - var(--columns-gutter)); }

      &--1-3d,
      &--2-3d,
      &--1-3c,
      &--1-2c {
        max-width: calc(var(--max-content) * 0.3333 - var(--columns-gutter) * 1.3333);
      }

      &--plus { width: calc(100% - var(--columns-gutter)); }
      &--min { width: calc(100% - var(--columns-gutter)); }
    }
  }

  // .columns
  @media (max-width: vars.$mq-mobile-small) {
    .columns__item {
      &--1-2 { width: calc(100% - var(--columns-gutter)); }
      &--1-3 { width: calc(100% - var(--columns-gutter)); }
      &--2-3 { width: calc(100% - var(--columns-gutter)); }
      &--1-4 { width: calc(100% - var(--columns-gutter)); }
      &--1-6 { width: calc(100% - var(--columns-gutter)); }
    }
  }
}
