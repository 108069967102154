@use "../1-base/mixins" as mixins;

.crumbs {
  margin-bottom: 3em;
  line-height: var(--lineheight);

  .crumbs__link,
  .crumbs__separator,
  .crumbs__current {
    margin-right: 0.5em;
  }

  .crumbs__link {
    display: inline;
    color: var(--colors-text-transparent);
    text-decoration: none;
    transition: color 0.2s;

    &.hover,
    body:not(.body--touch) &:focus,
    body:not(.body--touch) &:hover {
      text-decoration: underline;
      text-underline-offset: 0.2rem;
    }
  }

  .crumbs__separator {
    @include mixins.corner;

    display: inline-block;

    &::after {
      width: 1rem;
      height: 1rem;
      margin-left: -0.4rem;

      border-color: var(--colors-text-transparent);
      border-width: 2px;
    }
  }

  .crumbs__current {
    display: inline;
    color: var(--colors-text-transparent);
  }
}
