@use "../1-base/vars" as vars;

.interests-holder {
  width: 100%;
  margin-bottom: var(--gutter-medium);

  @media (max-width: vars.$mq-mobile) {
    position: relative;

    overflow: hidden;

    max-height: calc(var(--interest-height) * 10 + var(--interest-gap) * 1);
    margin-bottom: 0;

    transition: max-height var(--transition-slow) var(--transition-easing);

    &::after {
      pointer-events: none;
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: var(--interest-height);

      // background: linear-gradient(180deg, transparent 0%, var(--colors-bg-three) 100%);
      background: linear-gradient(180deg, transparent 0%, var(--colors-bg-one) 100%);

      transition: opacity var(--transition-default) var(--transition-default);
    }

    &--full {
      max-height: calc(var(--scroll-height) + 1px);

      &::after {
        opacity: 0;
      }
    }
  }
}
