@use "../1-base/vars" as vars;

.calculate {
  .calculate__button2,
  .calculate__button {
    display: flex;
  }

  .calculate__block {
    container-type: inline-size;
    overflow: hidden;
    display: block;

    margin-bottom: var(--gutter-small);

    background-color: var(--colors-bg-one);
    border-radius: var(--border-radius-large);

    .calculate__block-content {
      padding: var(--gutter-block) var(--gutter-block) 0;

      &--no-padding { padding-top: 0; }

      &:has(.interests) {
        padding: var(--gutter-small);
        padding-left: var(--gutter-medium);
      }

      @container (max-width: 70rem) {
        --gutter-block: var(--gutter-medium);
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    .calculate__block {
      .calculate__block-content {
        &:has(.interests) {
          padding: var(--gutter-medium);
        }
      }
    }
  }
}
