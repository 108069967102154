/*
Based on: Eric Meyer's Reset CSS v2.0 (http:// meyerweb.com/eric/tools/css/reset/)
Edited by Little Fragments (http:// littlefragments.com)
*/

// Reset all elements

/*
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video { */

// add more when necessary
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  font: inherit;
  font-size: 100%;
  vertical-align: baseline;

  border: 0;
}

body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  backface-visibility: hidden;
}

// Reset list styling
ol,
ul {
  list-style: none;
}

// Remove quotes from all blockquotes
blockquote,
q {
  quotes: none;
}

// Remove the before and after quotes
blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

img {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -ms-interpolation-mode: bicubic;
}

// Remove default browser appearance from buttons
// button { @include prefix(appearance,none); }

// Reset additional styling from iframes
iframe {
  overflow: hidden;
  background: none;
}
