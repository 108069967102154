@use "../1-base/vars" as vars;

.block5 {
  --bg-color: var(--colors-bg-three);

  display: flex;
  align-items: center;

  margin-bottom: var(--gutter-medium);
  padding: var(--gutter-medium);

  background-color: var(--bg-color);
  border-radius: var(--gutter-medium);

  .block5__texts {
    flex-grow: 1;
  }

  .block5__side {
    margin-left: var(--gutter-medium);
  }

  @media (max-width: vars.$mq-phablet) {
    margin-bottom: var(--gutter-small);
  }
}
