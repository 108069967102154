@use "../1-base/vars" as vars;

@mixin buttons-scroll {
  overflow: scroll;
  flex-flow: row nowrap;

  width: calc(100% + var(--gutter) * 2);
  margin: 0 calc(var(--gutter) * -1) var(--gutter);
  padding: 0 calc(var(--gutter) * 2 - 0.5rem) 0.5rem calc(var(--gutter) - 0.5rem);

  &::before,
  &::after {
    pointer-events: none;
    content: "";

    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
  }

  &::before {
    left: 0;
    width: calc(var(--gutter) * 1);
    background: linear-gradient(to right, var(--bg-color), transparent);
  }

  &::after {
    right: 0;
    width: calc(var(--gutter) * 2);
    background: linear-gradient(to left, var(--bg-color), transparent);
  }

  .how & {
    &::before { left: calc(var(--gutter-medium) * -0.5); }
    &::after { right: calc(var(--gutter-medium) * -0.5); }
  }

  a,
  label,
  button {
    flex-shrink: 0;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  width: calc(100% + 2rem);
  margin: -1rem;
  padding: 0.5rem;

  &--cb { margin-bottom: var(--gutter-large); }
  &--right { justify-content: flex-end; }
  &--center { justify-content: center; }

  &--full {
    flex-direction: column;
    align-items: stretch;
  }

  &--vertical {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }

  .column__content--gradient &,
  .column__content--bg-white &,
  .results &,
  .calculate & {
    padding-bottom: calc(var(--gutter-block) + 0.5rem);
  }

  .cta1--no-padding & {
    justify-content: center;
  }

  .buttons__text {
    margin: 1rem;
  }

  .buttons__splitter {
    width: 100%;
  }

  &--land {
    position: relative;

    .picture & {
      margin-top: var(--gutter-small);
    }

    .buttons__land {
      display: flex;
      width: 16.7rem;
      margin-left: var(--gutter-medium);

      svg {
        width: 100%;
        height: auto;
      }

      &--inverted {
        width: 18rem;

        .interests & {
          margin-bottom: calc(var(--lineheight) * 1em);
          margin-left: 0.5rem;
        }

        svg path {
          fill: var(--colors-text-one);
        }
      }
    }
  }

  .body--touch &--touch-scroll {
    @include buttons-scroll;
  }

  @media (max-width: vars.$mq-small) {
    &--small-vertical {
      flex-direction: column;
      align-items: flex-start;

      &.buttons--right {
        align-items: flex-end;
      }
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .interest-header--banks & {
      justify-content: flex-start;

      // padding: 0;
      border-radius: 0;
    }

    &--land {
      .buttons__land {
        &--inverted {
          width: 16rem;
          margin-left: var(--gutter-between);
        }
      }
    }
  }

  @media (max-width: vars.$mq-phablet) {
    &--phablet-vertical {
      flex-direction: column;
      align-items: flex-start;
    }

    &--land {
      .buttons__land {
        display: none;

        .interests & {
          display: flex;
        }
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    &--mobile-full {
      flex-direction: column;
      align-items: stretch;

      &.buttons--right {
        align-items: stretch;
      }
    }

    &--mobile-vertical {
      flex-direction: column;
      align-items: flex-start;
    }

    &--mobile-scroll {
      @include buttons-scroll;
    }

    .interest-header--banks & {
      padding: 0.5rem;
    }
  }
}
