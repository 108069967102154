@use "../1-base/vars" as vars;

.splash-block {
  position: relative;
  z-index: 1;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  aspect-ratio: 1 / 1;
  max-width: calc(calc(var(--max-content) - var(--gutter) * 3) * 0.3333);
  margin-bottom: var(--gutter);
  padding: var(--gutter-medium);

  border-radius: var(--border-radius-large);

  .splash-block__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20%;
    transform: scaleX(-1);

    width: 90%;
  }

  .splash-block__heading {
    position: relative;
  }

  @media (max-width: vars.$mq-tablet) {
    width: calc(50% - var(--gutter) * 0.5);
    margin: var(--padding) 0 0 0;
  }

  @media (max-width: vars.$mq-mobile) {
    width: 100%;
  }
}
