@media print {
  // general
  html,
  body {
    width: 900px;
  }

  html { font-size: 62.5%; }
  body { padding-top: 0; }

  // hiding elements
  .nav,
  .footer {
    display: none;
  }

  // several fixes
  .text { padding-bottom: 1em; }
  section { page-break-inside: avoid; }

  .gallery-holder {
    .gallery-holder__gallery { width: 100%; }
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;

    .gallery__item {
      width: 45%;
      margin-right: 5%;

      .gallery__item__caption { margin-bottom: 1em; }
    }
  }
}
