@use "../1-base/mixins" as mixins;
@use "../1-base/vars" as vars;
@use "../3-core/fonts" as fonts;

.splash {
  padding-bottom: var(--gutter);

  &--rentes { padding-bottom: calc(var(--interest-header-height) + 10vmax); }
  &--img-article { padding-bottom: calc(var(--article-img-height) * 0.5); }
  &--berekenen { padding-bottom: 0; }

  .splash__jumbo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vmax 0 calc(10vmax - var(--padding));

    &--small {
      padding: 6vmax 0 calc(6vmax - var(--padding));
    }

    &--cbs {
      padding: 6vmax 0 calc(6vmax - var(--padding) * 2);
    }

    .splash__heading {
      margin-bottom: 0.2em;

      // font-weight: 940;
      text-align: center;
    }
  }

  .splash__rest {
    .splash__main {
      padding: var(--padding) var(--gutter-large);
      background-color: var(--colors-bg-layer);
      border: 1px solid var(--colors-bg-three);
      border-radius: var(--border-radius-large);

      .splash__main-heading {
        margin-bottom: var(--gutter-medium);
      }
    }

    .splash__side {
      display: flex;
      flex-direction: column;
    }
  }

  .splash__split {
    display: flex;
    align-items: flex-start;
    width: 100%;

    > *:first-child {
      margin-right: var(--gutter);
    }

    .splash__cta {
      position: sticky;
      z-index: 2;
      top: calc(var(--nav-height) + var(--gutter-small));

      flex-grow: 0;
      flex-shrink: 0;

      width: var(--cta1-width);
      max-width: 100%;
    }

    .splash__cta-rest {
      flex-grow: 1;
    }
  }

  @media (max-width: vars.$mq-small) {
    .splash__rest {
      .splash__main {
        padding: var(--gutter-medium);
      }
    }

    .splash__split {
      .splash__cta {
        width: calc(33.3333% - var(--gutter-medium) * 0.5);
      }
    }
  }

  @media (max-width: vars.$mq-tablet) {
    .splash__split {
      flex-wrap: wrap;

      > *:first-child {
        margin-right: 0;
      }

      .splash__cta {
        position: relative;
        top: auto;
        width: 40rem;
      }

      .splash__cta-rest {
        width: 100%;
        max-width: calc(var(--max-content) - var(--cta1-width) - var(--gutter) * 3);
      }
    }

    .splash__rest {
      .splash__side {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: vars.$mq-mobile) {
    padding-bottom: 0;

    // override
    &--img-article { padding-bottom: calc(var(--article-img-height) * 0.5); }

    .splash__rest {
      @include mixins.white-start;

      --bg-color: var(--colors-bg-one);

      padding-top: var(--gutter-large);
      padding-bottom: var(--gutter);

      .splash__main {
        padding: 0;
        background-color: var(--colors-bg-one);
        border: none;
      }

      .splash__side {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
